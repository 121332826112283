<template>
  <div>
    <div class="d-flex align-items-center" style="justify-content: space-between">
      <div>
        <span style="margin-right: 10px;">Mostrar</span>
        <b-form-select style="width: 3rem; height: 1.8rem; padding: 5px; border: none; background-color: #ececec"
                       :options="options" v-model="max" required></b-form-select>
        <span style="margin-left: 10px;">registros</span>
      </div>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 10px;">Buscar:</span>
        <input class="filter-campues" v-model="search"/>
        <vs-button v-if="BusquedaBoton" @click.prevent="getData" title='Buscar' success icon
                   class=' '>
          <i class="fa-solid fa-magnifying-glass"></i>
        </vs-button>
      </div>
    </div>
    <div class="table-responsive mt-3">
      <table class="table table-responsive-md table-striped text-center">
        <thead>
        <tr>
          <th v-if="SeleccionMultiple || seleccionados.length > 0" class="column-header" style="width: 50px;">
            <input type="checkbox" :checked="this.data.length > 0 && seleccionados.length === this.totalRegistros"
                   @change="SelccionarTodos"/>
          </th>
          <th v-if="SeleccionSimple" class="column-header" style="width: 50px;"></th>
          <th v-for="(column, index) in columns"
              :key="index"
              :style="{
                  width: column.width || 'auto',
                  minWidth: column.minWidth || 'auto',
                  maxWidth: column.maxWidth || 'auto',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word'
              }" :class="{ 'sortable-column': column.sortable, 'column-header': true }"
              @click.prevent="column.sortable ? ordenar(column.key) : null">
            <div style="display: flex; align-items: center; justify-content: center; gap: 5px;">
              {{ column.label }}
              <span v-if="column.sortable" class="sort-icons">
                <span v-if="orden.columna === column.key && orden.ascendente" style="font-size: 10px">▲</span>
                <span v-if="orden.columna === column.key && !orden.ascendente" style="font-size: 10px">▼</span>
                <span v-if="orden.columna !== column.key" style="color: #cacaca; font-size: 10px">▲▼</span>
              </span>
            </div>
          </th>


        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, rowIndex) in filteredData" :key="rowIndex">
          <td v-if="SeleccionMultiple || seleccionados.length > 0">
            <input type="checkbox" :checked="SeleccionadosDatosMultiples(row)" @change="SeleccionDatosMultiple(row)"/>
          </td>
          <td v-if="SeleccionSimple">
            <input type="radio" :checked="SeleccionadoDatoSimple(row)" @change="SeleccionDatoUnicoSimple(row)"/>
          </td>
          <td v-for="(col) in columns" :key="col.key"
              :style="{ width: col.width || 'auto', minWidth: col.minWidth || 'auto', maxWidth: col.maxWidth || 'auto' }"
              class="column-text">
            <span class="column-text" :title="row[col.key]"
                  style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">
              <slot :name="col.key" :row="row" :column="col">{{ row[col.key] }}</slot>
            </span>
          </td>
        </tr>
        <tr v-if="loading" style="height: 10rem; width: 100%">
          <td :colspan="columns.length + 1" class="w-100">Buscando registros...</td>
        </tr>
        <tr v-else-if="filteredData.length === 0 && !isSearching && !loading" style="height: 10rem;">
          <td :colspan="columns.length + 1" class="w-100">No hay registros</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div style="display: flex; align-items: flex-start; justify-content: space-between;">
      <div v-if="totalRegistros>0">Mostrando registros del {{ (page - 1) * max + 1 }} al
        {{ Math.min(page * max, totalRegistros) }} de un total
        de {{ totalRegistros }} registros
      </div>
      <div v-else>No hay registros para mostrar</div>
      <div>
        <b-pagination
            v-model="page"
            :total-rows="totalPages"
            :per-page="1"
            pills
            @click.stop
            aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    fetchData: {
      type: Function,
      required: true,
    },
    busquedaRetardada: {
      type: Boolean,
      default: true,
    },
    SeleccionMultiple: {
      type: Boolean,
      default: false,
    },
    SeleccionSimple: {
      type: Boolean,
      default: false,
    },
    keySeleccion: {
      type: String,
      required: false,
    },
    fetchDataAllSelect: {
      type: Function,
      required: false,
    },
    BusquedaBoton:{
      type: Boolean,
      default: false,
      required: false,
    }
  },
  data() {
    return {
      abortController: null,
      // Ajuste tabla
      search: "",
      page: 1,
      max: 5,
      totalPages: 0,
      totalRegistros: 0,
      options: [5, 10, 20, 50, 100],
      orden: {
        columna: "",
        ascendente: true,
      },
      loading: false,
      debounceTimeout: null,
      isSearching: false,

      data: [],
      seleccionados: [],
      seleccion: null,
    };
  },
  computed: {
    filteredData() {
      return this.data;
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      this.isSearching = false;
      this.totalRegistros = 0;
      this.data = [];

      const payload = {
        filtro: this.search,
        orden: this.orden,
        cantDatos: this.max,
        paginacion: this.page,
      };
      try {
        const {total, data} = await this.fetchData(payload);
        this.data = data;
        this.totalRegistros = total;
        this.totalPages = Math.ceil(total / this.max);
      } catch (error) {
        console.error("Error fetching data:", error);
        this.data = [];
      } finally {
        this.loading = false;
      }
    },
    ordenar(columna) {
      if (this.orden.columna === columna) {
        this.orden.ascendente = !this.orden.ascendente;
      } else {
        this.orden.columna = columna;
        this.orden.ascendente = true;
      }
      this.getData();
    },
    async handleSearch() {
      if (this.busquedaRetardada) {
        if (this.search === "") {
          await this.getData();
        } else {
          this.loading = true;
          this.isSearching = true;
          // Limpiar el timeout anterior
          clearTimeout(this.debounceTimeout);
          // Reiniciar la data
          this.data = [];
          // Establecer un nuevo timeout
          this.debounceTimeout = setTimeout(async () => {
            await this.getData();
            this.loading = false;
            this.isSearching = false;
          }, 270);
        }
      } else {
        this.loading = true;
        await this.getData();
        this.loading = false;
      }
    },
    SeleccionDatoUnicoSimple(row) {
      if (this.keySeleccion !== undefined) this.seleccion = row[this.keySeleccion];
      else this.seleccion = row;
      this.$emit("KeySelectionSimple", this.seleccion);
      this.$emit("KeySelectionSimpleRow", row);
    },
    SeleccionDatosMultiple(row) {
      if (this.keySeleccion !== undefined) {
        const key = row[this.keySeleccion];
        if (this.seleccionados.some((item) => item === key)) {
          this.seleccionados = this.seleccionados.filter(item => item !== key);
        } else this.seleccionados.push(key);
      } else {
        if (this.seleccionados.some((item) => JSON.stringify(item) === JSON.stringify(row))) {
          this.seleccionados = this.seleccionados.filter(
              (item) => JSON.stringify(item) !== JSON.stringify(row)
          );
        } else {
          this.seleccionados.push(row);
        }
      }
      this.$emit("KeySelection", this.seleccionados);
    },
    SeleccionadosDatosMultiples(row) {
      if (this.keySeleccion === undefined) {
        return this.seleccionados.some((item) => JSON.stringify(item) === JSON.stringify(row));
      } else return this.seleccionados.some((item) => item === row[this.keySeleccion]);
    },
    SeleccionadoDatoSimple(row) {
      if (this.keySeleccion !== undefined) {
        return this.seleccion && this.seleccion === row[this.keySeleccion];
      } else return this.seleccion && this.seleccion === row;
    },
    async SelccionarTodos() {
      var TodosSeleccionados = this.data.length > 0 && this.seleccionados.length === this.totalRegistros;
      if (!TodosSeleccionados) {
        this.seleccionados = await this.fetchDataAllSelect(this.search);
      } else {
        this.seleccionados = [];
      }
      this.$emit("KeySelection", this.seleccionados.map(item => item));
    }
  },
  watch: {
    search: function(){
      if (!this.BusquedaBoton){
        this.handleSearch();
      }
    },
    page: "getData",
    max: "getData",
  },
  mounted() {
    this.getData();
  },
  created() {
    this.$on("actualizar", this.getData);
    this.$on("EstablecesDatoSeleccionadoSimple", (dato) => {
      this.seleccion = dato;
    });
  }
};
</script>

<style scoped>
.sortable-column {
  cursor: pointer;
  position: relative;
  user-select: none;
}

.column-header {
  border: 1.5px solid #cacaca;
  padding: 10px !important;
  color: #07459b !important;
}

.sort-icons {
  margin-left: 5px;
  font-size: 0.8rem;
  vertical-align: middle;
  color: #07459b;
}

.column-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-campues {
  color: #363636;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ececec;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}

</style>

