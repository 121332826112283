<template>
  <b-container fluid>
    <form class="form" @submit.prevent="saveCuenta">
      <card class="my-card">
        <template v-slot:headerTitle>
          <span class="card-title my-2 h4"><b>Crear cuenta</b></span>
          <div>
            <span class="text-muted">General</span>
            <p style="width: 60%">Utilice el tipo de tarjeta de crédito para el saldo de apertura negativo.
              El número es esencial para conciliar las cuentas correctamente.
              La cuenta predeterminada registrará todas las transacciones si no se selecciona otra.</p>
          </div>
        </template>
        <h4 class="px-4 py-4"><b>Tipo de cuenta</b></h4>
        <div class="wrapper-fields">
          <div class="wrapper-input">
            <vs-button class="button"
                       :class="{ 'active-button': tipoCuenta !== 'banco' }"
                       v-on:click.prevent="cambiartipoCuenta('banco')">Banco
            </vs-button>
          </div>
          <div class="wrapper-input">
            <vs-button class="button" :class="{ 'active-button': tipoCuenta !== 'Card' }"
                       v-on:click.prevent="cambiartipoCuenta('Card')">Tarjeta de credito
            </vs-button>
          </div>
        </div>
        <h4 class="px-4 py-4"><b>Información General</b></h4>
        <div class="wrapper-fields">
          <div class="wrapper-input" title="Nombre">
            <label for="NombreCuenta">Nombre de la cuenta</label>
            <b-form-input id="NombreCuenta" name="NombreCuenta" required></b-form-input>
          </div>
          <div class="wrapper-input" title="Numero">
            <label for="NumeroCuenta">Numero</label>
            <b-form-input id="NumeroCuenta" name="NumeroCuenta" required></b-form-input>
          </div>
          <div class="wrapper-input" title="Divisa">
            <label for="DivisaId">Divisa</label>
            <HelexSelect id="DivisaId" name="DivisaId" :options="Listdivisas" option-label="nombre" option-value="id"
                         optionValue="id" required>
            </HelexSelect>
          </div>
          <div class="wrapper-input" title="Saldo Inicial">
            <label for="SaldoInicial">Saldo inicial</label>
            <b-form-input id="SaldoInicial" type="number"
                          name="SaldoInicial"
                          required></b-form-input>
          </div>
        </div>
        <h4 v-if="tipoCuenta==='banco'" class="px-4 py-4"><b>Cuenta por defecto</b></h4>
        <div v-if="tipoCuenta==='banco'" class="wrapper-fields">
          <vs-button-group>
            <vs-button class="buttonDefault"
                       v-on:click.prevent="cambiarCuentaDefecto(true)"
                       :class="{'buttonDefaultActive': cuentaDefault}">
              SI
            </vs-button>
            <vs-button class="buttonDefault"
                       v-on:click.prevent="cambiarCuentaDefecto(false)"
                       :class="{'buttonDefaultFalse': !cuentaDefault}">
              NO
            </vs-button>
          </vs-button-group>
        </div>
        <h4 class="px-4 py-4"><b>Banco</b></h4>
        <div class="wrapper-fields">
          <div class="wrapper-input" title="Nombre del banco">
            <label for="NombreBanco">Nombre del banco</label>
            <b-form-input id="NombreBanco" name="NombreBanco"></b-form-input>
          </div>
          <div class="wrapper-input" title="Numero del banco">
            <label for="NumeroBanco">Numero del banco</label>
            <b-form-input id="NumeroBanco" name="NumeroBanco"></b-form-input>
          </div>
          <div class="wrapper-input" title="Direccion del banco">
            <label for="DireccionBanco">Direccion del banco</label>
            <b-form-textarea id="DireccionBanco" name="DireccionBanco"></b-form-textarea>
          </div>
        </div>
        <div class="wrapper-button" style="display: flex;">
          <vs-button class="button" dark @click.prevent="$router.push('Cuentas')">Cancelar</vs-button>
          <vs-button class="button">Guardar</vs-button>
        </div>
      </card>
    </form>
  </b-container>
</template>
<script>
export default {
  name: 'CreateAccounts',
  data() {
    return {
      tipoCuenta: 'banco',
      cuentaDefault: false,
      Listdivisas: []
    }
  },
  methods: {
    cambiartipoCuenta(tipo) {
      this.tipoCuenta = tipo;
    },
    cambiarCuentaDefecto(opcion){
      this.cuentaDefault = opcion;
    },
    async saveCuenta(event) {
      var id = JSON.parse(localStorage.getItem("credenciales"))
      const cuenta = Object.fromEntries(new FormData(event.target).entries());
      cuenta["TipoCuenta"]=this.tipoCuenta;
      const data = {
        "Cuenta": cuenta,
        "CreadorId":id.personaId,
        "DivisaId": cuenta["DivisaId"]
      }
      delete cuenta["DivisaId"];
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "CONTAccount/CraerCuenta",
          data
        });
        if (res["respuesta"]) {
          await this.$router.push('Cuentas');
        } else {
          this.$vs.notification({
            position:'top-center',
            title:'Error',
            text: res["mensaje"]
          })
        }
      } catch (error) {
        this.Listdivisas = [];
        console.log(error)
        this.$vs.notification({
          position:'top-center',
          title:'Error',
          text: error
        })
      }
    },
    async obtenerDivisasEmpresa(){
      try {
        var id = JSON.parse(localStorage.getItem("credenciales"));
        let res = await this.$store.dispatch("hl_get", {
          path: "GDivisas/ObtenerDivisasEmpresa/"+id.personaId,
        });
        if (res["respuesta"]) {
          this.Listdivisas = res["data"]
        } else {
          this.Listdivisas = [];
        }
      } catch (error) {
        this.Listdivisas = [];
        console.log("err", error);
      }
    }
  },
  async mounted() {
    this.obtenerDivisasEmpresa();
  }
}
</script>

<style scoped>
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 0 48px 16px;
}

.wrapper-button {
  align-self: flex-end;
  padding: 0 48px 16px;
}

.active-button {
  background-color: white !important;
  color: black !important;
  border: 2px solid black !important;
}

.buttonDefault{
  border-radius: 0 !important;
  color: black !important;
  background-color: #bfbfbf;
}

.buttonDefaultActive{
  color: black !important;
  background-color: #57ff14;
}

.buttonDefaultFalse{
  color: white !important;
  background-color: #ff1818;
}
</style>