<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold" style='font-size: 30px'>Nueva Categoría</h4>
          </template>
          <form class='px-4' style='height: auto;' @submit.prevent="saveCategoria">
            <div class="row pt-4 align-items-center">
              <div class='col-12 col-md-6 pb-3 pb-md-0'>
                <label for="nombre">nombre *</label>
                <b-form-input id="nombre" name='nombre' required placeholder="Ingrese Nombre" trim></b-form-input>
              </div>
            </div>
            <div class='pt-3'>
              <label for="descripcion">Descripción</label><br />
              <b-form-textarea rows="6" class='p-3' id='descripcion' name='descripcion'
                placeholder="Ingrese Descripción" required></b-form-textarea>
            </div>
            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" :loading="waiting">Guardar</vs-button>
              <vs-button size="large" class='bg-danger' @click.prevent="$router.push('Categorias')">Cancelar</vs-button>
            </div>
          </form>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Swal from 'sweetalert2';
  export default {
    data() {
      return {
        waiting: false,
        EmpresaId: null,
        saveCategoriaobjet: {
          Categoria: Object,
          EmpresaId: null,
        },
      }
    },
    mounted() {
      const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
      if (valor) {
        this.saveCategoriaobjet.EmpresaId = valor.empresa.id;
      }
    },
    methods: {
      async saveCategoria(event) {
        const data = Object.fromEntries(new FormData(event.target).entries());
        this.saveCategoriaobjet.Categoria = data;

        try {
          this.waiting = true;
          const response = await this.$store.getters.fetchPost({
            path: `CONTCategoria/CrearCategoria`,
            data: this.saveCategoriaobjet
          })

          if (response.ok) {
            Swal.fire({
              title: 'Categoria creada',
              text: 'Se ha guardado la categoria',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.$router.push('Categorias');
            })
          } else {
            Swal.fire({
              title: 'Error',
              text: 'No se ha guardado la Categoria',
              icon: 'error',
              confirmButtonText: 'Aceptar'
            })
          }

        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'No se ha guardado la Categoria',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          })
        } finally {
          this.waiting = false;
        }
      },
    },
  }
</script>

<style>
  .vs-select__input {
    background-color: transparent !important;
    border: 1px solid var(--vs-colors--dark) !important;
    width: 100%;
  }

  .vs-select-content {
    max-width: 50%;
  }

  .vs-input {
    width: 100%;
    border-radius: 7px;
  }
</style>