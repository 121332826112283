<template>
  <div class="speech-input position-relative">
    <textarea
      v-model="text"
      ref="textarea"
      class="form-control-with-icon form-control"
      :placeholder="placeholderText"
      @input="adjustHeight"
    ></textarea>
    <i
      v-if="isSpeechRecognitionSupported"
      v-b-tooltip.hover
      :title="isListening ? 'Detener grabación' : 'Iniciar grabación'"
      class="fas fa-microphone microphone-icon"
      :class="{ recording: isListening }"
      @click="toggleListening"
    ></i>

    <span v-if="text" class="text-danger pointer" @click="clearText">
      <i class="fas fa-times"></i>
      Limpiar texto
    </span>
  </div>
</template>

<script>
import annyang from "annyang";

export default {
  name: "SpeechInput",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text: this.value,
      isListening: false,
      isSpeechRecognitionSupported: false,
    };
  },
  computed: {
    placeholderText() {
      return this.isSpeechRecognitionSupported
        ? "Escribe o habla para ingresar texto..."
        : "Escribe para ingresar texto...";
    },
  },
  watch: {
    text(newValue) {
      this.$emit("input", newValue); // Emitimos el texto al componente padre
    },
  },
  mounted() {
    // Verifica si SpeechRecognition es compatible
    this.isSpeechRecognitionSupported =
      "SpeechRecognition" in window || "webkitSpeechRecognition" in window;

    this.adjustHeight();
  },
  methods: {
    toggleListening() {
      if (annyang && annyang.isListening()) {
        annyang.abort();
        this.isListening = false;
      } else {
        this.startListening();
      }
    },
    startListening() {
      if (annyang) {
        annyang.removeCallback("result");
        annyang.addCallback("result", (phrases) => {
          const newText = phrases[0];
          this.text = this.text ? `${this.text} ${newText}` : newText;
          this.adjustHeight();
        });

        annyang.setLanguage("es-ES");
        annyang.start({ autoRestart: true });
        this.isListening = true;
      } else {
        alert("Annyang no es compatible con este navegador.");
      }
    },
    adjustHeight() {
      const textarea = this.$refs.textarea;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
        textarea.scrollTop = textarea.scrollHeight;
      }
    },
    clearText() {
      this.text = "";
      const textarea = this.$refs.textarea;
      if (textarea) {
        textarea.style.height = "auto";
      }
    },
  },
};
</script>

<style scoped>
/* Contenedor principal */
.speech-input {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

/* Campo de texto con ajuste dinámico */
textarea.form-control-with-icon {
  overflow-y: auto;
  resize: none;
  max-height: 200px;
  line-height: 1.5;
  transition: height 0.2s ease-in-out;
}

/* Icono del micrófono */
.microphone-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #6c757d;
  transition: color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

/* Estado de grabación */
.microphone-icon.recording {
  color: rgb(242, 19, 67);
  animation: blink 1s infinite;
}

/* Onda */
.microphone-icon.recording::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid rgb(242, 19, 67);
  border-radius: 50%;
  opacity: 0;
  animation: pulse 1s infinite;
}

/* Animación de parpadeo */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Animación de onda */
@keyframes pulse {
  0% {
    opacity: 0.6;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2.5);
  }
}
</style>
