var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mini-chat bg-card p-2 border rounded mx-auto",staticStyle:{"height":"auto","max-height":"50vh","max-width":"700px"}},[_c('div',{staticClass:"chat-messages p-2 overflow-auto",staticStyle:{"height":"auto","min-height":"10vh","max-height":"30vh","border":"1px solid var(--body-bg)","border-radius":"5px"}},[(_vm.messages.length === 0)?_c('p',{staticClass:"text-center"},[_vm._v(" No hay comentarios aún. ")]):_vm._e(),_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,staticClass:"d-flex my-3",class:{
        'flex-row-reverse': message.user === 'Tú',
        'flex-row': message.user !== 'Tú',
      }},[_c('vs-avatar',{staticClass:"mt-3",attrs:{"circle":"","size":"30","primary":"","danger":message.user === ''},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" "+_vm._s(message.user)+" ")]},proxy:true}],null,true)},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(message.user === ''),expression:"message.user === ''"}],staticClass:"fas fa-user",staticStyle:{"font-size":"0.7rem"}})]),_c('div',{staticClass:"d-flex flex-column mx-2"},[_c('small',{class:{
            'text-right': message.user === 'Tú',
          }},[_vm._v(" "+_vm._s(_vm._f("moment")(message.fecha,"from", "now"))+" ")]),_c('div',{staticClass:"chat-text bg-dark",class:{
            right: message.user === 'Tú',
          }},[_vm._v(" "+_vm._s(message.text)+" ")])])],1)})],2),_c('div',{staticClass:"chat-input mt-2"},[_c('form',{staticClass:"d-flex align-items-center",on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('b-form-textarea',{staticClass:"mb-2 input-chat",attrs:{"placeholder":"Escribe un comentario...","rows":"1","max-rows":"3","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendMessage.apply(null, arguments)}},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}}),_c('vs-button',{staticClass:"mt-0",staticStyle:{"max-height":"3rem","width":"3.2rem","height":"3rem","border-radius":"100% !important"},attrs:{"type":"submit","primary":"","icon":""}},[_c('i',{staticClass:"fa-solid fa-paper-plane"})])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }