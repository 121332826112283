<template>
  <HXContentCard title="Modificar Empresa" :toBack="{ name: 'empresa' }">
    <InformacionGeneral/>
    <hr/>
    <InformacionContact/>
    <hr/>
    <div class='px-3 d-flex align-items-center'>
      <b-form-checkbox id="aportesEnLinea" size="sm" v-model="ApostesEnLinea" name="aportesEnLinea">Usar Aportes en
        Linea
      </b-form-checkbox>
    </div>
    <template v-if='ApostesEnLinea'>
      <CredencialesAportesEnLinea/>
      <hr/>
    </template>
    <template v-else>
      <CredencialesProveedor/>
    </template>
    <InformacionVentas/>
    <hr/>
    <div class="p-4">
      <h3 class="text-white-50"><strong> IFrames de la empresa </strong></h3>
      <div class="row">
        <div class="col-12">
          <BVForm :schema="iframePesvSchema" :callBackSubmit="saveIframe" title="Iframe PESV"
                  :injectData="{ seccion: 'PESV' }"></BVForm>
        </div>
        <div class="col-12">
          <BVForm :schema="iframeSgsstSchema" :callBackSubmit="saveIframe" title="Iframe SGSST"
                  :injectData="{ seccion: 'SGSST' }"></BVForm>
        </div>
      </div>
    </div>
  </HXContentCard>
</template>
<script>
import BVForm from "@/components/BVForm/BVForm";
import {core} from "../../../config/pluginInit";
import Swal from "sweetalert2";
import InformacionGeneral from "./EditarEmpresa/InformacionGeneral.vue";
import InformacionContact from "./EditarEmpresa/InformacionContact.vue";
import CredencialesAportesEnLinea from "./EditarEmpresa/CredencialesAportesEnLinea.vue";
import InformacionVentas from "@/views/Helexium/Empresas/EditarEmpresa/InformacionVentas.vue";

export default {
  name: "DataTable",
  components: {
    InformacionVentas,
    BVForm,
    InformacionGeneral,
    InformacionContact,
    CredencialesAportesEnLinea,
  },
  data() {
    return {
      item: {},
      id: "",
      options: {},
      ListSector: [],
      iframePesvSchema: [],
      iframeSgsstSchema: [],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.id = this.$route.params.id;
    core.index();
    window.$("#datatable_empresa").DataTable();
    await this.getData();
    await this.getListSector();
    this.$isLoading(false);
  },
  methods: {
    saveIframe(self) {
      let data = self.data;
      data["empresaId"] = this.id;

      self.loader = true;
      this.$store.getters
          .fetchPost({path: "IFrame/CreateIframe", data})
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("No se ha guardado el iframe");
          })
          .then(() => {
            Swal.fire({
              title: "Iframe guardado",
              text: "Se ha guardado el iframe en la empresa",
              icon: "success",
              confirmButtonText: "Aceptar",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.message,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          })
          .finally(() => {
            self.loader = false;
          });
    },
    actualizaSector(e) {
      this.item.sectorId = e.target.value;
    },
    async getData() {
      try {
        // let empresaZ = await this.$store.getters.fetchGet({ path: `Empresa/GetEmpresaById/${this.id}`})
        let res = await this.$store.dispatch("hl_get", {
          path: "Empresa/GetEmpresa/" + this.id,
        });
        this.item = res;

        (this.iframePesvSchema = JSON.parse(JSON.stringify(iframeSchema))),
            (this.iframeSgsstSchema = JSON.parse(JSON.stringify(iframeSchema)));

        let responseIframePesv = await this.$store.getters.fetchGet({
          path: `IFrame/empresa/${this.$route.params.id}/seccion/PESV/last`,
        });
        let iframePesv = await responseIframePesv.json();
        this.iframePesvSchema.forEach((item) => {
          item.value = iframePesv[item.name];
        });

        let responseIframeSgsst = await this.$store.getters.fetchGet({
          path: `IFrame/empresa/${this.$route.params.id}/seccion/SGSST/last`,
        });
        let iframeSgsst = await responseIframeSgsst.json();
        this.iframeSgsstSchema.forEach((item) => {
          item.value = iframeSgsst[item.name];
        });
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
    async getListSector() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Sector/ListSector/",
        });
        this.ListSector = res;

      } catch (error) {
        this.ListSector = {};
        console.log("err", error);
      }
    },
    async saveData() {
      try {
        if (this.item.sectorId == "00000000-0000-0000-0000-000000000000")
          this.item.sectorId = this.item.sector.id;
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/Update/",
          data: this.item,
        });

        if (res) this.$router.push("/helex/empresa");
        else alert("Verificar");
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
  },
};

const iframeSchema = [
  {
    validations: "required",
    label: "Url",
    name: "url",
    type: "text",
    value: null,
  },
  {
    validations: "",
    label: "Descripción",
    name: "descripcion",
    type: "text",
    value: null,
  },
];
</script>
