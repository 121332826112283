var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HXContentCard',{attrs:{"title":"Carga de soportes de Cuenta de cobro","defaultToBack":!_vm.showInit}},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},_vm._l((_vm.itemsFiles),function(item,index){return _c('div',{key:index,staticClass:"mt-2"},[_c('div',{staticClass:"d-flex bg-dark rounded pointer"},[_c('div',{staticClass:"align-items-center rounded bg-dark py-3 px-2",staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.toggle(index)}}},[_c('div',[_c('i',{staticClass:"mr-2",class:[
                    'fas',
                    _vm.isActive(index) ? 'fa-chevron-down' : 'fa-chevron-right',
                  ]}),_c('span',[_vm._v(_vm._s(item.title))])])]),(item.url)?_c('div',{staticClass:"d-flex align-items-center"},[(item.estado == -1)?_c('div',{staticClass:"file-modify-badge"},[_c('i',{staticClass:"fas fa-file-arrow-up fa-2x p-2"})]):_vm._e(),(item.estado != null)?_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"lego-badge mx-0 py-1",staticStyle:{"cursor":"default"},attrs:{"title":item.estado == 1
                    ? 'Soporte aprobado por el auditor'
                    : item.estado == 0
                    ? 'Soporte rechazado por el auditor'
                    : 'Soporte pendiente de revisión por el auditor',"transparent":"","active":"","size":"mini","danger":item.estado == 0,"warn":item.estado == -1,"success":item.estado == 1}},[_c('i',{class:`fas fa-${
                    item.estado == 1
                      ? 'file-circle-check'
                      : item.estado == 0
                      ? 'ban'
                      : 'clock'
                  } mr-1`}),_vm._v(" "+_vm._s(item.estado == 1 ? "Aprobado" : item.estado == 0 ? "Rechazado" : "Revisión")+" ")]):_vm._e(),_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"width":"2.5rem"},attrs:{"success":"","icon":"","title":"Visualizar"},on:{"click":function($event){return _vm.gotoFile(item.url)}}},[_c('i',{staticClass:"fas fa-eye"})]),_c('vs-button',{staticStyle:{"width":"2.5rem"},attrs:{"primary":"","icon":""},on:{"click":function($event){return _vm.toggleComponent(index)}}},[_c('i',{class:_vm.showDivs[index]
                      ? 'fa-solid fa-comment-dots'
                      : 'fa-solid fa-file'})])],1):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive(index)),expression:"isActive(index)"}],staticClass:"accordion-content mx-1 mt-2"},[(item.url)?_c('div',{staticClass:"d-flex justify-content-end mb-2"},[_c('span',{staticClass:"mx-2"},[_c('i',{staticClass:"fas fa-cloud-arrow-up"}),_vm._v(" Última subida: "+_vm._s(_vm._f("moment")(item.fechaSubida,"from", "now"))+" ")]),(item.fechaEstado)?_c('span',{staticClass:"mx-2"},[_c('i',{staticClass:"fas fa-circle-check"}),_vm._v(" Última auditoría: "+_vm._s(_vm._f("moment")(item.fechaEstado,"from", "now"))+" ")]):_vm._e()]):_vm._e(),(_vm.showDivs[index])?_c('DropFile',{attrs:{"id":index + 1,"archivo":{
                Id: item.id,
                PersonaId: _vm.personaId,
                NombreArchivo: item.name,
                UrlArchivo: item.url,
                FechaSubida: item.fechaSubida,
                EstadoGestion: item.estado,
              },"urlDir":`users/${_vm.personaId}/CDC/${_vm.mes}/`,"pathPost":"CDC/AddArchivo","pathPutArchivos":'CDC/UpdateArchivo/' + item.id,"pathPutGestion":'CDC/UpdateEstadoGestion/' + item.id,"injectData":{
                PersonaId: _vm.personaId,
                NombreArchivo: item.name,
                UrlArchivo: `https://helexiumfiles.com/users/${_vm.personaId}/CDC/${_vm.mes}/`,
                FechaSubida: _vm.$moment
                  .tz(new Date(), 'America/Bogota')
                  .format('YYYY-MM-DDTHH:mm:ss'),
              },"fileTypes":[
                'application/pdf',
                'application/docx',
                'image/jpeg',
                'image/png',
                'image/gif',
                'application/msword',
                'application/vnd.ms-excel',
                'application/vnd.ms-powerpoint',
                'text/plain',
                'image/*',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.ms-powerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
              ]}}):_c('div',[_c('transition',{attrs:{"name":"fade"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"leave":_vm.leave}},[_c('ComentariosCuentaCobro',{staticClass:"div-comentario-v2",attrs:{"dataSendCommentFile":_vm.buildObjectCommentFile(item.id),"commentsFiles":item.comments}})],1)],1)],1)])}),0)]),_c('hr',{staticClass:"w-100",staticStyle:{"height":"1px","background":"var(--dark)"}}),_c('div',{staticClass:"d-flex pt-4 justify-content-end"},[_c('vs-button',{attrs:{"icon":"","disabled":_vm.puedeCargarSoportes,"size":"large","loading":_vm.waiting},on:{"click":function($event){return _vm.guardarArchivos()}}},[_c('i',{staticClass:"fas fa-cloud-arrow-up mr-2"}),_vm._v(" Cargar soportes ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }