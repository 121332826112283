<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between align-items-center px-0  pt-0">
              <h3 class="card-title font-weight-bold" style='font-size: 30px'>Descuentos</h3>
              <div class='d-flex' style='gap: .4rem;'>
                <vs-button @click="$router.push('NuevoDescuento')">Crear Descuento</vs-button>
              </div>
            </div>
          </template>

          <div class='p-5'>
            <DataTableLotes ref="dataTableLotes" :fetch-data="fetchData" :columns="columns">
              <template v-slot:Porcentaje="{ row }">
                {{row.Porcentaje}} %
              </template>
              <template v-slot:Id="{ row }">
                <div class="d-flex justify-content-center" style="gap: .5rem;">
                  <vs-button v-b-tooltip.hover title='Editar' icon
                             @click="$router.push(`EditarDescuento/${row.Id}`)">
                    <i class="fa-solid fa-pen"></i>
                  </vs-button>
                  <vs-button v-b-tooltip.hover title='Eliminar' danger icon class=' '
                             @click="deleteDescuento(row.Id)">
                    <i class="fa-solid fa-trash"></i>
                  </vs-button>
                </div>
              </template>
            </DataTableLotes>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Swal from 'sweetalert2';
import DataTableLotes from "@/components/Datatable/DataTableLotes.vue";

export default {
  components: {DataTableLotes},
  data: () => ({
    columns: [
      {label: "Nombre", key: "Nombre", sortable: true, width: '400px'},
      {label: "Descripción", key: "Descripcion", sortable: true, width: '300px'},
      {label: "Porcentaje", key: "Porcentaje", sortable: true, width: '100px'},
      {label: "Inicio", key: "FechaInicio", sortable: true, width: '200px'},
      {label: "Fin", key: "FechaFin", sortable: true, width: '200px'},
      {label: "Tipo", key: "Tipo", sortable: true, width: '150px'},
      {label: "Frecuencia", key: "Frecuencia", sortable: true, width: '150px'},
      {label: "Activo", key: "Activo", sortable: true, width: '150px'},
      {label: "Opciones", key: "Id", sortable: false, width: '100px'}
    ],
    empresaId: null,
  }),
  async created() {
    const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
    if (valor) {
      this.empresaId = valor.empresa.id;
    }
  },
  methods: {
    async fetchData({filtro, orden, cantDatos, paginacion}) {
      if (!this.empresaId) {
        console.error("EmpresaId no está definido.");
        return {TotalDatos: 0, data: []};
      }
      let params = {
        filtro: filtro,
        orden: orden,
        cantDatos: cantDatos,
        paginacion: paginacion
      };
      let data = [];
      let total = 0;

      await Promise.all([
        this.$store.dispatch('hl_get', {
          path: `ContDescuentos/ObtenerCantidadDescuentosEmpresaId/${this.empresaId}?filtro=${params.filtro}`
        }),
        this.$store.dispatch('hl_post', {
          path: 'ContDescuentos/ObtenerDescuentosEmpresaId/' + this.empresaId,
          data: params
        })
      ]).then(([cantidadDatos, Datos]) => {
        if (cantidadDatos.respuesta) {
          total = cantidadDatos.data;
        }
        if (Datos.respuesta) {
          data = Datos.data;
          data.forEach(Element => {
            Element.FechaInicio = this.$moment.tz(Element.FechaInicio, "America/Bogota").format("MM-DD-YYYY");
            Element.FechaFin = this.$moment.tz(Element.FechaFin, "America/Bogota").format("MM-DD-YYYY")
          })
        }
      });
      return {total, data};
    },
    async deleteDescuento(id) {
      await Swal.fire({
        title: '¿Estás seguro que deseas eliminar este descuento?',
        text: "¡No podrás revertir esta acción!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, borrarlo!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            this.$store.dispatch("hl_delete", {
              path: `ContDescuentos/EliminarDescuento/${id}`,
            }).then(response => {
              if (response.respuesta) {
                this.$refs.dataTableLotes.$emit("actualizar");
                Swal.fire("Listo!", "Categoria eliminada Correctamente", "success");
              } else {
                Swal.fire("Error", response.mensaje, "error")
              }
            })
          } catch (error) {
            await Swal.fire("Error", error, "error");
          }
        }
      });
    },
  },
}
</script>

<style>
.header-title {
  width: 100% !important;
}
</style>