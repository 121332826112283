<template>
  <HXContentCard
    title="Carga de soportes de Cuenta de cobro"
    :defaultToBack="!showInit"
  >
    <div class="p-4">
      <div class="row">
        <div class="col-12">
          <!-- {{ archivosCargados }}
          {{ archivosSubidos }} -->
          <div class="mt-2" v-for="(item, index) in itemsFiles" :key="index">
            <div class="d-flex bg-dark rounded pointer">
              <div
                style="width: 100%"
                class="align-items-center rounded bg-dark py-3 px-2"
                @click="toggle(index)"
              >
                <div>
                  <i
                    class="mr-2"
                    :class="[
                      'fas',
                      isActive(index) ? 'fa-chevron-down' : 'fa-chevron-right',
                    ]"
                  ></i>
                  <span>{{ item.title }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center" v-if="item.url">
                <!-- soporte modificado -->
                <div class="file-modify-badge" v-if="item.estado == -1">
                  <i class="fas fa-file-arrow-up fa-2x p-2"></i>
                </div>
                <!-- badge estado gestion -->
                <vs-button
                  v-if="item.estado != null"
                  v-b-tooltip.hover
                  :title="
                    item.estado == 1
                      ? 'Soporte aprobado por el auditor'
                      : item.estado == 0
                      ? 'Soporte rechazado por el auditor'
                      : 'Soporte pendiente de revisión por el auditor'
                  "
                  class="lego-badge mx-0 py-1"
                  transparent
                  active
                  size="mini"
                  :danger="item.estado == 0"
                  :warn="item.estado == -1"
                  :success="item.estado == 1"
                  style="cursor: default"
                >
                  <i
                    :class="`fas fa-${
                      item.estado == 1
                        ? 'file-circle-check'
                        : item.estado == 0
                        ? 'ban'
                        : 'clock'
                    } mr-1`"
                  ></i>
                  {{
                    item.estado == 1
                      ? "Aprobado"
                      : item.estado == 0
                      ? "Rechazado"
                      : "Revisión"
                  }}
                </vs-button>

                <!-- acciones -->
                <vs-button
                  style="width: 2.5rem"
                  success
                  icon
                  v-b-tooltip.hover
                  title="Visualizar"
                  @click="gotoFile(item.url)"
                >
                  <i class="fas fa-eye"></i>
                </vs-button>
                <vs-button
                  style="width: 2.5rem"
                  primary
                  icon
                  @click="toggleComponent(index)"
                >
                  <i
                    :class="
                      showDivs[index]
                        ? 'fa-solid fa-comment-dots'
                        : 'fa-solid fa-file'
                    "
                  ></i>
                </vs-button>
              </div>
            </div>
            <div v-show="isActive(index)" class="accordion-content mx-1 mt-2">
              <div class="d-flex justify-content-end mb-2" v-if="item.url">
                <span class="mx-2">
                  <i class="fas fa-cloud-arrow-up"></i>
                  Última subida:
                  {{ item.fechaSubida | moment("from", "now") }}
                </span>
                <span class="mx-2" v-if="item.fechaEstado">
                  <i class="fas fa-circle-check"></i>
                  Última auditoría:
                  {{ item.fechaEstado | moment("from", "now") }}
                </span>
              </div>
              <DropFile
                v-if="showDivs[index]"
                :id="index + 1"
                :archivo="{
                  Id: item.id,
                  PersonaId: personaId,
                  NombreArchivo: item.name,
                  UrlArchivo: item.url,
                  FechaSubida: item.fechaSubida,
                  EstadoGestion: item.estado,
                }"
                :urlDir="`users/${personaId}/CDC/${mes}/`"
                pathPost="CDC/AddArchivo"
                :pathPutArchivos="'CDC/UpdateArchivo/' + item.id"
                :pathPutGestion="'CDC/UpdateEstadoGestion/' + item.id"
                :injectData="{
                  PersonaId: personaId,
                  NombreArchivo: item.name,
                  UrlArchivo: `https://helexiumfiles.com/users/${personaId}/CDC/${mes}/`,
                  FechaSubida: $moment
                    .tz(new Date(), 'America/Bogota')
                    .format('YYYY-MM-DDTHH:mm:ss'),
                }"
                :fileTypes="[
                  'application/pdf',
                  'application/docx',
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'application/msword',
                  'application/vnd.ms-excel',
                  'application/vnd.ms-powerpoint',
                  'text/plain',
                  'image/*',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/vnd.ms-powerpoint',
                  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.ms-excel',
                ]"
              />
              <div v-else>
                <transition
                  name="fade"
                  @before-enter="beforeEnter"
                  @enter="enter"
                  @leave="leave"
                >
                  <ComentariosCuentaCobro
                    :dataSendCommentFile="buildObjectCommentFile(item.id)"
                    :commentsFiles="item.comments"
                    class="div-comentario-v2"
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style="height: 1px; background: var(--dark)" class="w-100" />
      <div class="d-flex pt-4 justify-content-end">
        <vs-button
          icon
          @click="guardarArchivos()"
          :disabled="puedeCargarSoportes"
          size="large"
          :loading="waiting"
        >
          <i class="fas fa-cloud-arrow-up mr-2"></i>
          Cargar soportes
        </vs-button>
      </div>
    </div>
  </HXContentCard>
</template>

<script>
import Swal from "sweetalert2";

import ComentariosCuentaCobro from "../../../components/ComentariosCuentaCobro/ComentariosCuentaCobro.vue";
import { eventBus } from "../../../main";
import DropFile from "@/components/helexium/DropFileCDC.vue";
import { core } from "../../../config/pluginInit";

export default {
  components: {
    DropFile,
    ComentariosCuentaCobro,
  },
  data: () => ({
    personaId: null,
    showDivs: {},
    activeIndexes: [],
    persona: {},
    itemsFiles: [
      {
        id: "",
        title: "Cuenta de cobro",
        name: "Cuenta_de_cobro",
        url: "",
        estado: null,
        fechaEstado: "",
        fechaSubida: "",
        comments: [],
      },
      {
        id: "",
        title: "Declaración juramentada",
        name: "Declaracion_juramentada",
        url: "",
        estado: null,
        fechaEstado: "",
        fechaSubida: "",
        comments: [],
      },
      {
        id: "",
        title: "Planilla de seguridad social",
        name: "Planilla_de_seguridad_social",
        url: "",
        estado: null,
        fechaEstado: "",
        fechaSubida: "",
        comments: [],
      },
      {
        id: "",
        title: "Póliza",
        name: "Poliza",
        url: "",
        estado: null,
        fechaEstado: "",
        fechaSubida: "",
        comments: [],
      },
      {
        id: "",
        title: "Recibo de pago de la póliza",
        name: "Recibo_de_pago_de_la_poliza",
        url: "",
        estado: null,
        fechaEstado: "",
        fechaSubida: "",
        comments: [],
      },
    ],
    waiting: false,
    mes: new Date().getMonth() + 1,
    archivosCargados: [],
    archivosSubidos: [],
    archivosReSubidos: [],
  }),
  async mounted() {
    this.personaId = this.$route.params.id;
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));

    if (this.personaId == undefined) {
      this.personaId = this.persona.id;
    }

    console.log(this.persona);
    await this.getLastPeriodFiles();
    this.initializeShowDivs(); // Llamada para inicializar `showDivs`
    core.index();
    console.log(this.archivosCargados);

    let timeout; // Identificador para setTimeout

    // Evento: archivos subidos
    eventBus.$on("archivosSubidos", async ({ data, tipo }) => {
      console.log(`Archivo ${tipo}:`, data);

      if (tipo === "subido") {
        this.archivosSubidos.push(data);

        // Validación para archivos subidos
        if (this.archivosSubidos.length === this.archivosCargados.length) {
          this.handleFileProcess(timeout, "Soportes cargados con éxito!");
        }
      } else if (tipo === "resubido") {
        this.archivosReSubidos.push(data);

        // Validación para archivos resubidos
        const archivosRechazados = this.archivosCargados.filter(
          (archivo) => archivo.EstadoGestion === 0
        );

        console.log("archivosRechazados", archivosRechazados.length);
        console.log("archivosReSubidos", this.archivosReSubidos.length);

        if (this.archivosReSubidos.length === archivosRechazados.length) {
          this.handleFileProcess(timeout, "Soportes modificados con éxito!");
        }
      }
    });

    // Evento: archivo cargado individualmente
    eventBus.$on("archivoCargado", async (data) => {
      console.log("archivoCargado recibido", data);

      if (!this.archivosCargados.some((x) => x === data)) {
        this.archivosCargados.push(data);
      }

      console.log("archivosCargados-->", this.archivosCargados);
    });
  },

  computed: {
    puedeCargarSoportes() {
      let result = true;

      if (this.archivosCargados.length >= this.itemsFiles.length) {
        if (this.archivosCargados[0].ArchivoId === undefined) {
          console.log("ok", this.archivosCargados[0]);
          if (this.archivosCargados.length === this.itemsFiles.length) {
            result = false;
          }
        } else {
          console.log("ok");

          // Verificar si algún archivo está en estado 0
          const rechazados = this.archivosCargados.filter(
            (archivo) => archivo.EstadoGestion === 0
          );

          const resubidos = this.archivosCargados.filter(
            (archivo) => archivo.EstadoGestion === undefined
          );

          console.log("rechazados", rechazados.length);
          console.log("resubidos", resubidos.length);

          if (resubidos.length && rechazados.length) {
            if (resubidos.length === rechazados.length) {
              result = false;
            }
          }
        }
      }

      return result;
    },

    showInit() {
      return this.$route.query.showInit;
    },
  },
  methods: {
    async handleFileProcess(timeout, successMessage) {
      // Cancelar cualquier temporizador previo
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(async () => {
        console.log("Procesando archivos...");
        await this.getLastPeriodFiles();
        this.initializeShowDivs(); // Llamada para inicializar `showDivs`
        core.index();
        this.$forceUpdate();
        this.showToast("success", successMessage);
      }, 5000);
    },
    showToast(type, message) {
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    buildObjectCommentFile(archivoId) {
      console.log(this.personaId, archivoId);

      return {
        personaId: this.personaId,
        archivoId: archivoId,
      };
    },
    gotoFile(url) {
      // console.log(url);
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500"
      );
    },
    initData(archivos) {
      archivos.forEach((archivo) => {
        console.log(archivo);

        const item = this.itemsFiles.find(
          (item) => `_${item.name}` === archivo.NombreArchivo.split(".")[0]
        );

        console.log(item);

        if (item) {
          // Asignación reactiva
          this.$set(item, "id", archivo.ArchivoId);
          this.$set(item, "url", archivo.UrlArchivo);
          this.$set(item, "fechaSubida", archivo.FechaSubida);
          // Para propiedades condicionales:
          if (archivo.EstadoGestion != undefined) {
            this.$set(item, "estado", archivo.EstadoGestion);
            this.$set(item, "fechaEstado", archivo.FechaEstado);
          }
          if (archivo.Observaciones != undefined) {
            this.$set(item, "comments", archivo.Observaciones);
          }
        }
      });

      console.log(this.itemsFiles);
    },
    async getLastPeriodFiles() {
      const query = `
WITH ArchivosPorPeriodo AS (
    SELECT
        f.Anio,
        f.Mes,
        f.FechaInicio,
        f.FechaFin,
        a.Id AS ArchivoId,
        a.PersonaId,
        a.NombreArchivo,
        a.UrlArchivo,
        a.FechaSubida,
        COALESCE(g.Estado, -1) AS EstadoGestion  -- Si no hay estado en CDCGestion, asigna -1
    FROM
        dbo.CDCFechas f
    JOIN
        dbo.CDCArchivos a ON YEAR(a.FechaSubida) = f.Anio AND MONTH(a.FechaSubida) = f.Mes
    LEFT JOIN
        dbo.CDCGestion g ON a.Id = g.ArchivoId
    WHERE
        a.PersonaId = @personaId
),
ArchivosConObservaciones AS (
    SELECT
        a.Id AS ArchivoId,
        a.PersonaId,
        a.NombreArchivo,
        a.UrlArchivo,
        a.FechaSubida,
        COALESCE(g.Estado, -1) AS EstadoGestion,  -- Si no hay estado en CDCGestion, asigna -1
        g.FechaEstado AS FechaEstado,
        (SELECT
            o.Id AS ObservacionId,
            o.PersonaId,
            o.Observacion,
            o.FechaObservacion
         FROM
            dbo.CDCObservaciones o
         WHERE
            o.ArchivoId = a.Id
         FOR JSON PATH) AS Observaciones
    FROM
        dbo.CDCArchivos a
    LEFT JOIN
        dbo.CDCGestion g ON a.Id = g.ArchivoId
    WHERE
        a.PersonaId = @personaId
),
UltimoPeriodo AS (
    SELECT TOP 1
        f.Anio,
        f.Mes,
        f.FechaInicio,
        f.FechaFin
    FROM
        dbo.CDCFechas f
    JOIN
        ArchivosPorPeriodo ap ON ap.Anio = f.Anio AND ap.Mes = f.Mes
    ORDER BY
        f.Anio DESC, f.Mes DESC
)
SELECT
    (SELECT
        ac.*
     FROM
        ArchivosConObservaciones ac
     WHERE
        YEAR(ac.FechaSubida) = up.Anio AND MONTH(ac.FechaSubida) = up.Mes
     FOR JSON PATH) AS Archivos
FROM
    UltimoPeriodo up;

  `;

      this.$isLoading(true);
      try {
        const response = await this.$store.getters.fetchPost({
          path: `DynamicQuery/`,
          data: {
            query,
            parameters: {
              personaId: this.personaId,
            },
          },
        });
        console.log("AllData", response);
        if (response.ok) {
          const allData = await response.json();

          if (allData.data.length > 0) {
            const archivos = JSON.parse(allData.data[0].Archivos);

            console.log("Archivos", archivos);

            // Actualizar lista de archivos
            this.archivosCargados.splice(
              0,
              this.archivosCargados.length,
              ...archivos
            );

            // Inicializar datos de archivos
            this.initData(archivos);
          }
        } else {
          console.error("Error en la respuesta del servidor", response);
          Swal.fire(
            "¡Error!",
            "Ha ocurrido un error al obtener los archivos de la cuenta de cobro.",
            "error"
          );
        }

        this.$isLoading(false);
      } catch (error) {
        console.error("Error al obtener el último período:", error);
        this.$isLoading(false);
        Swal.fire(
          "¡Error!",
          "Ha ocurrido un error al obtener los archivos de la cuenta de cobro.",
          "error"
        );
      }
    },
    initializeShowDivs() {
      this.showDivs = this.itemsFiles.reduce((acc, _, index) => {
        acc[index] = true; // Asigna `true` para cada índice
        return acc;
      }, {});
      this.activeIndexes = Object.keys(this.showDivs).map(Number); // Marca todos como activos
    },
    toggle(index) {
      const idx = this.activeIndexes.indexOf(index);
      if (idx === -1) {
        this.activeIndexes.push(index);
      } else {
        this.activeIndexes.splice(idx, 1);
      }
    },
    isActive(index) {
      return this.activeIndexes.includes(index);
    },
    toggleComponent(index) {
      this.$set(this.showDivs, index, !this.showDivs[index]);
    },
    guardarArchivos() {
      eventBus.$emit("GuardandoArchivosCDC", this.archivosCargados);
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.vs-table__footer {
  background: var(--body-bg) !important;
}

.vs__dropdown-toggle {
  min-height: 2.2rem;
  height: auto;
}

.accordion-title {
  display: flex;
  align-items: center;
  background-color: var(--gray-100);
  cursor: pointer;
  border: 1px solid var(--gray-100);
}

.accordion-title i {
  margin-right: 10px;
}

.accordion-content {
  padding: 10px;
  border: 1px solid var(--gray-100);
  background-color: var(--gray-100);
}
</style>
