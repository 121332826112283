<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between align-items-center px-0  pt-0">
              <h3 class="card-title font-weight-bold" style='font-size: 30px'>Clientes</h3>
              <div class='d-flex' style='gap: .4rem;'>
                <vs-button @click="$router.push('NuevoCliente')">Nuevo Cliente</vs-button>
              </div>
            </div>
          </template>
          <div class='p-5'>
            <DataTableLotes ref="dataTableLotes" :fetch-data="fetchData" :columns="columns">
              <template v-slot:ID="{ row }">
                <div class="d-flex justify-content-center" style="gap: .5rem;">
                  <vs-button v-b-tooltip.hover title='ver' success icon class=' '>
                    <i class="fa-solid fa-eye"></i>
                  </vs-button>
                  <vs-button v-b-tooltip.hover title='Editar' icon
                             @click="$router.push(`EditarCliente/${row.ID}`)">
                    <i class="fa-solid fa-pen"></i>
                  </vs-button>
                  <vs-button v-b-tooltip.hover title='Eliminar' danger icon class=' '
                             @click.prevent='EliminarComprador(row.ID)'>
                    <i class="fa-solid fa-trash"></i>
                  </vs-button>
                </div>
              </template>
            </DataTableLotes>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Swal from "sweetalert2";
import DataTableLotes from "@/components/Datatable/DataTableLotes.vue";

export default {
  components: {DataTableLotes},
  data() {
    return {
      EmpresaId: null,
      columns: [
        {label: "Razon Social", key: "RazonSocial", sortable: true},
        {label: "NIT", key: "NIT", sortable: true},
        {label: "Correo Electronico", key: "Correo", sortable: true},
        {label: "Teléfono", key: "Telefono", sortable: true},
        {label: "Celular", key: "Celular", sortable: true},
        {label: "País", key: "Pais", sortable: true},
        {label: "Departamento", key: "Departamento", sortable: true},
        {label: "Municipio", key: "Municipio", sortable: true},
        {label: "TipoContribuyente", key: "TipoContribuyente", sortable: true},
        {label: "Opciones", key: "ID", sortable: false},
      ]
    }
  },
  created() {
    const Persona = JSON.parse(localStorage.getItem('setPersonaAct'));
    this.EmpresaId = Persona.empresa.id;
  },
  methods: {
    async fetchData({filtro, orden, cantDatos, paginacion}) {
      if (!this.EmpresaId) {
        console.error("EmpresaId no está definido.");
        return {TotalDatos: 0, data: []};
      }
      let params = {
        filtro: filtro,
        orden: orden,
        cantDatos: cantDatos,
        paginacion: paginacion
      };
      let data = [];
      let total = 0;

      await Promise.all([
        this.$store.dispatch('hl_get', {
          path: `ContComprador/ObtenerCantidadCompradores/${this.EmpresaId}?filtro=${filtro}`
        }),
        this.$store.dispatch('hl_post', {
          path: `ContComprador/ObtenerCompradores/${this.EmpresaId}`,
          data: params
        })
      ]).then(([cantidadDatos, Datos]) => {
        if (cantidadDatos.respuesta) {
          total = cantidadDatos.data;
        }
        if (Datos.respuesta) {
          data = Datos.data;
        }
      });
      return {total, data};
    },
    async EliminarComprador(Id) {
      await Swal.fire({
        title: '¿Estás seguro que deseas eliminar este cliente?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, borrarlo!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("hl_delete", {
              path: "ContComprador/EliminarComprador/" + Id
            }).then(response => {
              if (response.respuesta) {
                this.$refs.dataTableLotes.$emit("actualizar");
                Swal.fire("Listo!", "Cuenta eliminada correctamente.", "success");
              }else{
                Swal.fire("Error", response.mensaje, "error");
              }
            })
          } catch (error) {
            await Swal.fire("Error", error, "error");
          }
        }
      });
    },

  },
}
</script>

<style>
.header-title {
  width: 100% !important;
}
</style>