<template>
  <form @submit.prevent="onUpload">
    <slot name="header"> </slot>
    <input
      v-if="!archivo.Id || archivo.EstadoGestion == 0"
      type="file"
      @change="handleFileChange"
      ref="fileInput"
      style="display: none"
    />

    <div
      v-if="!archivo.Id || archivo.EstadoGestion == 0"
      class="dropzone"
      :class="{ 'is-file': isFile }"
      :style="fileBackground"
      @click="triggerFileInput"
      @drop.prevent="handleDrop"
      @dragover.prevent
      @dragenter.prevent
      @mouseover="handleMouseOver"
      @mouseleave="handleMouseLeave"
    >
      <div v-if="loader" class="loader"></div>
      <div class="overlay" v-if="imageUrl"></div>
      <div v-if="!file" class="d-flex flex-column align-items-center">
        <i
          :class="[
            'fas fa-cloud-arrow-up text-white-50',
            isHovered && 'fa-beat',
          ]"
          style="font-size: 2rem"
        ></i>
        <br />
        Haz clic o arrastra un archivo aquí.
      </div>
      <div
        v-else
        class="d-flex flex-column align-items-center"
        style="z-index: 3"
      >
        <i
          :class="`fas fa-file-circle-check ${loader && 'fa-bounce'}`"
          style="font-size: 2rem"
        ></i>
        <br />
        <span>
          El documento "
          <span class="text-primary font-weight-bold">{{ name }}</span> " ha
          sido cargado
        </span>
      </div>
    </div>
    <div v-else class="d-flex flex-column align-items-center mt-auto mb-auto">
      <i
        :class="`fas fa-file-circle-check ${loader && 'fa-bounce'}`"
        style="font-size: 2rem"
      ></i>
      <br />
      <span>
        <span class="text-primary font-weight-bold">{{
          archivo.NombreArchivo
        }}</span>
      </span>
    </div>
    <slot name="prevFooter"> </slot>
    <!-- <div class="actions mt-2" v-if="file">
      <vs-button
        v-b-tooltip.hover
        title="Cargar archivo"
        icon
        type="submit"
        :disabled="loader"
        :upload="loader"
      >
        <i class="fas fa-cloud-arrow-up"></i>
      </vs-button>

      <vs-button
        v-b-tooltip.hover
        title="Cancelar"
        danger
        icon
        @click.prevent="restaurar"
        :disabled="loader"
      >
        <i class="fas fa-xmark-circle"></i>
      </vs-button>
    </div> -->
  </form>
</template>

<script>
import { eventBus } from "../../main";

import Swal from "sweetalert2";
import _ from "lodash";
// TODO: path get auto increment que es el que existe
// Todo: path get por ultimo directorio

export default {
  props: {
    fileTypes: {
      type: Array,
      default: () => [], // ["image/jpeg", "image/png"], // Tipos MIME por defecto
    },
    urlDir: {
      type: String,
      default: () => "",
      required: true,
    },
    injectData: {
      type: Object,
      default: () => {},
    },
    pathPost: {
      type: String,
      required: true,
    },
    pathPutArchivos: {
      type: String,
      required: true,
    },
    pathPutGestion: {
      type: String,
      required: true,
    },
    pathGet: {
      type: String,
      required: false,
    },
    autoIncrement: {
      type: Boolean,
      default: () => true,
    },
    id: {
      type: Number,
      default: () => 0,
    },
    archivo: {
      type: Object,
      default: () => {},
    },
    estado: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      file: null,
      name: null,
      imageUrl: null,
      typeFile: null,
      loader: false,
      isHovered: false,
      archivosCargados: [],
    };
  },
  beforeDestroy() {
    eventBus.$off("GuardandoArchivosCDC");
  },
  async mounted() {
    console.log(this.archivo);

    eventBus.$on("GuardandoArchivosCDC", async (data) => {
      //this.dataRecibida = data;
      console.log("GuardandoArchivosCDC-->", data);
      if (data.length > 0) {
        this.archivosCargados = data;
        await this.onUpload();
      }
    });
  },
  computed: {
    backgroundStyle() {
      if (this.imageUrl) {
        return {
          backgroundImage: `url("${this.imageUrl}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
        };
      }
      return {};
    },
    isFile() {
      return this.file ? true : false;
    },
    fileBackground() {
      if (this.file) {
        let backgroundImage = this.imageUrl ? `url("${this.imageUrl}")` : "";
        return {
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        };
      }
      return {};
    },
    extensionOfFileTypes() {
      return this.fileTypes.map((elment) => {
        return elment.substring(elment.lastIndexOf("/") + 1);
      });
    },
  },
  methods: {
    // handleFilesSisi() {
    //   // this.$emit("handleFilesSisi", data);
    // },
    // handleFilesSisi2() {
    //   // this.$emit("handleFilesSisi2", data);
    // },
    handleMouseOver() {
      // console.log("hover over");
      this.isHovered = true;
    },
    handleMouseLeave() {
      // console.log("hover leave");
      this.isHovered = false;
    },
    quitarTildesYEspacios(input) {
      let sinTildes = _.deburr(input);
      let sinEspacios = sinTildes.replace(/\s+/g, "");
      return sinEspacios;
    },
    restaurar() {
      this.file = null;
      this.imageUrl = null;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.validateAndProcessFile(selectedFile);
      }
    },
    handleDrop(event) {
      const selectedFile = event.dataTransfer.files[0];
      if (selectedFile) {
        this.validateAndProcessFile(selectedFile);
      }
    },
    validateAndProcessFile(selectedFile) {
      console.log(selectedFile.type);

      console.log(this.id);

      if (
        this.fileTypes.includes(selectedFile.type) &&
        selectedFile.name.split(".").length <= 2
      ) {
        eventBus.$emit("archivoCargado", this.id);
        // alert('archivo cargado' + this.id)
        this.file = selectedFile;
        this.typeFile = this.file.name.substring(
          this.file.name.lastIndexOf(".")
        );
        this.name = this.file.name;
        //Coloca de fondo la imagen siempre y cuando sea una imagen
        if (selectedFile.type.startsWith("image/")) {
          this.imageUrl = URL.createObjectURL(selectedFile);
          console.log("selectedFile", selectedFile);
        }
      } else {
        console.error("Tipo de archivo no válido.");

        if (!this.fileTypes.includes(selectedFile.type)) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Tipo de archivo no válido.",
            confirmButtonText: "Aceptar",
          });
        }

        if (selectedFile.name.split(".").length > 2) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "El nombre del archivo no puede contener puntos.",
            confirmButtonText: "Aceptar",
          });
        }

        this.file = null;
        this.imageUrl = null;
      }
      this.$refs.fileInput.value = "";
    },
    async onUpload() {
      try {
        //Consultar la base de datos la ultima imagen
        // let count = 0;
        console.log("ok");

        if (this.archivosCargados.find((x) => x == this.id) > 0) {
          console.log("ok");

          let nameFileT = this.injectData.NombreArchivo; // this.$moment.tz(new Date(), 'America/Bogota').format('DDMMYYYYHHmmss')+Math.floor(Math.random() * (1 - 99999 + 1)) + 1;
          // let dataGet;
          this.loader = true;
          this.$isLoading(true);

          // try {
          //   const responseGet = await this.$store.getters.fetchGet({
          //     path: this.pathGet,
          //   });
          //   dataGet = await responseGet.json();
          //   //count = dataGet.length.toString().padStart(4, "0");
          // } catch {
          //   //count = [].length.toString().padStart(4, "0");
          //   nameFileT = this.$moment.tz(new Date(), 'America/Bogota').format('DDMMYYYYHHmmss')+Math.floor(Math.random() * (1 - 99999 + 1)) + 1;

          // }

          const newPath = this.quitarTildesYEspacios(this.urlDir);
          // console.log("newPath", newPath);

          // let url = "users|pesv|evaluaciones|" + this.usuario.empresa.id + "|" +  + item.Id;
          const lastDirectory = newPath.substring(newPath.lastIndexOf("/") + 1);
          // const filename = `${lastDirectory}_${count}${this.typeFile}`;
          const filename = `${lastDirectory}_${nameFileT}${this.typeFile}`;
          const urlImg = newPath.split("/").join("|");

          const fileModel = {
            filename: filename,
            file: this.file,
            url: ".|" + urlImg,
          };

          console.log("fileMode", fileModel);
          // let isSave = false;
          let isSave = await this.$store.dispatch("onDropFile", fileModel);
          console.log("Save in smarter", isSave);

          //Agregar el archivo al backend, si uploadImg es true
          //La URL en la base de datos debe comenzar "https://www.helexiumfiles.com/users/pesv/evaluaciones/.../.../*.jpg"

          if (isSave == true) {
            // enviar file a sisi pesv
            // fase 1
            // this.handleFilesSisi({
            //   file: this.file,
            //   pasoId: this.injectData.PasoId,
            // });

            // fase 2
            // this.handleFilesSisi2({
            //   file: this.file,
            //   numeroEvidencia: this.injectData.NumeroEvidencia,
            //   form: this.injectData.Form,
            // });

            const url = "https://www.helexiumfiles.com/" + newPath + filename;

            const data = {
              ...this.injectData,
              NombreArchivo: filename,
              // descripcion: filename,
              // urlDir: newPath,
              UrlArchivo: url,
            };
            console.log("data", data);

            if (this.archivo.Id == "") {
              this.$store.getters
                .fetchPost({ path: this.pathPost, data: data })
                .then((response) => {
                  this.$isLoading(false);

                  // console.log("response", response);
                  if (response.ok) {
                    return response.json();
                  }
                  throw new Error("Error en el backend o DB");
                })
                .then((result) => {
                  this.$isLoading(false);

                  // Swal.fire({
                  //   icon: "success",
                  //   title: "Se ha guardado el documento",
                  //   text: `El documento "${this.name}" ha sido cargado`,
                  //   confirmButtonText: "Aceptar",
                  // }).then(() => {
                  //   this.restaurar();
                  // });
                  this.restaurar();

                  this.$emit("onUpload", result);
                })
                .catch((error) => {
                  this.$isLoading(false);

                  throw new Error(error);
                });
            } else {
              data["id"] = this.archivo.Id;

              this.$store.getters
                .fetchPut({ path: `${this.pathPutArchivos} `, data: data })
                .then((response) => {
                  this.$isLoading(false);

                  // console.log("response", response);
                  if (response.ok) {
                    return response.json();
                  }
                  throw new Error("Error en el backend o DB");
                })
                .then((result) => {
                  this.$isLoading(false);

                  // Swal.fire({
                  //   icon: "success",
                  //   title: "Se ha guardado el documento",
                  //   text: `El documento "${this.name}" ha sido cargado`,
                  //   confirmButtonText: "Aceptar",
                  // }).then(() => {
                  //   this.restaurar();
                  // });
                  this.restaurar();

                  this.$emit("onUpload", result);
                })
                .catch((error) => {
                  this.$isLoading(false);

                  throw new Error(error);
                });

              let dataGestion = {
                Estado: -1, // -1: Resubido, 0: Rechazado, 1: Aprobado
                FechaEstado: this.$moment
                  .tz(new Date(), "America/Bogota")
                  .format("YYYY-MM-DDTHH:mm:ss"),
              };
              this.$store.getters
                .fetchPut({ path: `${this.pathPutGestion}`, data: dataGestion })
                .then((response) => {
                  this.$isLoading(false);

                  // console.log("response", response);
                  if (response.ok) {
                    return response.json();
                  }
                  throw new Error("Error en el backend o DB");
                })
                .then((result) => {
                  this.$isLoading(false);

                  // Swal.fire({
                  //   icon: "success",
                  //   title: "Se ha guardado el documento",
                  //   text: `El documento "${this.name}" ha sido cargado`,
                  //   confirmButtonText: "Aceptar",
                  // }).then(() => {
                  //   this.restaurar();
                  // });
                  this.restaurar();

                  this.$emit("onUpload", result);
                })
                .catch((error) => {
                  this.$isLoading(false);

                  throw new Error(error);
                });
            }

            if (this.archivo.Id == "") {
              eventBus.$emit("archivosSubidos", {
                data: this.id,
                tipo: "subido",
              });
            } else {
              eventBus.$emit("archivosSubidos", {
                data: this.id,
                tipo: "resubido",
              });
            }
          } else {
            this.$isLoading(false);

            throw new Error(
              "Error al guardar el archivo en el servidor. Por favor, intentelo de nuevo"
            );
          }
        }
      } catch (error) {
        this.$isLoading(false);

        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: error.message,
          confirmButtonText: "Aceptar",
        });
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped>
form {
  width: 100%;
  min-height: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dropzone {
  width: 100%;
  min-height: 150px;
  height: 80%;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  transition: 0.3s all;

  &:hover {
    background-color: #ececec;
  }

  .dark &:hover {
    background-color: #131416;
  }
}

.is-file {
  background-color: #ececec;

  .dark & {
    background-color: #131416;
  }
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  /* background-color: rgba(27, 75, 141, 0.5); */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.actions {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
