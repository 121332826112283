<script>
import Swal from "sweetalert2";
import _ from "lodash";
import CardTable from "@/components/cardTable/CardTableV2.vue";

export default {
  components: {
    CardTable,
    ModalLiquidacionPersonaDetalles: () =>
      import("./ModalLiquidacionPersonaDetalles.vue"),
    NominaAjustePersona: () =>
      import("@/views/Helexium/Nomina/NominaAjustePersona/NominaAjustePersona"),
    NominaConsultaEstados: () =>
      import(
        "@/views/Helexium/Nomina/NominaAjustePersona/NominaConsultaEstados"
      ),
  },
  props: {
    liquidacionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nominaLiquidacion: undefined,
      listaLiquidacionPersona: [],
      onActiveModalDataLiquidacionPersona: null,
      enPreparacion: false,
      messageEstados: "",
      enviandoLiquidacion: false,
      dataCards: [
        {
          title: "Empleados",
          value: () => this.empleadosActivos,
          iconName: "users",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
        },
        {
          title: "Salarios",
          value: () => this.salarioEmpleadosActivos,
          iconName: "money-bill-1",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Pagos adicionales",
          value: () => this.pagosAdicionales,
          iconName: "hand-point-up",
          color: "rgba(71,136,255,1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Deducciones",
          value: () => this.deducciones,
          iconName: "hand-point-down",
          color: "rgba(223, 18, 46, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Total a pagar",
          value: () => this.totalAPagar,
          iconName: "hand-holding-dollar",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
        },
      ],
    };
  },
  async mounted() {
    await this.getData();
    this.initConsultasAutomáticasAportes();
  },
  computed: {
    empleadosSeleccionados() {
      return this.listaLiquidacionPersona.filter(
        (liquidacionPersona) =>
          liquidacionPersona.estado &&
          !liquidacionPersona.liquidado &&
          !liquidacionPersona.error
      );
    },
    empleadosLiquidados() {
      return this.listaLiquidacionPersona.filter(
        (liquidacionPersona) => liquidacionPersona.liquidado
      );
    },
    empleadosActivos() {
      return this.empleadosLiquidados.filter((x) => x.estado == 0).length;
    },
    salarioEmpleadosActivos() {
      let empleados = this.empleadosLiquidados.filter((x) => x.estado == 0);

      return empleados
        .reduce((prev, curr) => prev + Number(curr.salario), 0)
        .toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
    pagosAdicionales() {
      let empleados = this.empleadosLiquidados.filter((x) => x.estado == 0);
      return empleados
        .reduce((prev, curr) => prev + Number(curr.totalIngresosAdicionales), 0)
        .toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
    deducciones() {
      let empleados = this.empleadosLiquidados.filter((x) => x.estado == 0);
      return empleados
        .reduce((prev, curr) => prev + Number(curr.totalDeducciones), 0)
        .toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
    totalAPagar() {
      let empleados = this.empleadosLiquidados.filter((x) => x.estado == 0);
      return empleados
        .reduce((prev, curr) => prev + Number(curr.totalPago), 0)
        .toLocaleString("es-CO", { style: "currency", currency: "COP" });
    }
  },
  methods: {
    async getData() {
      try {
        const liquidacionAll = await this.$store.getters.fetchGet({
          path: `NominaLiquidacion/GetLiquidacionAll/${this.liquidacionId}`,
        });
        const { nominaLiquidacion, nominaLiquidacionPersonas } =
          await liquidacionAll.json();
        this.listaLiquidacionPersona = nominaLiquidacionPersonas;
        this.nominaLiquidacion = nominaLiquidacion;
        this.nominaLiquidacion.fechaInicio = this.$moment
          .tz(this.nominaLiquidacion.fechaInicio, "America/Costa_Rica")
          .format("YYYY-MM-DD");
        this.nominaLiquidacion.fechaFin = this.$moment
          .tz(this.nominaLiquidacion.fechaFin, "America/Costa_Rica")
          .format("YYYY-MM-DD");

        this.enPreparacion = !this.nominaLiquidacion.liquidado;

        this.$store.getters
          .fetchGet({
            path: `NominaLiquidacion/GetListaDeNovedades`,
          })
          .then((response) => response.json())
          .then(({ listaDeducciones, listaDevengados }) => {
            const shadowListaLiquidacionPersona = this.listaLiquidacionPersona;

            shadowListaLiquidacionPersona.forEach((liquidacionPersona) => {
              liquidacionPersona.nominaLiquidacionPersonaDetalle.forEach(
                (novedad) => {
                  if (novedad.tipoNovedadId == 1) {
                    novedad.concepto = listaDeducciones.find(
                      (deduccion) => deduccion.id == novedad.conceptoId
                    ).descripcion;
                  }
                  if (novedad.tipoNovedadId == 2) {
                    novedad.concepto = listaDevengados.find(
                      (devengado) => devengado.id == novedad.conceptoId
                    ).descripcion;
                  }
                }
              );
            });
            this.listaLiquidacionPersona = window.structuredClone(
              shadowListaLiquidacionPersona
            );

            this.transformTable();
          })
          .catch((error) => {
            throw error;
          });
      } catch (error) {
        console.error(error);
      }
    },
    mostrarDestallesLiquidacionPersona(liquidacionPersona) {
      this.onActiveModalDataLiquidacionPersona = liquidacionPersona;
      this.$bvModal.show("modalDetallesLiquidacionPersona");
    },
    mostrarPrevalidacionesDeLaPersona(liquidacionPersona) {
      this.onActiveModalDataLiquidacionPersona = liquidacionPersona;
      this.$bvModal.show("modalPrevalidacionesPersona");
    },
    mostrarNominaDeAjuste(liquidacionPersona) {
      this.onActiveModalDataLiquidacionPersona = liquidacionPersona;
      this.$bvModal.show("modalNominaDeAjuste");
    },
    mostrarModalConsultaEstado(liquidacionPersona) {
      this.onActiveModalDataLiquidacionPersona = liquidacionPersona;
      this.$bvModal.show("modalConsultaDeEstado");
    },
    transformTable() {
      window.$("#datatable_empleados").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      window.$("#dataTableEmpeladosLiquidados").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
    },
    async liquidarNomina() {
      // Actualizacion en el backend
      if (this.empleadosSeleccionados.length == 0) return;

      this.$isLoading(true);
      const queryString = this.empleadosSeleccionados
        .map(
          (liquidacionPersona) =>
            `liquidacionPersonaId=${liquidacionPersona.id}`
        )
        .join("&");
        
      this.$store.getters
        .fetchPost({
          path: `NominaLiquidacion/LiquidarPersonasDePreparacion/${this.liquidacionId}?${queryString}`,
        })
        .then((response) => response.json())
        .then(() => {
          this.$router.push({
            name: "NominaTransmitir",
            params: { liquidacionId: this.liquidacionId },
          });
        })
        .finally(() => {
          this.$isLoading(false);
        });

      //Actualización en el frontend
      this.nominaLiquidacion.liquidado = true;
      this.empleadosSeleccionados.forEach((nominaPersona) => {
        const index = this.listaLiquidacionPersona.findIndex(
          (liq) => liq.id == nominaPersona.id
        );
        this.listaLiquidacionPersona[index].liquidado = true;
      });
      // this.empleadosSeleccionados = window.structuredClone(this.empleadosSeleccionados);

      Swal.fire("Listo!", "Liquidación procesada correctamente!.", "success");

      this.enPreparacion = false;
    },
    mapError(erroresString) {
      return erroresString
        .replace("Errores: ", "")
        .split(", ")
        .map((error) => error.trim());
    },
    initConsultasAutomáticasAportes() {
      const idsLiquidacionPersonasPendientes = this.empleadosLiquidados
        .filter((liq) => liq.estadoAportes === "Pendiente")
        .map((liq) => liq.id);

      this.messageEstados = "Consultando estados en la base de datos";
      if (idsLiquidacionPersonasPendientes.length == 0) {
        this.messageEstados = "";
        this.validarEstadosPorBackEnd();
        return;
      }

      const queryString = idsLiquidacionPersonasPendientes
        .map((id) => `liquidacionPersonaId=${id}`)
        .join("&");
      this.$store.getters
        .fetchGet({
          path: `LiquidadorNomina/GetCuerposConsultaByLiquidacionesPersonasIds?${queryString}`,
        })
        .then((response) => response.json())
        .then((data) => {
          return new Promise((resolve) => {
            resolve(data.map(({ body }) => body));
          });
        })
        .then((bodies) => {
          const groupedData = _.groupBy(bodies, "idTrack");
          const resultado = Object.keys(groupedData).map((idTrack) => ({
            idTrack,
            trabajador: _.flatMap(groupedData[idTrack], "trabajador"),
          }));

          return new Promise((resolve) => {
            resolve(resultado);
          });
        })
        .then(async (bodies) => {
          bodies.forEach(async (body, index) => {
            this.messageEstados = `${
              index + 1
            } Se ha iniciado el proceso de consulta de estados de liquidaciones en Aportes en Línea`;

            const result = await this.consultarPersonaEnAportes(
              JSON.stringify(body)
            );

            this.messageEstados = result.descripcion;
            if (
              this.messageEstados.includes("previamente") ||
              this.messageEstados.includes("validación")
            ) {
              this.messageEstados +=
                "\nEste proceso puede tardar algunas horas. Se le enviara un correo cuando el proceso haya finalizado.";
            }

            const responseSaveData = await this.$store.getters.fetchPut({
              path: `LiquidadorNomina/SaveResultLiquidacionPersonas/${body.idTrack}`,
              data: result,
            });
            const resultSaveData = await responseSaveData.json();

            resultSaveData.forEach((item) => {
              const index = this.listaLiquidacionPersona.findIndex(
                (liq) => liq.id == item.id
              );
              this.listaLiquidacionPersona[index].estadoAportes =
                item.estadoAportes;
            });
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // setTimeout(() => {
          //   this.messageEstados = ''
          // }, 10000)
        });
    },
    async consultarPersonaEnAportes(body) {
      const token = await this.$store.getters.getTokenAportes();
      const response = await fetch(
        `${this.$store.state.APIAportes}/NominaElectronica/ConsultaEstado`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            token: token,
            "Content-Type": "application/json",
          },
          body: body,
        }
      );
      return await response.json();
    },
    validarEstadosPorBackEnd() {
      const enviadas = this.empleadosLiquidados.filter(
        (liq) => liq.estadoAportes
      );

      if (enviadas.length == 0) {
        this.messageEstados =
          "No se han enviado liquidaciones a Aportes en Línea";
        return;
      }

      const aprobados = enviadas.filter(
        (liq) => liq.estadoAportes === "Aprobado"
      );
      if (aprobados.length == this.empleadosLiquidados.length) {
        this.messageEstados =
          "Todos los documentos de las liquidaciones han sido aprobados";
        return;
      }
    },
  //   async enviarAportesEnLinea() {
  //     this.$isLoading(true);
  //     const responseCuerpoLiquidacion = await this.$store.getters.fetchGet({
  //       path: `LiquidadorNomina/GetDataLiquidacion/${this.liquidacionId}`,
  //     });
  //     const { liquidacion } = await responseCuerpoLiquidacion.json();
  //     liquidacion.informacionGeneral.tipoXML = "102";

  //     const token = await this.$store.getters.getTokenAportes();
  //     const responseLiquidacion = await fetch(
  //       `${this.$store.state.APIAportes}/NominaElectronica/ProcesarNomina`,
  //       {
  //         method: "POST",
  //         headers: {
  //           accept: "application/json",
  //           token: token,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(liquidacion),
  //       }
  //     );
  //     const resultLiquidacion = await responseLiquidacion.json();

  //     try {
  //       if (resultLiquidacion.successful && resultLiquidacion.idTrack) {
  //         //Actualización en el backend
  //         const response = await this.$store.getters.fetchPost({
  //           path: `LiquidadorNomina/liquidacion/${this.liquidacionId}/GuardarIdDeSeguimiento/${resultLiquidacion.idTrack}`,
  //         });

  //         //Actualización local
  //         const { liquidacionesPersonas } = await response.json();
  //         liquidacionesPersonas.forEach((liquidacionPersona) => {
  //           const index = this.listaLiquidacionPersona.findIndex(
  //             (liq) => liq.id == liquidacionPersona.id
  //           );
  //           this.listaLiquidacionPersona[index].estadoAportes === "Pendiente";
  //         });

  //         this.initConsultasAutomáticasAportes();

  //         if (response.ok) {
  //           Swal.fire({
  //             title: "Procesando nómina",
  //             text: resultLiquidacion.descripcion.join("\n"),
  //             icon: "success",
  //             confirmButtonText: "Ok",
  //           });
  //         } else {
  //           throw new Error("Error al guardar el id de seguimiento");
  //         }
  //       } else {
  //         throw new Error(resultLiquidacion.descripcion.join("\n"));
  //       }
  //     } catch (error) {
  //       Swal.fire({
  //         title: "Error",
  //         text: error.message,
  //         icon: "error",
  //         confirmButtonText: "Ok",
  //       });
  //     }
  //     this.$isLoading(false);
  //   },
  },
  filters: {
    nombreCompleto(obj) {
      const persona = obj.persona;
      return (
        persona.nombres +
        " " +
        (persona.segundoNombre != null ? persona.segundoNombre : "") +
        " " +
        persona.apellidos +
        " " +
        (persona.segundoApellido != null ? persona.segundoApellido : "")
      );
    },
    formatoMoneda(valor) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(valor);
    },
  },
};
</script>

<template>
  <div>
    <!-- Este bloque se puede mejorar -->
    <card>
      <h4 class="px-4 py-4"><b>información de la nómina</b></h4>
      <template v-slot:headerTitle></template>
      <div class="row px-5 mb-4">
        <div class="col-md-6">
          <label for=" " class="form-label"> Período de Liquidación </label>
          <div>
            <!-- :readonly="item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2" -->
            <label for=" " class="form-label">Fecha inicio</label>
            <input
              readonly
              required
              :value="nominaLiquidacion?.fechaInicio"
              placeholder=""
              type="date"
              class="form-control"
              id=" "
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="mt-4">
            <label for=" " class="form-label">Fecha fin</label>
            <!-- :readonly="nominaLiquidacion.tipoLiquidacion == 1 || nominaLiquidacion.tipoLiquidacion == 2" -->
            <input
              readonly
              :value="nominaLiquidacion?.fechaFin"
              required
              placeholder=""
              type="date"
              class="form-control"
              id=" "
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="mt-4">
            <label for=" " class="form-label">Tipo de Liquidación</label>
            <input
              disabled
              :value="
                nominaLiquidacion?.tipoLiquidacion == 1
                  ? 'Primer semestre'
                  : nominaLiquidacion?.tipoLiquidacion == 2
                  ? 'Último semestre / Navideña'
                  : 'Nómina'
              "
              required
              placeholder=""
              type="text"
              class="form-control"
              id=" "
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="mt-4">
            <label for=" " class="form-label">Estado</label>
            <input
              disabled
              :value="nominaLiquidacion?.estado == 1 ? 'Liquidado' : ''"
              required
              placeholder=""
              type="text"
              class="form-control"
              id=" "
            />
          </div>
        </div>
      </div>
    </card>

    <div class="px-5">
      <div class="row">
        <div class="col-12">
          <CardTable :data="dataCards" />
        </div>
        <!-- <div class="col-12 col-md">
                  <BtnAddCardTable text="Abrir Ticket" url="AgregarTickets" />
                </div> -->
      </div>
    </div>

    <b-card>
      <div>
        <div class="border border-1 border-white p-2 rounded rounded-2">
          <div class="mb-4">
            <!-- <vs-button @click.prevent="recalculaLiquida()" gradient>Recalcular liquidación</vs-button> -->
            <vs-button
              @click="liquidarNomina"
              gradient
              danger
              :disabled="!empleadosSeleccionados.length"
              >Preparar nomina</vs-button
            >
          </div>
          <table
            id="datatable_empleados"
            class="table table-bordered table-responsive-md table-striped text-center"
          >
            <thead>
              <tr>
                <th>Liquidar</th>
                <th>Nombre</th>
                <th>Identificación</th>
                <th>Salario</th>
                <th>Pagos adicionales</th>
                <th>Deducciones</th>
                <th>Total a pagar</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="liquidacionPersona in listaLiquidacionPersona"
                :key="liquidacionPersona.dataId"
              >
                <td>
                  <span
                    @click="
                      mostrarPrevalidacionesDeLaPersona(liquidacionPersona)
                    "
                    v-if="liquidacionPersona.error"
                    class="pointer"
                  >
                    <i
                      class="fas fa-solid fa-exclamation-triangle text-danger mr-2"
                    ></i>
                  </span>
                  <HelexCheckBtn
                    v-else-if="!liquidacionPersona.liquidado"
                    v-model="liquidacionPersona.estado"
                  >
                  </HelexCheckBtn>
                  <span v-else>Liquidado</span>
                </td>
                <td>{{ liquidacionPersona | nombreCompleto }}</td>
                <td>{{ liquidacionPersona.persona.numeroDocumento }}</td>
                <td>
                  {{ liquidacionPersona.persona.salario | formatoMoneda }}
                </td>
                <td>
                  {{
                    liquidacionPersona.totalIngresosAdicionales | formatoMoneda
                  }}
                </td>
                <td>
                  {{ liquidacionPersona.totalDeducciones | formatoMoneda }}
                </td>
                <td>{{ liquidacionPersona.totalPago | formatoMoneda }}</td>
                <td>
                  <div
                    class="text-center"
                    style="gap: 0.5rem"
                    v-if="
                      liquidacionPersona.liquidado ||
                      (liquidacionPersona.estado && !liquidacionPersona.error)
                    "
                  >
                    <span
                      @click="
                        mostrarDestallesLiquidacionPersona(liquidacionPersona)
                      "
                      class="pointer"
                    >
                      <i class="fas fa-solid fa-eye text-success mx-1"></i>
                    </span>
                    <router-link
                      :to="{
                        path: `/helex/liquidacioneditardetalle/${liquidacionId}/${liquidacionPersona.persona.id}`,
                      }"
                      class="pointer"
                    >
                      <span>
                        <i class="fas fa-solid fa-file text-success mx-1"></i>
                      </span>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>

    <b-modal
      title="Prevalidaciones"
      id="modalPrevalidacionesPersona"
      size="xl"
      hide-footer
    >
      <template #default="{ close }">
        <div v-if="onActiveModalDataLiquidacionPersona" class="mb-3">
          <div>
            <h3>{{ onActiveModalDataLiquidacionPersona | nombreCompleto }}</h3>
            <p>
              {{ onActiveModalDataLiquidacionPersona.persona.numeroDocumento }}
            </p>
          </div>
          <div
            class="border-1 border border-white p-3"
            v-if="onActiveModalDataLiquidacionPersona.error"
          >
            <p>Datos faltantes de la persona</p>
            <ul class="p-0">
              <li
                v-for="(error, index) in mapError(
                  onActiveModalDataLiquidacionPersona.error
                )"
                :key="index"
              >
                {{ error }}
              </li>
            </ul>
          </div>
          <div v-else>
            <p>Todo en orden</p>
          </div>
        </div>
        <div class="w-100 d-flex flex-row-reverse">
          <vs-button gradient success @click="close"> Cerrar </vs-button>
          <router-link
            target="_blank"
            :to="{
              path: `/helex/Persona_editar/${onActiveModalDataLiquidacionPersona.persona.id}`,
            }"
          >
            <vs-button gradient>Ir al perfil</vs-button>
          </router-link>
        </div>
      </template>
    </b-modal>

    <b-modal
      title="Liquidación"
      id="modalDetallesLiquidacionPersona"
      size="xl"
      hide-footer
    >
      <template #default="{ close }">
        <ModalLiquidacionPersonaDetalles
          v-if="onActiveModalDataLiquidacionPersona"
          :nominaLiquidacionPersonaDetalle="
            onActiveModalDataLiquidacionPersona.nominaLiquidacionPersonaDetalle
          "
        >
        </ModalLiquidacionPersonaDetalles>
        <div class="w-100 text-right">
          <button class="mx-1 btn px-4 py-2 btn-success" @click="close">
            Cerrar
          </button>
        </div>
      </template>
    </b-modal>

    <b-modal
      title="Nomina de ajuste"
      id="modalNominaDeAjuste"
      size="xl"
      hide-footer
    >
      <NominaAjustePersona
        v-if="onActiveModalDataLiquidacionPersona"
        :liquidacionId="liquidacionId"
        :liquidacionPersonaId="onActiveModalDataLiquidacionPersona.id"
      ></NominaAjustePersona>
    </b-modal>

    <b-modal
      title="Consultar estado"
      id="modalConsultaDeEstado"
      size="md"
      hide-footer
    >
      <NominaConsultaEstados
        v-if="onActiveModalDataLiquidacionPersona"
        :liquidacionPersonaId="onActiveModalDataLiquidacionPersona.id"
      />
    </b-modal>
  </div>
</template>

<style scoped>
.check-button {
  border: 1px solid #cacaca;
}
</style>
