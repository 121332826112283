<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between align-items-center px-0  pt-0">
              <h3 class="card-title font-weight-bold" style='font-size: 30px'>Facturas</h3>
              <div class='d-flex' style='gap: .4rem;'>
                <vs-button @click="$router.push('NuevaFactura')">Nuevo Factura</vs-button>
              </div>
            </div>
          </template>
          <div class="px-5 py-3">
            <CardTable :data="dataCards"/>
          </div>
          <div class='p-5'>
            <DataTableLotes :fetch-data="fetchData" :columns="columns">
              <template v-slot:TotalVenta="{ row }">
                {{ formatearNumero(row.TotalVenta) }}
              </template>
              <template v-slot:ID="{ row }">
                <div class="d-flex justify-content-center" style="gap: .5rem;">
                  <vs-button v-b-tooltip.hover title="Ver factura" success icon
                             @click="$router.push(`InfoFactura/${row.ID}`)">
                    <i class="fa-solid fa-eye"></i>
                  </vs-button>
                  <vs-button v-b-tooltip.hover title="Editar" icon @click="$router.push(`EditarFactura/${row.ID}`)">
                    <i class="fa-solid fa-pen"></i>
                  </vs-button>
                  <vs-button v-b-tooltip.hover title="Eliminar" danger icon>
                    <i class="fa-solid fa-trash"></i>
                  </vs-button>
                </div>
              </template>
            </DataTableLotes>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CardTable from "../../../../../../components/cardTable/CardTableV2.vue";
import DataTableLotes from "@/components/Datatable/DataTableLotes.vue";

export default {
  components: {
    DataTableLotes,
    CardTable,
  },
  data() {
    return {
      dataCards: [
        {
          title: "Pagado",
          value: 0,
          iconName: "money-bill-1",
          color: "rgba(21, 128, 61, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Pendiente",
          value: 0,
          iconName: "money-bill-1",
          color: "rgba(71,136,255,1)",
          tipo: "shield",
          animated: "bounce",
        }
      ],
      EmpresaId: "",
      Divisa: null,
      columns: [
        {label: "Fecha Facturación", key: "FechaVenta", sortable: true},
        {label: "Fecha Vencimiento", key: "FechaVencimiento", sortable: true},
        {label: "Estado", key: "Estado", sortable: false},
        {label: "Cliente", key: "RazonSocial", sortable: true},
        {label: "Valor", key: "TotalVenta", sortable: true},
        {label: "Opciones", key: "ID", sortable: false},
      ],
    }
  },
  created() {
    const Persona = JSON.parse(localStorage.getItem('setPersonaAct'));
    this.EmpresaId = Persona.empresa.id;
    this.getData();
  },
  methods: {
    async fetchData({filtro, orden, cantDatos, paginacion}) {
      if (!this.EmpresaId) {
        console.error("EmpresaId no está definido.");
        return {TotalDatos: 0, data: []};
      }
      let params = {
        filtro: filtro,
        orden: orden,
        cantDatos: cantDatos,
        paginacion: paginacion
      };
      let data = [];
      let total = 0;
      await Promise.all([
        this.$store.dispatch("hl_get", {
          path: `ContVentas/ObtenerCantidadVentasEmpresa/${this.EmpresaId}?filtro=${filtro}`,
        }),
        this.$store.dispatch("hl_post", {
          path: `ContVentas/ObtenerVentasEmpresa/${this.EmpresaId}`,
          data: params,
        })
      ]).then(([cantidadDatos, Datos]) => {
        if (cantidadDatos.respuesta) {
          total = cantidadDatos.data;
        }
        if (Datos.respuesta) {
          data = Datos.data;
          data.forEach(Element => {
            Element.FechaVenta = this.$moment.tz(Element.FechaVenta, "America/Bogota").format("MM-DD-YYYY");
            if (Element.FechaVencimiento) {
              Element.FechaVencimiento =
                  this.$moment.tz(Element.FechaVencimiento, "America/Bogota").format("MM-DD-YYYY")
            }

          })
        }
      });
      this.CalcularCards(filtro);
      return {total, data};
    },
    formatearNumero(numero) {
      try {
        if (!this.Divisa || !this.Divisa.Simbolo || !this.Divisa.Precision) {
          throw new Error("Configuración de divisa incompleta.");
        }
        const opciones = {
          minimumFractionDigits: parseInt(this.Divisa.Precision, 10),
          maximumFractionDigits: parseInt(this.Divisa.Precision, 10),
        };
        const formato = new Intl.NumberFormat('es-CO', opciones);
        const numeroFormateado = formato.format(numero);
        return `${this.Divisa.Simbolo} ${numeroFormateado}`;
      } catch (error) {
        console.error("Error al formatear el número:", error.message);
        return numero;
      }
    },
    async getData() {
      await this.$store.dispatch('hl_get',
          {
            path: `GDivisas/ObtenerDivisaEmpresaVentaId/${this.EmpresaId}`
          }).then((response) => {
        if (response.respuesta) {
          this.Divisa = response.data;
        }
      });
    },
    async CalcularCards(filtro) {
      let pagado = 0;
      let pendiente = 0;

      await this.$store.dispatch("hl_get", {
        path: `ContVentas/ObtenerTotalesEstados/${this.EmpresaId}?filtro=${filtro}`,
      }).then((response) => {
        if (response.respuesta) {
          pagado = response.data.Pagado ?? 0;
          pendiente = response.data.Pendiente ?? 0;
        }
      });

      this.dataCards[0].value = this.formatearNumero(pagado);
      this.dataCards[1].value = this.formatearNumero(pendiente);
    }
  }
}
</script>

<style>
.vs-input {
  width: 100%;
}

.header-title {
  width: 100% !important;
}
</style>