<template>
  <div>
    <multiselect v-model="localSelected" :options="options" :multiple="true" :track-by="valorGuardar"
                 placeholder="Selecciona opciones" :label="valorVer" :searchable="true" @input="updateParent"
                 :close-on-select="false" :clear-on-select="false" :preselect-first="selectFirst"
                 :allowEmpty="allowEmptyList"
                 selectLabel="Presiona enter para añadir" selectedLabel="añadido"
                 deselectLabel="Presiona enter para eliminar">

      <!-- TEMPLATE PARA LISTAS VACIAS -->
      <template #noOptions>
        {{ emptyList }}
      </template>

      <template #noResult>
        {{ badSearch }}
      </template>

    </multiselect>
    <span v-if="!localSelected.length && dataRequired" class="text-danger">Este campo es requerido.</span>
    <!-- //<p>Seleccionado: {{ localSelectedNames }}</p>
    <p>Condicional: {{ allData }}</p> -->
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {Multiselect},
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    valorGuardar: {
      type: String,
      default: 'id'  // La propiedad única para identificar elementos
    },
    valorVer: {
      type: String,
      default: 'name'
    },
    allData: {
      type: Boolean,
      default: true,
    },

    selectFirst: {
      type: Boolean,
      default: true,
    },
    emptyList: {
      type: String,
      default: "¡Lo sentimos! No hemos encontrado valores.",
    },
    badSearch: {
      type: String,
      default: "¡Ops! Revisa tu búsqueda.",
    },
    allowEmptyList: {
      type: Boolean,
      default: true,
    },
    dataRequired: {
      type: Boolean,
      default: true
    },
    dataPreload:{
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localSelected: this.selected,
    };
  },
  computed: {
    // Computa una lista con solo los nombres de los elementos seleccionados
    localSelectedNames() {
      if (!this.allData) {
        // Si allData es false, devuelve una lista vacía
        return this.localSelected.map(item => item[this.valorGuardar]);
      } else {
        return this.localSelected;
      }
    }
  },
  watch: {
    selected(newVal) {
      this.localSelected = newVal;
    },
    // Sincroniza localSelected cuando las opciones cambian
    options() {
      this.localSelected = [];
      this.updateParent();
    },
    dataPreload(newVal) {
      if (newVal && newVal.length) {
        this.localSelected = [...newVal];
      }
    },
  },
  // AJUSTAR DE ACUERDO A LA INFORMACIÓN QUE SE REQUIERA EMITIR
  methods: {
    updateParent() {
      this.$emit('input', this.localSelectedNames);
    },
  }

};
</script>
<style>
.text-danger {
  color: red;
}
</style>
<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
