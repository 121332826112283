<template>
  <HXContentCard title="Base Metas Mensuales">
    <div class="row p-4">
      <div class="col-12 col-lg-3">
        <b-button v-for="{ name, text } in optionsActions" class="w-100 my-1"
          :variant="name == selectedAction ? 'primary' : 'dark'" :key="name + 'action'" @click="handleAction(name)">
          {{ text }}
        </b-button>
      </div>
      <div class="col-12 col-lg border rounded body-content-baseMetas">
        <form v-if="selectedAction == 'config'" class="row p-2" @submit.prevent="saveConfiguracion">
          <div class="col-12 col-lg-6 mb-3">
            <label for="date" class="form-label">Mes y Año</label>
            <input id="date" type="month" class="form-control" v-model="forms[selectedAction].date"
              @input="handleDateInput" required />
          </div>

          <div class="col-12">
            <div class="row">
              <div v-for="field in forms[selectedAction].fields" :key="field.name" class="col-12 col-lg-6 mb-3">
                <label :for="field.name" class="form-label">{{
                  field.label
                }}</label>
                <input :type="field.type" v-model="field.value" class="form-control" required />
              </div>
            </div>
          </div>

          <vs-button success class="ml-auto" type="submit">
            Guardar Cambios
          </vs-button>
        </form>

        <form v-else class="row p-2" @submit.prevent="saveBaseMetas">
          <div class="col-12 col-lg-6 mb-3">
            <label for="date" class="form-label">Mes y Año</label>
            <input id="date" type="month" class="form-control" v-model="forms[selectedAction].date" required />
          </div>

          <div v-for="(zona, zonaIndex) in forms[selectedAction].zonas" :key="zona.name" class="col-12">
            <b class="w-100 text-center d-block py-2">
              {{ zona.name }}
              <i @click="removeZona(zonaIndex)" class="fas fa-circle-minus text-danger pointer mx-2"></i>
            </b>
            <hr>

            <div class="row align-items-center my-2" v-for="(ubicacion, ubicacionIndex) in zona.ubicaciones"
              :key="ubicacion.name">
              <i @click="removeUbicacion(zonaIndex, ubicacionIndex)"
                class="fas fa-circle-minus text-danger pointer "></i>
              <div class="col-12 col-lg">
                <label for="" class="form-label">Ubicacion</label>
                <b-form-select v-model="ubicacion.name" required
                  :options="getAvailableUbicaciones(zonaIndex, ubicacionIndex)">
                  <template #first>
                    <b-form-select-option :value="''" disabled>
                      -- Seleccionar ubicacion --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-12 col-lg">
                <label for="" class="form-label">Meta Mensual</label>
                <input type="number" class="form-control" v-model="ubicacion.metaMensual" required />
              </div>
            </div>
            <div class="col-12 mt-2">
              <b-button @click="addUbicacion(zonaIndex)" variant="primary" size="sm" :disabled="isAvaliableNewZona">
                <i class="fas fa-circle-plus mr-1"></i>
                Agregar Ubicación
              </b-button>
            </div>
          </div>



          <div class="col-12 mt-3">
            <b-button @click="addZona" variant="warning" size="sm" :disabled="isAvaliableNewZona">
              <i class="fas fa-location-dot mr-1"></i>
              Agregar Zona</b-button>
          </div>

          <vs-button success class="ml-auto" type="submit">
            Guardar Cambios
          </vs-button>
        </form>
      </div>
    </div>
  </HXContentCard>
</template>

<script>
import Holidays from "date-holidays";
// eslint-disable-next-line no-unused-vars
import { eachDayOfInterval, isWeekend, format } from "date-fns";
const Swal = require("sweetalert2");

export default {
  data() {
    return {
      ultimaConfiguracion: null,
      isAvaliableNewZona: true,
      ultimaBaseMetas: [],
      // Lista de ubicaciones disponibles
      ubicacionesDisponibles: [
        "CALI AV 6TA",
        "CALI CIUDAD JARDIN",
        "CALI CALIMA",
        "MALL PLAZA CALI",
        "MALL PLAZA BARRANQUILLA",
        "ITAGUI",
        "LA PLAYA",
        "POBLADO",
        "VILLAS",
        "COLSUBSIDIO",
        "PORTAL 80",
        "CALLE 80",
        "MULTIPLAZA",
        "SALITRE",
        "SUBA PLAZA IMPERIAL",
        "TUNAL",
        "CEDRITOS",
        "VILLAVICENCIO",
        "ALAMOS",
        "KENNEDY",
        "CENTRO",
        "MALL PLAZA BOGOTA",
        "PASEO VILLA DEL RIO",
        "ROMA",
        "CENTRO MAYOR",
        "CHAPINERO",
        "GRAN ESTACION",
        "PLAZA DE LAS AMERICAS",
        "UNICENTRO",
        "SOACHA",
      ],
      optionsActions: [
        {
          name: "config",
          text: "Configuracion Mensual",
        },
        {
          name: "baseMetas",
          text: "Base Metas",
        },
        {
          name: "proyeccionMetas",
          text: "Proyección  Metas",
        },
      ],
      selectedAction: "config",
      forms: {
        config: {
          date: '',
          fields: [
            {
              type: "number",
              name: "diasHabiles",
              label: "Dias Habiles",
              value: 0,
            },
            {
              type: "number",
              name: "porcentajePrimerAbono",
              label: "% Primer Abono",
              value: 87,
            },
            {
              type: "number",
              name: "porcentajeSegundoAbono",
              label: "% Segundo Abono",
              value: 13,
            },
            {
              type: "number",
              name: "metaGeneralV1",
              label: "Eficacia",
              value: 1380,
            },
          ],
        },
        baseMetas: {
          date: '',
          zonas: [
            {
              name: "ZONA 1",
              ubicaciones: [
                { name: "CALI AV 6TA", metaMensual: 151900 },
                { name: "CALI CIUDAD JARDIN", metaMensual: 170900 },
                { name: "CALI CALIMA", metaMensual: 223900 },
                { name: "MALL PLAZA CALI", metaMensual: 218900 },
                { name: "MALL PLAZA BARRANQUILLA", metaMensual: 204900 },
              ],
            },
            {
              name: "ZONA 2",
              ubicaciones: [
                { name: "ITAGUI", metaMensual: 192900 },
                { name: "LA PLAYA", metaMensual: 280900 },
                { name: "POBLADO", metaMensual: 124900 },
                { name: "VILLAS", metaMensual: 152000 },
                { name: "COLSUBSIDIO", metaMensual: 190900 },
                { name: "PORTAL 80", metaMensual: 189900 },
              ],
            },
            {
              name: "ZONA 3",
              ubicaciones: [
                { name: "CALLE 80", metaMensual: 201900 },
                { name: "MULTIPLAZA", metaMensual: 277900 },
                { name: "SALITRE", metaMensual: 151900 },
                { name: "SUBA PLAZA IMPERIAL", metaMensual: 399900 },
                { name: "TUNAL", metaMensual: 150900 },
                { name: "CEDRITOS", metaMensual: 278900 },
                { name: "VILLAVICENCIO", metaMensual: 239900 },
              ],
            },
            {
              name: "ZONA 4",
              ubicaciones: [
                { name: "ALAMOS", metaMensual: 175000 },
                { name: "KENNEDY", metaMensual: 442900 },
                { name: "CENTRO", metaMensual: 126000 },
                { name: "MALL PLAZA BOGOTA", metaMensual: 151900 },
                { name: "PASEO VILLA DEL RIO", metaMensual: 153900 },
                { name: "ROMA", metaMensual: 186900 },
              ],
            },
            {
              name: "ZONA 5",
              ubicaciones: [
                { name: "CENTRO MAYOR", metaMensual: 308900 },
                { name: "CHAPINERO", metaMensual: 191900 },
                { name: "GRAN ESTACION", metaMensual: 195500 },
                { name: "PLAZA DE LAS AMERICAS", metaMensual: 238900 },
                { name: "UNICENTRO", metaMensual: 150900 },
                { name: "SOACHA", metaMensual: 245900 },
              ],
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.getUltimaConfiguracion();
    this.getUltimaBaseMetas();
  },
  methods: {
    showToast(type, message) {
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    addUbicacion(zonaIndex) {
      this.forms.baseMetas.zonas[zonaIndex].ubicaciones.push({
        name: this.ubicacionesDisponibles[0], // Asignar la primera ubicación por defecto
        metaMensual: 0,
      });
    },
    removeUbicacion(zonaIndex, ubicacionIndex) {
      this.forms.baseMetas.zonas[zonaIndex].ubicaciones.splice(
        ubicacionIndex,
        1
      );
    },
    addZona() {
      this.forms.baseMetas.zonas.push({
        name: `ZONA ${this.forms.baseMetas.zonas.length + 1}`,
        ubicaciones: [
          { name: this.ubicacionesDisponibles[0], metaMensual: 0 }, // Asignar la primera ubicación disponible
        ],
      });
    },
    removeZona(zonaIndex) {
      Swal.fire({
        title: '¿Estás seguro que deseas eliminar la Zona?',
        text: "¡No podrás revertir esta acción!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí, eliminar!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.forms.baseMetas.zonas.splice(zonaIndex, 1);
        }
      });
    },
    handleAction(name) {
      this.selectedAction = name;
      if (name === "proyeccionMetas") {
        // Redirigir a la ruta correspondiente
        this.$router.push({ name: "ProyeccionMetas" });
      } else {
        console.log(`Acción seleccionada: ${name}`);
      }
    },
    getAvailableUbicaciones(zonaIndex, ubicacionIndex) {
      // Obtener todas las ubicaciones ocupadas en otras zonas y ubicaciones dentro de la misma zona
      const ocupadas = this.forms.baseMetas.zonas
        .map((zona, index) => {
          if (index !== zonaIndex) { // Excluir la zona actual
            return zona.ubicaciones.map(ubicacion => ubicacion.name);
          } else {
            return zona.ubicaciones
              .filter((_, idx) => idx !== ubicacionIndex) // Excluir la ubicación actual
              .map(ubicacion => ubicacion.name);
          }
        })
        .flat(); // Aplanar el array de ubicaciones ocupadas
      console.log("ocupadas", ocupadas.length);

      // Filtrar las ubicaciones disponibles que no estén ocupadas
      let ubicacionesDisponibles = this.ubicacionesDisponibles.filter(ubicacion => !ocupadas.includes(ubicacion));

      console.log("disponibles", ubicacionesDisponibles.length);


      this.isAvaliableNewZona = ocupadas.length + 1 == this.ubicacionesDisponibles.length;

      return ubicacionesDisponibles;
    },
    async getUltimaConfiguracion() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("denti_get", {
          path: "BaseMetasMensuales/UltimaConfiguracion",
        });
        console.log("ultima configuracion", res);

        if (res.id) {
          this.ultimaConfiguracion = res;
          this.forms.config.date = String(res.anio) + "-" + String(res.mes).padStart(2, "0");
          console.log(this.forms.config.date);

          this.forms.config.fields.forEach((field) => {
            field.value = res[field.name];
            console.log(res[field.name]);
          });
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
      }
    },
    async getUltimaBaseMetas() {
      this.$isLoading(true);
      try {
        let res = await this.$store.dispatch("denti_get", {
          path: "BaseMetasMensuales/UltimaBaseMetas",
        });
        console.log("ultima basemetas", res);
        if (res && res.length) {
          this.ultimaBaseMetas = res;
          // Mapear los datos en el formato de zonas y ubicaciones
          const zonas = res.reduce((acc, item) => {
            let zona = acc.find((z) => z.name === item.zona);
            if (!zona) {
              zona = { name: item.zona, ubicaciones: [] };
              acc.push(zona);
            }
            zona.ubicaciones.push({
              name: item.ubicacion,
              metaMensual: item.metaMensual,
            });
            return acc;
          }, []);

          console.log("zonas", zonas);

          // Asignar al formulario
          this.forms.baseMetas.date = String(res[0].anio) + "-" + String(res[0].mes).padStart(2, "0");
          this.forms.baseMetas.zonas = zonas;
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    async saveConfiguracion() {
      this.$isLoading(true);
      try {
        console.log("save configuracion", this.forms.config);

        const { date, fields } = this.forms.config;

        const [anio, mes] = date.split("-").map(Number);

        console.log(anio, mes, fields);

        const extractedFields = fields.reduce((acc, field) => {
          const { name, value } = field;
          acc[name] = value;
          return acc;
        }, {});

        console.log("extractedFields", extractedFields);

        let data = {
          mes,
          anio,
          ...extractedFields,
        };

        console.log("data", data);

        let res = await this.$store.dispatch("denti_post", {
          path: "BaseMetasMensuales/CrearConfiguracion",
          data,
        });
        console.log("res", res);

        if (res.id) {
          this.showToast("success", "Se ha guardado la configuración.");
          this.ultimaConfiguracion = res;
        }
        this.$isLoading(false);
      } catch (error) {
        console.log("error", error);
        this.$isLoading(false);
        Swal.fire("Error!", "Error: " + error, "error");
      }
    },
    async saveBaseMetas() {
      this.$isLoading(true);
      try {
        console.log("save base metas", this.forms.baseMetas);

        const { date, zonas } = this.forms.baseMetas;

        const [anio, mes] = date.split("-").map(Number);

        // Transformar las zonas y ubicaciones en un formato plano
        const data = zonas.flatMap((zona) =>
          zona.ubicaciones.map((ubicacion) => ({
            anio,
            mes,
            zona: zona.name,
            ubicacion: ubicacion.name,
            metaMensual: ubicacion.metaMensual,
          }))
        );
        console.log("Datos a enviar:", data);


        let res = await this.$store.dispatch("denti_post", {
          path: "BaseMetasMensuales/CrearBaseMetas",
          data,
        });

        console.log("res", res);


        if (res.status) {
          // calcular valores base metas
          let result = await this.$store.dispatch("denti_post", {
            path: "BaseMetasMensuales/CalcularBaseMetas/" + anio + "/" + mes,
          });

          console.log("result", result);

          this.showToast("success", res.message);

        } else {
          Swal.fire("Error!", res.message, "error");
        }

        this.$isLoading(false);
      } catch (error) {
        console.log("error", error);
        this.$isLoading(false);
        Swal.fire("Error!", "Error: " + error, "error");
      }
    },
    handleDateInput(event) {
      const fecha = event.target.value; // Formato 'YYYY-MM'
      if (fecha) {
        const [anio, mes] = fecha.split("-").map(Number);
        console.log(anio, mes);

        this.getDiasHabiles(anio, mes);
      }
    },
    getDiasHabiles(anio, mes) {
      const diasHabiles = this.obtenerDiasHabiles(anio, mes);
      console.log(diasHabiles);

      const campoDiasHabiles = this.forms.config.fields.find(
        (f) => f.name === "diasHabiles"
      );
      if (campoDiasHabiles) {
        campoDiasHabiles.value = diasHabiles.length;
      }
    },
    obtenerDiasHabiles(anio, mes) {
      const hd = new Holidays("CO");
      const inicio = new Date(anio, mes - 1, 1);
      const fin = new Date(anio, mes, 0);
      const dias = eachDayOfInterval({ start: inicio, end: fin });
      const festivos = hd
        .getHolidays(anio)
        .filter((festivo) => {
          const fechaFestivo = new Date(festivo.date);
          return fechaFestivo.getMonth() + 1 === mes;
        })
        .map((festivo) => format(new Date(festivo.date), "yyyy-MM-dd"));

      // const diasHabiles = dias.filter(dia => {
      //   const esFinDeSemana = isWeekend(dia);
      //   const esFestivo = festivos.includes(format(dia, 'yyyy-MM-dd'));
      //   return !esFinDeSemana && !esFestivo;
      // });

      // Filtrar días hábiles incluye sabados
      const diasHabiles = dias.filter((dia) => {
        const diaSemana = dia.getDay(); // 0: Domingo, 1: Lunes, ..., 6: Sábado
        const esDomingo = diaSemana === 0; // Solo Domingo
        const esFestivo = festivos.includes(format(dia, "yyyy-MM-dd"));
        return !esDomingo && !esFestivo;
      });

      return diasHabiles.map((dia) => format(dia, "yyyy-MM-dd"));
    },
  },
};
</script>

<style lang="scss" scoped>
.body-content-baseMetas {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
