<script>
import LegoModalLayout from "@/views/Helexium/Lego/LegoModalLayout.vue";
import ModalHeaderControls from "@/components/Lego/ModalHeaderControls.vue";
import AddBtn from "@/components/buttons/AddBtn.vue";
import LegoQuill from "@/components/Lego/LegoQuill.vue";
import LegoModalCheckList from "@/components/Lego/LegoModalCheckList.vue";
import LegoModalComentarios from "@/components/Lego/LegoModalComentarios.vue";
import LegoModalDependents from "@/components/Lego/LegoModalDependents.vue";

export default {
  components: {
    LegoModalLayout,
    AddBtn,
    ModalHeaderControls,
    LegoQuill,
    LegoModalCheckList,
    LegoModalComentarios,
    LegoModalDependents,
  },
  props: {
    boardId: {
      type: String,
      required: true,
    },
    cardId: {
      type: String,
      required: true,
    },
  },
  beforeDestroy() {
    const empty = {};
    this.$store.commit("lego/setCard", empty);
    this.$store.dispatch("lego/getAndSetBoardDeep", this.boardId);
  },
  data() {
    return {
      activeEditor: "",
      description: "",
    };
  },
  computed: {
    board() {
      return this.$store.state.lego.board;
    },
    column() {
      try {
        return this.board.columns.find(
          (column) => column.id == this.card.legoColumnId
        );
      } catch {
        return {};
      }
    },
    shadowCard() {
      try {
        const allCards = this.board.columns
          .map((column) => column.cards)
          .flat();
        return allCards.find((card) => card.id == this.cardId);
      } catch {
        return {};
      }
    },
    card() {
      return this.$store.state.lego.card;
    },
    title() {
      return this.card.title;
    },
  },
  async mounted() {
    this.$isLoading(true);
    // this.$store.commit('lego/setCard', this.shadowCard) // Linea de optimización pero ha generado errores con el Gantt

    await this.initData();
    this.$watch(() => this.$route.params.cardId, this.initData);
    this.$isLoading(false);
  },
  methods: {
    async initData() {
      await this.$store.dispatch("lego/getAndSetCard", this.cardId);
      this.description = this.card.description;
    },

    saveTitle(ev) {
      const title = ev.target.value.trim();
      if (!title) return;
      this.$store.dispatch("lego/setItemInCard", { title });
      ev.target.blur();
    },
    saveDescription() {
      const html = this.description.replace(
        /^(<p><br><\/p>)+|(<p><br><\/p>)+$/g,
        ""
      );
      this.$store.dispatch("lego/setItemInCard", { description: html });
    },
    nextAndSaveDescription(quill) {
      this.saveDescription();
      const next =
        quill.$el.parentElement.parentElement.nextElementSibling.querySelector(
          "button, input"
        );
      next.focus();
    },
  },
};
</script>

<template>
  <LegoModalLayout>
    <template #header="{ close }">
      <header class="header">
        <ModalHeaderControls />
        <AddBtn class="close m-2" @click="close" />
      </header>
    </template>
    <div>
      <input
        class="title lego lego-input"
        type="text"
        :value="title"
        @blur="saveTitle"
        @keydown.enter="saveTitle"
      />

      <div class="item-card">
        <div>
          <svg
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <line
              x1="3"
              y1="6"
              x2="21"
              y2="6"
              stroke-linejoin="round"
              stroke-width="2"
            ></line>
            <line
              x1="3"
              y1="10"
              x2="21"
              y2="10"
              stroke-linejoin="round"
              stroke-width="2"
            ></line>
            <line
              x1="3"
              y1="14"
              x2="21"
              y2="14"
              stroke-linejoin="round"
              stroke-width="2"
            ></line>
            <line
              x1="3"
              y1="18"
              x2="14"
              y2="18"
              stroke-linejoin="round"
              stroke-width="2"
            ></line>
          </svg>
        </div>
        <div class="in-right">
          <div>
            <span class="title-section"> Descripción </span>
          </div>
          <LegoQuill
            @blur="saveDescription"
            @ctrlEnter="saveDescription"
            @tab="nextAndSaveDescription"
            v-model="description"
          />
        </div>
      </div>

      <LegoModalDependents
        v-if="card.legoCardId == null && column"
        :color="column.color"
      />

      <LegoModalCheckList
        class="mb-3"
        :color="column ? column.color : '#ef476f'"
      />
      <LegoModalComentarios />
    </div>
  </LegoModalLayout>
</template>

<style scoped>
.header {
  margin-bottom: 1.5rem;
  border-bottom: solid 1px #cacaca;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .dark & {
    border-bottom: solid 1px rgba(255, 255, 255, 0.25);
  }
}

.in-right {
  grid-column-start: 2;
  grid-column-end: none;
}

.title {
  border: none;
  font-size: 1.5rem;
  color: white;
  margin-bottom: 0.8rem;
  padding-left: 0;
  border-radius: 0px;
}

.title.lego:focus {
  border: none;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.5);
}

.item-card {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}
.title-section {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  display: flex;
  gap: 0.5rem;
}
</style>
