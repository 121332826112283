<template>
  <div>
    <h4 class="px-4 py-4"><b>Informacion de Articulos</b></h4>
    <form class="form" @submit.prevent="saveData">
      <div class="wrapper-fields">
        <div class="wrapper-input" title="Divisa">
          <label for="DivisaId">Divisa</label>
          <b-form-select name='DivisaID' text-field="nombre" value-field="id"
                         placeholder='-- Please select an option --'
                         :options="ListaDivisas" v-model='infoDivisa'>
            <template #first>
              <b-form-select-option :value="null">-- Please select an option --</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="wrapper-input" title="MetodosPagos">
          <label for="DivisaId">Metodos Pago disponibles</label>
          <multiSelect v-model="PagosSeleccionados" :pre-load="true" :data-preload="PagosSeleccionadosCompleto"
                       :options="ListaMetodosDePagos" valorGuardar="ID" valorVer="Medio"
                       :selectFirst=false :allData="false" :data-required="false"/>
        </div>
        <div class="wrapper-input" title="MedidasFavoritas">
          <label for="DivisaId">Medidas Favoritas</label>
          <multiSelect v-model="medidasSeleccionadas" :pre-load="true" :data-preload="medidasSeleccionadasCompleto"
                       :options="ListaMedidas" valorGuardar="ID" valorVer="Descripción"
                       :selectFirst=false :allData="false" :data-required="false"/>
        </div>
      </div>
      <vs-button class="button" :loading="waiting">Guardar</vs-button>
    </form>
  </div>
</template>

<script>

import multiSelect from "@/components/MultiSelect/MultiSelect.vue";
import Swal from "sweetalert2";

export default {
  components: {multiSelect},
  async created() {
    const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
    if (valor) {
      this.idEmpresa = valor.empresa.id;
    }
    await this.getData();
  },
  data() {
    return {
      waiting: false,
      ListaDivisas: [],
      ListaMedidas: [],
      ListaMetodosDePagos: [],
      infoDivisa: '',
      PagosSeleccionados: [],
      PagosSeleccionadosCompleto: [],
      medidasSeleccionadas: [],
      medidasSeleccionadasCompleto: [],
      idEmpresa: '',
    };
  },
  methods: {
    async getData() {
      await this.$store.dispatch('hl_get', {
        path: 'GDivisas/ObtenerDivisas'
      }).then(response => {
        if (response.respuesta) {
          this.ListaDivisas = response.data;
        }
      });

      await this.$store.dispatch('hl_get', {
        path: 'GMetodosPagos/ObtenerMetodosPagos'
      }).then(response => {
        if (response.respuesta) {
          this.ListaMetodosDePagos = response.data;
        }
      })

      await this.$store.dispatch('hl_get', {
        path: 'GMedidas/ObtenerTodasLasMedidas'
      }).then(response => {
        if (response.respuesta) {
          this.ListaMedidas = response.data;
        }
      })

      await this.$store.dispatch('hl_get', {path: "ContVentas/ConsultarInfoVentas/" + this.idEmpresa})
          .then(response => {
            if (response.respuesta) {
              this.infoDivisa = response.data.DivisaId;
              this.PagosSeleccionadosCompleto = response.data["MetodosPagos"];
              this.medidasSeleccionadasCompleto = response.data["Medidas"];
              this.medidasSeleccionadas = response.data["Medidas"].map(metodo => metodo.ID);
              this.PagosSeleccionados = response.data["MetodosPagos"].map(medida => medida.ID);
            } else {
              this.$vs.notification({
                position: 'top-center',
                title: 'Error',
                text: response.mensaje,
                border: 'danger'
              });
            }
          })
    },
    async saveData() {
      this.waiting = true;
      let jsonData = {
        idMetodosPagos: this.PagosSeleccionados,
        idMedidasFavoritas: this.medidasSeleccionadas,
        idDivisa: this.infoDivisa
      };
      await this.$store.dispatch('hl_post', {
        path: 'ContVentas/ActualizarInfoVentas/' + this.idEmpresa,
        data: jsonData
      }).then(response => {
        if (!response.respuesta) {
          Swal.fire({
            title: "Error",
            text: response.mensaje,
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        } else {
          Swal.fire({
            title: "Guardada",
            text: response.mensaje,
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        }
      })
      this.waiting = false;
    }
  }
}
</script>

<style scoped>
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form {
  padding: 0 48px 16px;
  display: flex;
  flex-direction: column;
}

.form .button {
  align-self: flex-end;
}
</style>