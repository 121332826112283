<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold" style='font-size: 30px'>Nuevo Descuento</h4>
          </template>
          <form class='px-4' style='height: auto;' @submit.prevent="CrearDescuento">
            <div class="row pt-4 align-items-center">
              <div class='col-12 col-md-6 pb-3 pb-md-0'>
                <label for="nombre">nombre *</label>
                <b-form-input id="nombre" v-model="Descuento.Nombre" required placeholder="Ingrese Nombre"
                              trim></b-form-input>
              </div>
              <div class="co-12 col-md-6 pb-3 pd-md-0">
                <label for="nombre">Porcentaje *</label>
                <b-form-input type="number" id="porcentajeDescuento" placeholder="Ingrese el porcentaje"
                              v-model="Descuento.Porcentaje" required trim></b-form-input>
              </div>
            </div>
            <div class='pt-3'>
              <label for="descripcion">Descripción</label><br/>
              <b-form-textarea rows="6" class='p-3' id='descripcion' v-model="Descuento.Descripcion"
                               placeholder="Ingrese Descripción" required></b-form-textarea>
            </div>

            <div class="d-flex flex-column pt-5 pb-2">
              <h4 class="mb-2" style="font-size: large;">Periodo de actividad</h4>
              <p class="w-100" style="font-size: 13px;">
                Selecciona el tipo de periodo y define las fechas en las que el descuento será aplicado.
              </p>
              <hr style="height: 1px; background: var(--dark);" class="w-100"/>
            </div>

            <!-- Fechas -->
            <div class="row">
              <div class="col-12 col-md-6">
                <label for="FechaInicio">Fecha inicio *</label>
                <b-form-input
                    type="date"
                    id="FechaInicio"
                    placeholder="Ingrese Fecha"
                    v-model="Descuento.FechaInicio"
                    required
                    trim
                ></b-form-input>
              </div>
              <div class="col-12 col-md-6">
                <label for="FechaFin">Fecha Fin *</label>
                <b-form-input
                    type="date"
                    id="FechaFin"
                    placeholder="Ingrese Fecha"
                    v-model="Descuento.FechaFin"
                    required
                    trim
                ></b-form-input>
              </div>
            </div>

            <!-- Tipo de descuento -->
            <div class="row mt-4">
              <div class="col-12 col-md-12">
                <label for="tipoDescuento">Tipo de descuento *</label>
                <b-form-radio-group id="tipoDescuento" v-model="Descuento.Tipo" name="tipoDescuento"
                                    class="d-flex" size="lg">
                  <b-form-radio :value="0" class="mr-3">
                    Continuo
                    <small class="d-block text-muted">
                      Aplica desde la fecha de inicio hasta la fecha de fin de forma continua.
                    </small>
                  </b-form-radio>
                  <b-form-radio :value="1">
                    Específico
                    <small class="d-block text-muted">
                      Aplica solo en ciertos meses y días específicos dentro del rango de fechas.
                    </small>
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <!-- Frecuencia -->
            <div class="row mt-4" v-if="Descuento.Tipo === 1">
              <div class="col-12">
                <label for="frecuenciaEspecifica">Frecuencia *</label>
                <b-form-radio-group size="lg" id="frecuenciaEspecifica" v-model="Descuento.Frecuencia"
                                    name="frecuenciaEspecifica" class="d-flex justify-content-around">
                  <b-form-radio :value="'Semanal'" class="mb-2">
                    Semanal
                    <small class="d-block text-muted">Selecciona los días de la semana.</small>
                  </b-form-radio>
                  <b-form-radio :value="'Mensual'">
                    Mensual
                    <small class="d-block text-muted">Selecciona los meses y días específicos del mes.</small>
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <!-- Días de la semana -->
            <div class="row" style="margin-top: 30px" v-if="Descuento.Tipo === 1 && Descuento.Frecuencia === 'Semanal'">
              <div class="col-12">
                <label for="diasEspecificos">Días de la semana *</label>
                <b-form-checkbox-group size="lg" id="diasEspecificos" v-model="Descuento.DiasEntreSemanaEspecificos"
                                       :options="diasSemana"></b-form-checkbox-group>
              </div>
            </div>

            <!-- Meses y días del mes -->
            <div class="row" style="margin-top: 30px" v-if="Descuento.Tipo === 1 && Descuento.Frecuencia === 'Mensual'">
              <div class="col-12 col-md-6">
                <label for="mesesEspecificos">Meses *</label>
                <b-form-checkbox-group size="lg" id="mesesEspecificos" v-model="Descuento.MesesEspecificos"
                                       class="d-flex flex-wrap">
                  <b-form-checkbox v-for="(mes, index) in meses" :key="index" :value="mes.value"
                                   class="col-12 col-md-4">
                    {{ mes.text }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </div>
              <div class="col-12 col-md-6">
                <label for="diasDelMes">Días del mes *</label>
                <b-form-checkbox-group id="diasDelMes" v-model="Descuento.DiasDelMesEspecificos" name="diasDelMes"
                                       class="d-flex flex-wrap" :disabled="Descuento.MesesEspecificos.length === 0">
                  <b-form-checkbox v-for="day in diasDelMesOptions" :key="day" :value="day"
                                   :id="'day-' + day" class="col-1 mb-2">
                    {{ day }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
                <small class="text-muted">Selecciona los días del mes.</small>
              </div>

            </div>

            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" :loading="waiting">Guardar</vs-button>
              <vs-button size="large" class='bg-danger' @click.prevent="$router.push('Descuentos')">Cancelar</vs-button>
            </div>
          </form>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      waiting: false,
      EmpresaId: null,
      Descuento: {
        Nombre: null,
        Tipo: 0,
        Descripcion: null,
        FechaInicio: null,
        FechaFin: null,
        Porcentaje: null,

        Frecuencia: null,
        MesesEspecificos: [],
        DiasDelMesEspecificos: [],
        DiasEntreSemanaEspecificos: []
      },
      diasSemana: [
        {text: 'Lunes', value: 0},
        {text: 'Martes', value: 1},
        {text: 'Miércoles', value: 2},
        {text: 'Jueves', value: 3},
        {text: 'Viernes', value: 4},
        {text: 'Sábado', value: 5},
        {text: 'Domingo', value: 6}
      ],
      meses: [
        {text: 'Enero', value: 0},
        {text: 'Febrero', value: 1},
        {text: 'Marzo', value: 2},
        {text: 'Abril', value: 3},
        {text: 'Mayo', value: 4},
        {text: 'Junio', value: 5},
        {text: 'Julio', value: 6},
        {text: 'Agosto', value: 7},
        {text: 'Septiembre', value: 8},
        {text: 'Octubre', value: 9},
        {text: 'Noviembre', value: 10},
        {text: 'Diciembre', value: 11}
      ],
      diasDelMesOptions: Array.from({length: 31}, (_, i) => i + 1),
      TiposDecuentos: [
        {
          Tipo: 'Continuo',
          id: 0
        }, {
          Tipo: 'Especifico',
          id: 1
        }
      ]
    }
  },
  async mounted() {
    const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
    if (valor) this.EmpresaId = valor.empresa.id;
  },
  methods: {
    async CrearDescuento() {
      this.waiting = true;
      if (this.Descuento.Tipo === 1 && this.Descuento.Frecuencia === null) {
        Swal.fire({
          title: 'Error',
          text: "Debe seleccionar la frecuencia",
          icon: 'error',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          this.waiting = false;
        });
      } else if (this.Descuento.Tipo === 1 && this.Descuento.Frecuencia === "Semanal" &&
          this.Descuento.DiasEntreSemanaEspecificos.length === 0) {
        Swal.fire({
          title: 'Error',
          text: "Debe seleccionar al menos 1 dia de la semana",
          icon: 'error',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          this.waiting = false;
        });
      } else if (this.Descuento.Tipo === 1 && this.Descuento.Frecuencia === "Mensual" &&
          this.Descuento.DiasDelMesEspecificos.length === 0) {
        Swal.fire({
          title: 'Error',
          text: "Debe seleccionar los meses en el que aplicara el descuento",
          icon: 'error',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          this.waiting = false;
        });
      } else if (this.Descuento.Tipo === 1 && this.Descuento.Frecuencia === "Mensual" &&
          this.Descuento.DiasDelMesEspecificos.length === 0) {
        Swal.fire({
          title: 'Error',
          text: "Debe seleccionar los dias del mes en el que aplicara el descuento",
          icon: 'error',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          this.waiting = false;
        });
      } else {
        if (this.Descuento.Tipo === 0) {
          this.Descuento.MesesEspecificos = null;
          this.Descuento.DiasDelMesEspecificos = null;
          this.Descuento.DiasEntreSemanaEspecificos = null;
        } else if (this.Descuento.Tipo === 1) {
          if (this.Descuento.Frecuencia === "Semanal") {
            this.Descuento.DiasEntreSemanaEspecificos = this.Descuento.DiasEntreSemanaEspecificos.join(",");
            this.Descuento.MesesEspecificos = null;
            this.Descuento.DiasDelMesEspecificos = null;
          } else if (this.Descuento.Frecuencia === "Mensual") {
            this.Descuento.DiasEntreSemanaEspecificos = null;
            this.Descuento.MesesEspecificos = this.Descuento.MesesEspecificos.join(",");
            this.Descuento.DiasDelMesEspecificos = this.Descuento.DiasDelMesEspecificos.join(",");
          }
        }
        this.Descuento.FechaFin = this.$moment.tz(this.Descuento.FechaFin, "America/Bogota").format("YYYY-MM-DDTHH:mm");
        this.Descuento.FechaInicio = this.$moment.tz(this.Descuento.FechaInicio, "America/Bogota").format("YYYY-MM-DDTHH:mm");
        await this.$store.dispatch("hl_post", {
          path: "ContDescuentos/CrearDescuento",
          data: {
            Descuento: this.Descuento,
            empresaId: this.EmpresaId
          }
        }).then(response => {
          if (response.respuesta) {
            Swal.fire({
              title: 'Descuento creado',
              text: 'Se ha guardado el Descuento',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.$router.push('Descuentos');
            })
          } else {
            Swal.fire({
              title: 'Error',
              text: response.mensaje,
              icon: 'error',
              confirmButtonText: 'Aceptar'
            })
          }
        }).finally(this.waiting = false)
      }
    }
  }
}
</script>
<style scoped>

</style>