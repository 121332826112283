<template>
  <HXContentCard title="Labs🧪">
    <div class="py-5">
      <speech-input v-model="speechText" />
      <!-- <p>Texto capturado: {{ speechText }}</p> -->
    </div>
  </HXContentCard>
</template>

<script>
import speechInput from "@/components/SpeechInput/speechInput.vue";
export default {
  components: {
    speechInput,
  },
  data() {
    return {
      speechText: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
