<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold" style='font-size: 30px'>Editar Artículo</h4>
          </template>
          <div class="d-flex flex-column px-4 pt-4 pb-2">
            <h4 class='mb-2' style='font-size: large;'>General</h4>
            <p class='w-100' style='font-size: 13px;'>Seleccione un categoría para que sus informes sean más
              detallados. La descripción se llenara cuando el articulo sea seleccionado en una factura o cuenta.</p>
            <hr style='height: 1px; background: var(--dark);' class='w-100'/>
          </div>
          <form class='px-4' @submit.prevent="editarCategoria" style='height: auto;'>
            <label style='font-size: medium;'>Tipo *</label>
            <div class='row' style='gap: 3px;'>
              <article class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <vs-button class="button" style="width: 100%"
                           :class="{ 'active-button': selectedButton !== 'Producto' }"
                           @click.prevent="selectButton('Producto')">Producto
                </vs-button>
              </article>
              <article class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <vs-button class="button" style="width: 100%"
                           :class="{ 'active-button': selectedButton !== 'Servicio' }"
                           @click.prevent="selectButton('Servicio')">Servicio
                </vs-button>
              </article>
            </div>
            <div class="row pt-4 align-items-center">
              <div class='col-12 col-md-4 pb-3 pb-md-0'>
                <label for="Nombre">Nombre *</label>
                <b-form-input id="Nombre" name='Nombre' aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Nombre" :value='saveArticuloObjet.Articulo.Nombre' trim
                              required></b-form-input>
              </div>
              <div class="col-12 col-md-4">
                <label for='Categoria'>Categoría</label><br/>
                <b-form-select name='Categoria' placeholder='-- Please select an option --'
                               :options="listaCategoria" v-model='saveArticuloObjet.CategoriaId'>
                  <template #first>
                    <b-form-select-option :value="null">-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class='col-12 col-md-4'>
                <label for="Nombre">Unidad de medida</label><br>
                <div style="display: flex; align-items: center;">
                  <b-form-select text-field="Descripción"
                                 name='MedidaId' placeholder="Seleccione una medida favorita" value-field="ID"
                                 :options="listaMedidasFavoritas" v-model='saveArticuloObjet.Articulo.UnidadMedidaId'
                                 :disabled="listaMedidasFavoritas.length === 0">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <vs-button v-b-tooltip.hover @click.prevent="isModalVisible = true" title='Buscar' success icon
                             class=' '>
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </vs-button>
                </div>
              </div>
            </div>
            <div class='pt-3'>
              <label for="Descripcion">Descripción</label><br/>
              <b-form-textarea rows="6" class='p-3' name='Descripcion' placeholder="Ingrese Descripción" required
                               :value='saveArticuloObjet.Articulo.Descripcion'></b-form-textarea>
            </div>
            <div class="d-flex flex-column pt-5 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Facturación</h4>
              <p class='w-100' style='font-size: 13px;'>La información de venta se utiliza dentro de la facturacion.</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <div class="row align-items-center">
              <div class='col-12 col-md-6'>
                <label for="PrecioVenta">Precio de Venta *</label>
                <b-form-input name='PrecioVenta' id="input-live" v-model="saveArticuloObjet.Articulo.PrecioVenta"
                              aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese el Precio de Venta" trim
                              required type='number'></b-form-input>
              </div>
              <div class="col-12 col-md-6">
                <label for="Sku">Sku</label><br/>
                <b-form-input id="Sku" name='Sku' aria-describedby="input-live-help input-live-feedback"
                              :value="saveArticuloObjet.Articulo.Sku"
                              placeholder="Ingrese Sku" trim required></b-form-input>
              </div>
            </div>
            <div class="d-flex flex-column pt-5 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Avanzado</h4>
              <p class='w-100' style='font-size: 13px;'>Configure si hay control del inventario, Duracion especifica
                entre otros.</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <div class="row pt-4 align-items-center">
              <div class='col-12 col-md-4'>
                <b-form-checkbox id="ControlInventario" size="lg" v-model="saveArticuloObjet.Articulo.ControlInventario"
                                 name="ControlInventario">
                  Control de inventario
                </b-form-checkbox>
              </div>
              <div class='col-12 col-md-4'>
                <b-form-checkbox id="RequiereDuracion" size="lg" v-model="saveArticuloObjet.Articulo.RequiereDuracion"
                                 name="RequiereDuracion">
                  Duración específica aplicable
                </b-form-checkbox>
              </div>
              <div class='col-12 col-md-4'>
                <b-form-checkbox id="ControlAbastecimiento" size="lg"
                                 v-model="saveArticuloObjet.Articulo.ControlAbastecimiento"
                                 name="ControlAbastecimiento">
                  Control de abastecimiento
                </b-form-checkbox>
              </div>
            </div>
            <div v-if="saveArticuloObjet.Articulo.ControlInventario" class="d-flex flex-column pt-5 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Control de inventario</h4>
              <p class='w-100' style='font-size: 13px;'>Configure si hay control del inventario, Duracion especifica
                entre otros.</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <div v-if="saveArticuloObjet.Articulo.ControlInventario" class='row align-items-center'>
              <div class="col-12 col-md-6">
                <label for="Stock">Stock</label><br/>
                <b-form-input type='number' id="Stock" name='Stock' v-model="saveArticuloObjet.Articulo.Stock"
                              aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Stock" trim
                              :required="saveArticuloObjet.Articulo.ControlInventario"></b-form-input>
              </div>
              <div class="col-12 col-md-6">
                <label for="StockAlert">Notificarme cuando el stock sea menor a</label><br/>
                <b-form-input type="number" id="StockAlert" name="StockAlert"
                              v-model="saveArticuloObjet.Articulo.StockAlert"
                              aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese cantidad mínima de stock" trim></b-form-input>
              </div>
            </div>
            <div v-if="saveArticuloObjet.Articulo.RequiereDuracion" class="d-flex flex-column pt-5 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Duracion del articulo</h4>
              <p class='w-100' style='font-size: 13px;'>Configure el tiempo de duracion del articulo, este sera aplicado
                desde que se efectue una venta.</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <div v-if="saveArticuloObjet.Articulo.RequiereDuracion" class='row align-items-center'>
              <div class="col-3 col-md-2">
                <label for="Stock">Años</label><br/>
                <b-form-input type='number' id="Stock" name='Stock' v-model="saveArticuloObjet.Duracion.Anios"
                              aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese los años" trim
                ></b-form-input>
              </div>
              <div class="col-3 col-md-2">
                <label for="Stock">Meses</label><br/>
                <b-form-select name='Meses' :options="TiempoOpcion.Meses" v-model="saveArticuloObjet.Duracion.Meses"
                               value='null'>
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-3 col-md-2">
                <label for="Stock">Dias</label><br/>
                <b-form-select name='Dias' :options="TiempoOpcion.Dias" v-model="saveArticuloObjet.Duracion.Dias"
                               value='null'>
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-3 col-md-2">
                <label for="Stock">Horas</label><br/>
                <b-form-select name='Horas' :options="TiempoOpcion.Horas" v-model="saveArticuloObjet.Duracion.Horas"
                               value='null'>
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-3 col-md-2">
                <label for="Stock">Minutos</label><br/>
                <b-form-select name='Minutos' :options="TiempoOpcion.Minutos"
                               v-model="saveArticuloObjet.Duracion.Minutos"
                               value='null'>
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div v-if="saveArticuloObjet.Articulo.ControlAbastecimiento" class="d-flex flex-column pt-5 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Abastecimiento</h4>
              <p class='w-100' style='font-size: 13px;'>Configure los proveedores, el precio de compra entre otros</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <div v-if="saveArticuloObjet.Articulo.ControlAbastecimiento" class='row align-items-center'>
              <div class='col-12 col-md-6'>
                <label for="PrecioVenta">Precio de Compra *</label>
                <b-form-input name='PrecioCompra' id="input-live" v-model="saveArticuloObjet.Articulo.PrecioCompra"
                              aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese el Precio de Venta" trim
                              :required="saveArticuloObjet.Articulo.ControlAbastecimiento" type='number'></b-form-input>
              </div>
            </div>
            <div class="d-flex flex-column pt-5 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Impuestos</h4>
              <p class='w-100' style='font-size: 13px;'>Los impuestos seran calculados en base al costo del
                articulo.</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <vs-table>
              <template #thead>
                <vs-tr style='background: var(--body-bg) !important'>
                  <vs-th style='min-width: 270px !important;  background: var(--body-bg) !important'>
                    Impuesto
                  </vs-th>
                  <vs-th style='min-width: 280px !important;  background: var(--body-bg) !important'>
                    Descripcion
                  </vs-th>
                  <vs-th style='min-width: 10rem;  background: var(--body-bg) !important'>
                    Porcentaje
                  </vs-th>
                  <vs-th style='min-width: 10rem;  background: var(--body-bg) !important'>
                    Acciones
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr v-for="(data, index) in saveArticuloObjet.Impuestos" :key="index">
                  <vs-td>
                    <b-form-select name='Impuesto' v-model="saveArticuloObjet.Impuestos[index].Impuesto"
                                   :options='listaImpuestos' :value-field="'ID'" :text-field="'TextoLabel'"
                                   @change="updateDescripcion(index)"
                                   placeholder='-- Seleccionar un Impuesto --' required>
                      <template #selected="{ option }">
                        {{ option?.Impuesto || '-- Seleccionar un Impuesto --' }}
                        <!-- Muestra solo el diminutivo seleccionado -->
                      </template>
                      <template #first>
                        <b-form-select-option value="null" disabled>-- Seleccionar un Impuesto --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </vs-td>
                  <vs-td>{{ data.Descripcion }}</vs-td>
                  <vs-td>
                    <b-form-input type="number" id="Porcentaje" name='PorcentajeImpuesto'
                                  :disabled="saveArticuloObjet.Impuestos[index].Impuesto==null"
                                  v-model="saveArticuloObjet.Impuestos[index].Porcentaje"
                                  aria-describedby="input-live-help input-live-feedback"
                                  placeholder="Ingrese Porcentaje" required trim>
                    </b-form-input>
                  </vs-td>
                  <vs-td>
                    <vs-button class='bg-danger' @click.prevent="eliminarImpuesto(index)">
                      <ion-icon name="trash-outline"></ion-icon>
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
              <template #footer>
                <div class='d-flex justify-content-center'>
                  <vs-button class='d-flex' style='gap: 1rem;' primary @click.prevent="agregarImpuesto">
                    <ion-icon
                        style='font-size: 1.6rem' name="add-outline"></ion-icon>
                    Agregar Impuesto
                  </vs-button>
                </div>
              </template>
            </vs-table>
            <div class="d-flex flex-column pt-5 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Descuentos</h4>
              <p class='w-100' style='font-size: 13px;'>Los Descuentos seran calculados en base al costo del
                articulo, y se veran reflejados en la factura</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <vs-table>
              <template #thead>
                <vs-tr style='background: var(--body-bg) !important'>
                  <vs-th style='min-width: 270px !important;  background: var(--body-bg) !important'>
                    Descuento
                  </vs-th>
                  <vs-th style='min-width: 280px !important;  background: var(--body-bg) !important'>
                    Descripcion
                  </vs-th>
                  <vs-th style='min-width: 10rem;  background: var(--body-bg) !important'>
                    Porcentaje
                  </vs-th>
                  <vs-th style='min-width: 10rem;  background: var(--body-bg) !important'>
                    Acciones
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr v-for="(data, index) in saveArticuloObjet.Descuentos" :key="index">
                  <vs-td>
                    <b-form-select name='Impuesto' v-model="saveArticuloObjet.Descuentos[index].Descuento"
                                   :options='listaDescuentos' :value-field="'ID'" :text-field="'Descuento'"
                                   @change="updateDescripcionDescuento(index)"
                                   placeholder='-- Seleccionar un Impuesto --' required>
                      <template #selected="{ option }">
                        {{ option?.Impuesto || '-- Seleccionar un Impuesto --' }}
                      </template>
                      <template #first>
                        <b-form-select-option value="null" disabled>-- Seleccionar un Impuesto --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </vs-td>
                  <vs-td>{{ data.Descripcion }}</vs-td>
                  <vs-td>
                    {{ saveArticuloObjet.Descuentos[index].Porcentaje }} %
                  </vs-td>
                  <vs-td>
                    <vs-button class='bg-danger' @click.prevent="eliminarDescuento(index)">
                      <ion-icon name="trash-outline"></ion-icon>
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
              <template #footer>
                <div class='d-flex justify-content-center'>
                  <vs-button class='d-flex' style='gap: 1rem;' primary @click.prevent="agregarDescuento">
                    <ion-icon
                        style='font-size: 1.6rem' name="add-outline"></ion-icon>
                    Agregar Descuento
                  </vs-button>
                </div>
              </template>
            </vs-table>
            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" :loading="waiting">Guardar</vs-button>
              <vs-button size="large" class='bg-danger'
                         @click.prevent="$router.push('/helex/Erp/Contabilidad/Articulos')">Cancelar
              </vs-button>
            </div>
          </form>
        </card>
      </b-col>
    </b-row>
    <vs-dialog v-model="isModalVisible" title="Buscar Medidas">
      <template #header>
        <h4 class="not-margin">
          Medidas
        </h4>
      </template>
      <div>
        <!-- Campo de búsqueda -->
        <vs-input v-model="searchTerm" placeholder="Buscar medida..." style="margin-bottom: 10px; width: 100%;"/>

        <!-- Lista de Radio Buttons -->
        <div style="height: 350px; overflow: auto; display: flex; align-items: start; flex-direction: column;">
          <vs-radio v-for="(data) in filteredMedidas" :key="data.ID" :val="data.ID" style="padding: 5px"
                    v-model="saveArticuloObjet.Articulo.UnidadMedidaId">
            {{ data.Descripción }}
          </vs-radio>
        </div>
      </div>
      {{ saveArticuloObjet }}
      <template #footer>
        <div style="display: flex; align-items: center;">
          <vs-button @click="isModalVisible = false" type="filled" color="primary">Aceptar</vs-button>
          <vs-button @click="isModalVisible = false" type="filled" color="danger">Cerrar</vs-button>
        </div>
      </template>
    </vs-dialog>
  </b-container>
</template>

<script>
import {core} from "../../../../../config/pluginInit";
import Swal from 'sweetalert2';

export default {
  computed: {
    filteredMedidas() {
      // Filtra las medidas según el término de búsqueda
      return this.listaMedidas.filter((data) =>
          data.Descripción.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  },
  data() {
    return {
      selectedButton: 'producto',
      waiting: false,
      EmpresaId: null,
      searchTerm: "",
      saveArticuloObjet: {
        Articulo: {
          Nombre: null,
          Tipo: null,
          Sku: null,
          Descripcion: null,
          PrecioVenta: 0,
          PrecioCompra: 0,
          ControlInventario: false,
          Stock: 0,
          RequiereDuracion: false,
          ControlAbastecimiento: false,
          StockAlert: null,
          UnidadMedidaId: null
        },
        Impuestos: [],
        Descuentos: [],
        CategoriaId: null,
        Duracion: {
          Anios: null,
          Meses: null,
          Dias: null,
          Horas: null,
          Minutos: null
        }
      },
      listaCategoria: [],
      listaImpuestos: [],
      listaDescuentos: [],
      TiempoOpcion: {
        Meses: Array.from({length: 12}, (_, i) => i + 1),
        Dias: Array.from({length: 31}, (_, i) => i + 1),
        Horas: Array.from({length: 23}, (_, i) => i + 1),
        Minutos: Array.from({length: 59}, (_, i) => i + 1),
      },
      isModalVisible: false,
      listaMedidas: [],
      listaMedidasFavoritas: []
    }
  },
  async mounted() {
    const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
    if (valor) {
      this.EmpresaId = valor.empresa.id;
    }

    core.index();
    await this.getData();
  },
  methods: {
    selectButton(button) {
      this.selectedButton = button;
    },
    async editarCategoria(event) {
      this.saveArticuloObjet.Articulo.Tipo = this.selectedButton;
      try {
        this.waiting = true;
        this.$store.dispatch('hl_post', {
          path: `CONTArticulo/ActualizarArticulo/${this.$route.params.id}`,
          data: this.saveArticuloObjet
        }).then(response => {
          if (response.respuesta) {
            Swal.fire({
              title: 'Artículo Editado',
              text: 'Se ha editado el Artículo',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.$router.push('/helex/Erp/Contabilidad/Articulos');
              event.target.reset();
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: response.mensaje,
              icon: 'error',
              confirmButtonText: 'Aceptar'
            })
          }
        })

      } catch (error) {
        await Swal.fire({
          title: 'Error',
          text: 'No se ha editado el Artículo',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        })
      } finally {
        this.waiting = false;
      }

    },
    async getData() {
      try {
        await this.$store.dispatch("hl_get", {
          path: 'CONTCategoria/GetCategoriaByEmpresaId/' + this.EmpresaId,
        }).then(response => {
          if (response.mensaje) {
            this.listaCategoria = response.data.map(item => ({
              value: item.Id,
              text: item.Nombre
            }));
          } else {
            this.saveCategoria = [];
          }
        })

        await this.$store.dispatch('hl_get', {
          path: 'GMedidas/ObtenerTodasLasMedidas',
        }).then(response => {
          if (response.respuesta) {
            this.listaMedidas = response.data;
          } else {
            this.$vs.notification({
              position: 'top-center',
              title: 'Error',
              text: response.mensaje,
              border: 'danger'
            })
          }
        })

        await this.$store.dispatch('hl_get', {
          path: 'GMedidas/ObtenerMedidasFavoritasEmpresas/' + this.EmpresaId,
        }).then(response => {
          if (response.respuesta) {
            this.listaMedidasFavoritas = response.data;
          }
        })

        await this.$store.dispatch("hl_get", {
          path: 'CONTArticulo/ObtenerArticuloId/' + this.$route.params.id,
        }).then(response => {
          if (response.respuesta) {
            this.saveArticuloObjet = response.data[0];
            this.selectedButton = response.data[0].Articulo.Tipo;

            if (response.data[0].Articulo.UnidadMedidaId != null) {
              const medidaExists = this.listaMedidasFavoritas
                  .some(medida => medida.ID === response.data[0].Articulo.UnidadMedidaId);

              if (!medidaExists) {
                // Si no está, añade la medida seleccionada a la lista
                const MedidaNueva = this.listaMedidas
                    .find(medida => medida.ID === response.data[0].Articulo.UnidadMedidaId);
                this.listaMedidasFavoritas.push(MedidaNueva);
              }
            }
          } else {
            Swal.fire("Error", response.mensaje, "error");
          }
        });

        await this.$store.dispatch('hl_get', {
          path: 'CONTArticulo/ObtenerImpuestos'
        }).then(response => {
          if (response.respuesta) {
            this.listaImpuestos = response.data.map(item => ({
              ID: item.ID,
              Impuesto: item.Impuesto,
              Descripcion: item.Descripcion,
              TextoLabel: `${item.Impuesto} // ${item.Descripcion}`
            }))
          }
        })

        this.$store.dispatch('hl_get', {
          path: 'ContDescuentos/ObtenerListaDescuentosEmpresaId/' + this.EmpresaId,
        }).then(response => {
          if (response.respuesta) {
            this.listaDescuentos = response.data;
          } else {
            this.$vs.notification({
              position: 'top-center',
              title: 'Error',
              text: response.mensaje,
              border: 'danger'
            })
          }
        })

      } catch (error) {
        this.saveCategoria = [];
      }
    },
    agregarDescuento() {
      this.saveArticuloObjet.Descuentos.push({
        Descuento: null,
        Descripcion: null,
        Porcentaje: 0
      });
    },
    eliminarDescuento(index) {
      this.saveArticuloObjet.Descuentos.splice(index, 1);
    },
    updateDescripcionDescuento(index) {
      const selectedId = this.saveArticuloObjet.Descuentos[index].Descuento;
      const selectedImpuesto = this.listaDescuentos.find(descuento => descuento.ID === selectedId);

      this.saveArticuloObjet.Descuentos[index].Descripcion = selectedImpuesto
          ? selectedImpuesto.Descripcion : '';
      this.saveArticuloObjet.Descuentos[index].Porcentaje = selectedImpuesto
          ? selectedImpuesto.Porcentaje : '';
    },
    agregarImpuesto() {
      this.saveArticuloObjet.Impuestos.push({
        Impuesto: null,
        Descripcion: null,
        Porcentaje: 0
      });
    },
    eliminarImpuesto(index) {
      this.saveArticuloObjet.Impuestos.splice(index, 1);
    },
    updateDescripcion(index) {
      const selectedId = this.saveArticuloObjet.Impuestos[index].Impuesto;
      const selectedImpuesto = this.listaImpuestos.find(impuesto => impuesto.ID === selectedId);
      // Actualiza la descripción basada en el impuesto seleccionado
      this.saveArticuloObjet.Impuestos[index].Descripcion = selectedImpuesto
          ? selectedImpuesto.Descripcion
          : '';
    },
  },
  watch: {
    'saveArticuloObjet.Articulo.UnidadMedidaId'() {
      const medidaExists = this.listaMedidasFavoritas
          .some(medida => medida.ID === this.saveArticuloObjet.Articulo.UnidadMedidaId);
      if (!medidaExists) {
        // Si no está, añade la medida seleccionada a la lista
        const MedidaNueva = this.listaMedidas
            .find(medida => medida.ID === this.saveArticuloObjet.Articulo.UnidadMedidaId);
        this.listaMedidasFavoritas.push(MedidaNueva);
      }
    }
  }
}
</script>

<style>
.vs-select__input {
  background-color: transparent !important;
  border: 1px solid var(--vs-colors--dark) !important;
  width: 100%;
}

.vs-select-content {
  max-width: 50%;
}

.vs-input {
  width: 100%;
  border-radius: 7px;
}

.active-button {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
}
</style>