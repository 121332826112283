<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class="pb-5">
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold" style='font-size: 30px'>Editar Factura</h4>
          </template>
          <div class="d-flex flex-column px-4 pt-4 pb-2">
            <h4 class='mb-2' style='font-size: large;'>Informacion</h4>
            <p class='w-100' style='font-size: 13px;'>Los datos de facturación aparecen en su factura.</p>
            <hr style='height: 1px; background: var(--dark);' class='w-100'/>
          </div>
          <div class='px-4'>
            <div class="row">
              <div class="col-12 col-md-6 py-2">
                <label>Cliente</label><br/>
                <div style="display: flex; align-items: center;">
                  <b-form-input aria-describedby="input-live-help input-live-feedback"
                                v-model="factura.Cliente"
                                placeholder="Ingrese Código postal" disabled></b-form-input>
                </div>
              </div>
              <div class="col-12 col-md-4 py-2">
                <article>
                  <label>Fecha de vencimiento</label><br/>
                  <input type="date" class="form-control" v-model="factura.fechaVencimiento"/>
                </article>
              </div>
            </div>
            <div class="d-flex flex-column pt-4 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Productos</h4>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <vs-table>
              <template #thead>
                <vs-tr style='background: var(--body-bg) !important'>
                  <vs-th style='width: 150px !important;  background: var(--body-bg) !important'>Sku</vs-th>
                  <vs-th style="width: 250px; background: var(--body-bg) !important">Articulo</vs-th>
                  <vs-th style="width: 120px; background: var(--body-bg) !important">Cantidad</vs-th>
                  <vs-th id="Precio" style="width: 200px; background: var(--body-bg) !important">Precio</vs-th>
                  <vs-th style="width: 100px; background: var(--body-bg) !important">Impuestos %</vs-th>
                  <vs-th id="Total" style="min-width: 100px; background: var(--body-bg) !important">Total</vs-th>
                  <vs-th style="min-width: 100px; background: var(--body-bg) !important">Opciones</vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr v-for="(articulo, index) in factura.articulosSeleccionados" :key="index">
                  <vs-td>{{ articulo.articulo.Sku }}</vs-td>
                  <vs-td>{{ articulo.articulo.Nombre }}</vs-td>
                  <vs-td>
                    <b-form-input type="number" @change="calcularTotalesAndSubtotales" v-model="articulo.cantidad"
                                  min="1"></b-form-input>
                  </vs-td>
                  <vs-td>{{ formatearNumero(articulo.articulo.PrecioVenta) }}</vs-td>
                  <vs-td>{{ articulo.articulo.PorImpuestos }}%</vs-td>
                  <vs-td>
                    {{
                      formatearNumero(
                          (articulo.articulo.PrecioVenta * (1 + articulo.articulo.PorImpuestos / 100)) * articulo.cantidad
                      )
                    }}
                  </vs-td>
                  <vs-td>
                    <div style="display: flex; align-items: center;">
                      <vs-button v-b-tooltip.hover.auto title="Ver Impuestos" success icon
                                 @click='MostrarImpuestosModal(articulo.Impuesto, articulo.articulo.PrecioVenta)'>
                        <i class="fa-solid fa-eye"></i>
                      </vs-button>
                      <vs-button v-b-tooltip.hover.auto title="Eliminar" danger icon
                                 @click='factura.articulosSeleccionados.splice(index, 1);'>
                        <i class="fa-solid fa-trash"></i>
                      </vs-button>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
              <template #footer>
                <div class="d-flex justify-content-center">
                  <vs-button class="mb-3" warn @click.prevent="abriModalProductos">Agregar Artículo +
                  </vs-button>
                  <vs-button class="mb-3" id="BttnEscaneo" @click="activarEscaneo"
                             :class="{ 'bg-success': escaneando }">
                    {{ escaneando ? "Escaneando......." : "Iniciar Escaneo" }}
                  </vs-button>
                  <input type="text" id="codigoLector" autocomplete="off" v-model="codigoBarra"
                         @keyup.enter="procesarCodigoBarra" style="position: absolute; opacity: 0;
                       pointer-events: none;"/>
                </div>
              </template>
            </vs-table>
            <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            <div class='d-flex flex-column justify-content-end align-items-end'>
              <article class='d-flex align-items-center mb-3' style='width: fit-content;gap: 30px;'>
                <h3>subTotal</h3>
                <p class='mb-0' style='font-size: 20px; text-align: end'>{{ formatearNumero(SubTotal) }}</p>
              </article>
              <article class='d-flex align-items-center' style='width: fit-content;gap: 30px;'>
                <h3>Total</h3>
                <p class='mb-0' style='font-size: 20px; text-align: end'>{{ formatearNumero(Total) }}</p>
              </article>
            </div>
            <hr style='height: 1px; background: var(--dark);' class='w-100'/>

            <div class="d-flex flex-column pt-4 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Metodo De pago</h4>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>

            <div class="col-md-4">
              <b-form-select name='DivisaID' text-field="MetodoPago" value-field="ID"
                             :options="ListaMetodosDePagos" v-model='factura.MetodoPago' required>
                <template #first>
                  <b-form-select-option :value="null">-- Please select an option --</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <!--
            <div class="d-flex flex-column pt-4 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Adjunto</h4>
              <p class='w-100' style='font-size: 13px;'>Los datos de facturación aparecen en su factura. La fecha de la
                factura se utiliza en el panel de control e informes.
                Seleccione la fecha que usted espera que se le pague como fecha de vencimiento.</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>-->
            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" dark shadow
                         @click.prevent="$router.push('/helex/Erp/Contabilidad/Ventas/Facturas')">Cancelar
              </vs-button>
              <vs-button size="large" :loading="loading" @click.prevent="ActualizarVentas">Guardar</vs-button>
            </div>
          </div>
        </card>
      </b-col>
    </b-row>

    <!-- Modals -->
    <vs-dialog v-model="ModalClienteVisible" title="Nuevo Cliente">
      <ClienteNuevo :is-external="true" @event-complet="cerrarModalCliente"></ClienteNuevo>
    </vs-dialog>
    <vs-dialog v-model="ModalArticuloVisible" title="Buscar Artículo" :close-on-backdrop="false"
               :close-on-esc="false">
      <template #header>
        <h4 class="not-margin">Articulos</h4>
      </template>
      <div style="width: 500px; min-width: 700px">
        <DataTableLotes :fetch-data="fetchData" :columns="columns" :seleccion-multiple="true" key-seleccion="Sku"
                        @KeySelection="ListaArticulosSeleccionManual = $event" @click.native.stop
                        :fetch-data-all-select="fetchDataSelectAll">
          <template v-slot:PrecioVenta="{ row }">
            {{ formatearNumero(row.PrecioVenta) }}
          </template>
        </DataTableLotes>
      </div>
      <template #footer>
        <div style="display: flex; justify-content: start ">
          <vs-button @click="agregarMultiplesArticulosManual" type="filled" color="primary">Aceptar</vs-button>
          <vs-button @click="ModalArticuloVisible = false" type="filled" color="danger">Cerrar</vs-button>
        </div>
      </template>
    </vs-dialog>
    <vs-dialog v-model="ModalImpuestosVisible" title="Impuestos">
      <template #header>
        <h4 class="not-margin">Impuestos</h4>
      </template>
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>Impuesto</vs-th>
            <vs-th>Porcentaje</vs-th>
            <vs-th>Total ({{ Divisa.Codigo }})</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(impuesto, index) in ListaImpuestos" :key="index">
            <vs-td>{{ impuesto.Descripcion }}</vs-td>
            <vs-td>{{ impuesto.Porcentaje }} %</vs-td>
            <vs-td>{{ formatearNumero(impuesto.total) }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-dialog>
  </b-container>
</template>

<script>
import ClienteNuevo from "@/views/Helexium/Erp/Contabilidad/Ventas/Clientes/Cliente_nuevo.vue";
import Swal from "sweetalert2";
import DataTableLotes from "@/components/Datatable/DataTableLotes.vue";

export default {
  components: {
    DataTableLotes,
    ClienteNuevo
  },
  data() {
    return {
      loading: false,
      escaneando: false,
      codigoBarra: "",
      Divisa: null,
      ModalClienteVisible: false,
      ModalArticuloVisible: false,
      ModalImpuestosVisible: false,
      factura: {
        ClienteId: "",
        articulosSeleccionados: [],
        fechaVencimiento: null,
        MetodoPago: null
      },
      listaClientes: [],
      ListaArticulos: [],
      ListaArticulosSeleccionManual: [],
      EmpresaId: "",
      ListaImpuestos: [],
      ListaMetodosDePagos: [],
      SubTotal: 0,
      Total: 0,
      personaId: null,
      idVenta: "",
      columns: [],
    };
  },
  async mounted() {
    const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
    this.idVenta = this.$route.params.id;
    if (valor) {
      this.EmpresaId = valor.empresa.id;
      this.personaId = valor.id;
    }
    await this.getData();
    const input = document.querySelector("#codigoLector");
    if (input) {
      input.addEventListener("blur", this.verificarFoco);
    }
    document.addEventListener('visibilitychange', this.manejarVisibilidad);
  },
  methods: {
    async fetchData({filtro, orden, cantDatos, paginacion}) {
      if (!this.EmpresaId) {
        return {TotalDatos: 0, data: []};
      }
      let params = {
        filtro: filtro,
        orden: orden,
        cantDatos: cantDatos,
        paginacion: paginacion
      };
      let data = [];
      let total = 0;
      await Promise.all([
        this.$store.dispatch("hl_get", {
          path: `CONTArticulo/ObtenerCantidadInformacionArticulos/${this.EmpresaId}?filtro=${filtro}`,
        }),
        this.$store.dispatch("hl_post", {
          path: 'CONTArticulo/ObtenerInformacionArticulos/' + this.EmpresaId,
          data: params,
        })
      ]).then(([cantidadDatos, Datos]) => {
        if (cantidadDatos.respuesta) total = cantidadDatos.data;
        if (Datos.respuesta) data = Datos.data;
      });
      return {total, data};
    },
    async fetchDataSelectAll(filtro) {
      if (!this.EmpresaId) {return [];}
      let data = [];
      await this.$store.dispatch("hl_get", {
        path: `CONTArticulo/ObtenerSkusMasivos/${this.EmpresaId}?filtro=${filtro}`,
      }).then(res => {
        data = (res.respuesta) ? res.data : []
      })
      return data;
    },
    async getData() {
      try {
        const [Divisa, MetodosPagos, Venta] = await Promise.all([
          this.$store.dispatch('hl_get', {path: `GDivisas/ObtenerDivisaEmpresaVentaId/${this.EmpresaId}`}),
          this.$store.dispatch('hl_get', {path: `GMetodosPagos/ObtenerMetodosPagosEmpresa/${this.EmpresaId}`}),
          this.$store.dispatch('hl_get', {path: `ContVentas/ObtenerVentaId/${this.idVenta}`}),
        ]);

        if (Venta.respuesta) {
          this.factura = Venta.data;
          if (this.factura.fechaVencimiento) {
            this.factura.fechaVencimiento = this.$moment
                .tz(this.factura.fechaVencimiento, "America/Bogota").format("YYYY-MM-DD");
          }
        } else {
          await Swal.fire({
            title: 'Error al obtener la venta',
            text: Venta.mensaje,
            icon: 'danger',
            confirmButtonText: 'Aceptar'
          })
        }

        if (Divisa.respuesta) {
          this.Divisa = Divisa.data;
          const input = document.querySelector("#Total");
          if (input && this.Divisa) {
            input.innerHTML = `Total (${this.Divisa.Codigo})`; // Actualiza el contenido del HTML
          }
          const inputPrecio = document.querySelector("#Precio");
          if (inputPrecio && this.Divisa) {
            inputPrecio.innerHTML = `Precio (${this.Divisa.Codigo})`; // Actualiza el contenido del HTML
          }
        } else {
          this.$vs.notification({
            position: 'top-center',
            title: 'Error Divisa',
            text: Divisa.mensaje,
            color: 'danger'
          });
        }

        if (MetodosPagos.respuesta) {
          this.ListaMetodosDePagos = MetodosPagos.data;
        }
        await this.ObtenerArticulos();
        await this.calcularTotalesAndSubtotales();
      } catch (error) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Error',
          text: 'Ocurrió un error al cargar los datos, ' + error,
          color: 'danger'
        });
      }
    },
    async agregarArticulo(articulo) {
      try {
        const existe = this.factura.articulosSeleccionados.some(item => item.articulo.Sku === articulo);
        if (!existe) {
          this.$store.dispatch('hl_get', {
            path: "CONTArticulo/ObtenerArticuloInfoSku/" + articulo + "/" + this.EmpresaId
          }).then(response => {
            if (response.respuesta) {
              let articuloData = response.data;
              this.factura.articulosSeleccionados.push(
                  {
                    articulo: articuloData.Articulo,
                    cantidad: 1,
                    Impuesto: articuloData.Impuestos
                  }
              );
            } else alert(response.mensaje)
          })
        } else {
          const articuloExistente = this.factura.articulosSeleccionados.find(item => item.articulo.Sku === articulo);
          articuloExistente.cantidad = Number(articuloExistente.cantidad) + 1;
        }

        this.calcularTotalesAndSubtotales();
      } catch (error) {
        console.log(error);
      }
    },
    async agregarMultiplesArticulos(articulos) {
      try {
        let SkuBusqueda = []
        for (const articulo of articulos) {
          const existe = this.factura.articulosSeleccionados.some(item => item.articulo.Sku === articulo);
          if (existe) {
            const articuloExistente = this.factura.articulosSeleccionados.find(item => item.articulo.Sku === articulo);
            articuloExistente.cantidad = Number(articuloExistente.cantidad) + 1;
          } else SkuBusqueda.push(articulo);
        }

        if (SkuBusqueda.length > 0) {
          this.$store.dispatch('hl_post', {
            path: "CONTArticulo/ObtenerArticuloInfoSku/" + this.EmpresaId,
            data: SkuBusqueda
          }).then(response => {
            if (response.respuesta) {
              let DatosAnadir = response.data.map(item => ({
                articulo: item.Articulo,
                cantidad: 1,
                Impuesto: item.Impuestos
              }));
              this.factura.articulosSeleccionados = [...this.factura.articulosSeleccionados, ...DatosAnadir];
            } else {
              alert(response.mensaje)
            }
          })
        }
        this.calcularTotalesAndSubtotales();
      } catch (error) {
        console.log(error);
      }
    },
    async ObtenerArticulos() {
      this.ListaArticulos = []
      let send = {
        filtro: this.search,
        orden: {
          columna: this.columna,
          ascendente: this.ascendente
        },
        cantDatos: this.max,
        paginacion: this.page
      }
      try {
        this.loading = true;
        const res = await this.$store.dispatch('hl_get', {
          path: `CONTArticulo/ObtenerCantidadInformacionArticulos/${this.EmpresaId}?filtro=${this.search}`
        })

        this.totalpagues = Math.ceil(res.data / this.max);
        if (res.data <= this.max) {
          this.page = 1;
        }

        const response = await this.$store.getters.fetchPost({
          path: 'CONTArticulo/ObtenerInformacionArticulos/' + this.EmpresaId,
          data: send
        });
        if (response.ok) {
          const data = await response.json();
          if (data.data.length > 0) {
            this.ListaArticulos = data.data.map((items) => items)
          } else {
            this.ListaArticulos = []
          }

        } else {
          console.error('Error en la solicitud:', response.statusText);
        }
      } catch (error) {
        this.article = []
      } finally {
        this.loading = false;
      }
    },
    async ActualizarVentas() {
      if (this.factura.articulosSeleccionados.length > 0) {
        let data = {
          idUsuario: this.personaId,
          Productos: this.factura.articulosSeleccionados.map(art => {
            return {
              idArticuloVenta: art.articulo.idArticuloVenta ?? null,
              sku: art.articulo.Sku,
              cantidad: art.cantidad
            }
          }),
          idMetodoPago: this.factura.MetodoPago,
          fechaVencimiento: this.factura.fechaVencimiento
        };
        this.loading = true;

        await this.$store.dispatch('hl_post', {
          path: `ContVentas/ActualizarVenta/${this.idVenta}`,
          data: data
        }).then(response => {
          if (response.respuesta) {
            this.$router.push('/helex/Erp/Contabilidad/Ventas/Facturas');
          } else {
            Swal.fire({
              title: 'Error al actualizar la venta',
              text: response.mensaje,
              icon: 'danger',
              confirmButtonText: 'Aceptar'
            })
          }
        })
      } else {
        await Swal.fire({
          title: 'Error al actualizar la venta',
          text: "Debe escoger por lo menos un articulo",
          icon: 'danger',
          confirmButtonText: 'Aceptar'
        })
      }

      this.loading = false;
    },
    calcularTotalesAndSubtotales() {
      this.SubTotal = 0;
      this.Total = 0;
      this.factura.articulosSeleccionados.forEach((item) => {
        const precioVenta = item.articulo.PrecioVenta;
        const cantidad = item.cantidad;

        // Calcular el total sin impuestos
        const subtotal = precioVenta * cantidad;
        this.SubTotal += subtotal;

        // Calcular los impuestos
        const impuestos = item.Impuesto.reduce((acc, impuesto) => {
          return acc + (subtotal * (impuesto.Porcentaje / 100));
        }, 0);

        // Calcular el total con impuestos
        this.Total += subtotal + impuestos;
      })
    },

    // Ajustes
    verificarFoco() {
      const input = document.querySelector("#codigoLector");
      if (this.escaneando && input) input.focus();
    },
    manejarVisibilidad() {
      if (document.hidden && this.escaneando) this.activarEscaneo();
    },
    formatearNumero(numero) {
      try {
        // Validar configuración de divisa
        if (!this.Divisa || !this.Divisa.Simbolo || !this.Divisa.Precision) {
          throw new Error("Configuración de divisa incompleta.");
        }

        // Configurar opciones de formato
        const opciones = {
          minimumFractionDigits: parseInt(this.Divisa.Precision, 10),
          maximumFractionDigits: parseInt(this.Divisa.Precision, 10),
        };

        // Formatear el número sin símbolo
        const formato = new Intl.NumberFormat('es-CO', opciones);
        const numeroFormateado = formato.format(numero);

        // Agregar el símbolo manualmente
        return `${this.Divisa.Simbolo} ${numeroFormateado}`;

      } catch (error) {
        console.error("Error al formatear el número:", error.message);
        return numero; // Devolver el número original en caso de error
      }
    },
    MostrarImpuestosModal(Impuesto, Precio) {
      console.log(Impuesto)
      this.ListaImpuestos = Impuesto.map(item => {
        return {
          ...item, // Copia las propiedades originales del objeto
          total: item.Porcentaje * Precio / 100 // Sobrescribe Descripcion
        };
      });
      this.ModalImpuestosVisible = true;
    },
    procesarCodigoBarra() {
      const codigo = this.codigoBarra.trim();
      if (codigo) this.agregarArticulo(codigo);
      this.codigoBarra = "";
    },
    agregarMultiplesArticulosManual() {
      if (this.ListaArticulosSeleccionManual.length > 0) {
        this.agregarMultiplesArticulos(this.ListaArticulosSeleccionManual)
        this.ListaArticulosSeleccionManual = []
        this.ModalArticuloVisible = false;
      } else alert("Debe seleccionar un articulo");
    },
    activarEscaneo() {
      this.escaneando = !this.escaneando;
      const input = document.querySelector("#codigoLector");
      if (this.escaneando) {
        this.$nextTick(() => {
          if (input) input.focus();
        });
      } else {
        input?.blur();
      }
    },
    cerrarModalCliente(id) {
      this.ModalClienteVisible = false;
      this.factura.ClienteId = id;
      this.getData();
    },
    abriModalProductos() {
      if (this.columns.length === 0) {
        this.columns = [
          {label: "Sku", key: "Sku", sortable: true},
          {label: "Nombre", key: "Nombre", sortable: true},
          {label: `Precio (${this.Divisa.Codigo})`, key: "PrecioVenta", sortable: true},
        ]
      }
      this.ModalArticuloVisible = true;
    }
  },
  watch: {
    search() {
      this.getData();
    },
    max() {
      this.getData();
    },
    page() {
      this.getData();
    },
    columna() {
      this.getData();
    },
    'factura.articulosSeleccionados'() {
      this.calcularTotalesAndSubtotales();
    }
  }
};
</script>