<script>
import Swal from "sweetalert2";

export default {
  props: {
    fileTypes: {
      type: Array,
      default: () => [], // ["image/jpeg", "image/png"], // Tipos MIME por defecto
    },
    loader: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      file: null,
      name: null,
      imageUrl: null,
      typeFile: null,
      isHovered: false,
    };
  },
  computed: {
    backgroundStyle() {
      if (this.imageUrl) {
        return {
          backgroundImage: `url("${this.imageUrl}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
        };
      }
      return {};
    },
    isFile() {
      return this.file ? true : false;
    },
    fileBackground() {
      if (this.file) {
        let backgroundImage = this.imageUrl ? `url("${this.imageUrl}")` : "";
        return {
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        };
      }
      return {};
    },
    extensionOfFileTypes() {
      return this.fileTypes.map((elment) => {
        return elment.substring(elment.lastIndexOf("/") + 1);
      });
    },
  },
  methods: {
    handleFileData(data) {
      this.$emit("handleFileData", data);
    },
    handleMouseOver() {
      // console.log("hover over");
      this.isHovered = true;
    },
    handleMouseLeave() {
      // console.log("hover leave");
      this.isHovered = false;
    },
    restaurar() {
      this.file = null;
      this.imageUrl = null;

      this.$emit('fileRemoved');
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.validateAndProcessFile(selectedFile);
      }
    },
    handleDrop(event) {
      const selectedFile = event.dataTransfer.files[0];
      if (selectedFile) {
        this.validateAndProcessFile(selectedFile);
      }
    },
    validateAndProcessFile(selectedFile) {
      console.log(selectedFile.type);
      if (
        this.fileTypes.includes(selectedFile.type) &&
        selectedFile.name.split(".").length <= 2
      ) {
        this.handleFileData(selectedFile);
        this.file = selectedFile;
        this.typeFile = this.file.name.substring(
          this.file.name.lastIndexOf(".")
        );
        this.name = this.file.name;
        //Coloca de fondo la imagen siempre y cuando sea una imagen
        if (selectedFile.type.startsWith("image/")) {
          this.imageUrl = URL.createObjectURL(selectedFile);
          console.log("selectedFile", selectedFile);
        }
      } else {
        console.error("Tipo de archivo no válido.");

        if (!this.fileTypes.includes(selectedFile.type)) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Tipo de archivo no válido.",
            confirmButtonText: "Aceptar",
          });
        }

        if (selectedFile.name.split(".").length > 2) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "El nombre del archivo no puede contener puntos.",
            confirmButtonText: "Aceptar",
          });
        }

        this.file = null;
        this.imageUrl = null;
      }
      this.$refs.fileInput.value = "";
    },
  },
};
</script>

<template>
  <form @submit.prevent="onUpload">
    <slot name="header"> </slot>
    <input type="file" @change="handleFileChange" ref="fileInput" style="display: none" />

    <!-- remover file -->
    <div v-if="file" class="text-right text-danger pointer mb-2" @click.prevent="restaurar">
      <i class="fas fa-circle-minus"></i>
      Remover
    </div>

    <div class="dropzone" :class="{ 'is-file': isFile }" :style="fileBackground" @click="triggerFileInput"
      @drop.prevent="handleDrop" @dragover.prevent @dragenter.prevent @mouseover="handleMouseOver"
      @mouseleave="handleMouseLeave">
      <div v-if="loader" class="loader"></div>
      <div class="overlay" v-if="imageUrl"></div>
      <div v-if="!file" class="d-flex flex-column align-items-center">
        <i :class="[
          'fas fa-cloud-arrow-up text-white-50',
          isHovered && 'fa-beat',
        ]" style="font-size: 2rem"></i>
        <br />
        Haz clic o arrastra un archivo aquí.
        <!-- De tipo {{ extensionOfFileTypes }} -->
      </div>
      <div v-else class="d-flex flex-column align-items-center" style="z-index: 3">
        <i :class="`fas fa-file-circle-check ${loader && 'fa-bounce'}`" style="font-size: 2rem"></i>
        <br />
        <span class="text-center">
          El documento "
          <span class="text-primary font-weight-bold">{{ name }}</span> " ha
          sido cargado
        </span>
      </div>
    </div>
  </form>
</template>

<style scoped>
form {
  width: 100%;
  min-height: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dropzone {
  width: 100%;
  min-height: 150px;
  height: 80%;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  transition: 0.3s all;

  &:hover {
    background-color: #ececec;
  }

  .dark &:hover {
    background-color: #131416;
  }
}

.is-file {
  background-color: #ececec;

  .dark & {
    background-color: #131416;
  }
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  /* background-color: rgba(27, 75, 141, 0.5); */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
