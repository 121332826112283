<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class=" p-5">
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold" style='font-size: 30px'>Factura #1</h4>
          </template>
          <div class="col-md-8">
            <div class="con-form d-flex flex-column p-3" style='gap: .5rem;'>
              <div class='row p-2 infocolor' style='background-color: var(--gray-300);'>
                <article class='col-12 col-md-6 p-0'>
                  <label class='text-uppercase font-weight-bold m-0'>Helexium sas</label>
                  <div v-for='(info, index) in headerInfo' :key='index' class='d-flex align-items-center'
                       style='gap: .5rem;'>
                    <label class='font-weight-bold m-0'>{{ info.title }}:</label>
                    <p class='m-0'>{{ info.value }}</p>
                  </div>
                </article>
                <article class='col-12 col-md-6 d-flex justify-content-end align-items-center mt-3 mt-md-0 p-0'>
                    <span style='width: 12rem; height: 12rem;'
                          class='border d-flex justify-content-center align-items-center m-auto m-md-0'>QR</span>
                </article>
              </div>
              <div class='row p-2 infocolor' style='gap: .5rem; background-color: var(--gray-300);'>
                <label class='font-weight-bold m-0'>CUFE:</label>
                <p class='m-0 p-0 text-justify'>95738475v745764765934659c4563746d573467564</p>
              </div>
              <div class='row p-2'>
                <div v-for='(info, index) in datosInfo' :key='index'
                     class='d-flex col-12 col-md-6 align-items-center p-0' style='gap: .5rem;'>
                  <label class='font-weight-bold m-0'>{{ info.title }}:</label>
                  <p class='m-0'>{{ info.value }}</p>
                </div>
              </div>
              <div class='row p-2 infocolor' style='background-color: var(--gray-300);'>
                <div class='d-flex col-12 col-md-6 align-items-center p-0' style='gap: .5rem;'>
                  <label class='font-weight-bold m-0'>Adquiriente:</label>
                  <p class='m-0 '> NIT: 7945739456</p>
                </div>
                <div class='d-flex col-12 col-md-6 align-items-center p-0' style='gap: .5rem;'>
                  <label class='font-weight-bold m-0'>Razón Social:</label>
                  <p class='m-0 '>HELEXIUM SAS</p>
                </div>
              </div>
              <div class='row p-2 separator'>
                <div v-for='(info, index) in usuarioInfo' :key='index'
                     class='d-flex col-12 col-md-6 align-items-center p-0' style='gap: .5rem;'>
                  <label class='font-weight-bold m-0'>{{ info.title }}:</label>
                  <p class='m-0'>{{ info.value }}</p>
                </div>
              </div>
              <div class='row p-2 infocolor' style='background-color: var(--gray-300);'>
                <label class='font-weight-bold m-0'>Detalles de Productos</label>
              </div>
              <div class='row overflow-auto separator pb-3' style='max-width: 800px;'>
                <table class='tablefactura' border="1" cellpadding="5" cellspacing="0"
                       style="border-collapse: collapse; width: 100%;font-size: .6rem;">
                  <thead>
                  <tr>
                    <th colspan="6"></th>
                    <th colspan="2">Cargos o Descuentos</th>
                    <th colspan="3">Impuestos</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>Nro</th>
                    <th>Código</th>
                    <th>Descripción</th>
                    <th>U/M</th>
                    <th>Cantidad</th>
                    <th>Precio Unitario</th>
                    <th>Descuento</th>
                    <th>Recargo</th>
                    <th>IVA</th>
                    <th>%IVA</th>
                    <th>INC</th>
                    <th>Total item</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>1</td>
                    <td>DOMINIO</td>
                    <td>DOMINIO Renovar Dominio - helexium.com - 1 Año(s) (21/07/2024 - 20/07/2025) + Gestionar DNS
                      +
                      Redirección de Email
                    </td>
                    <td>UNIDA</td>
                    <td>1,00</td>
                    <td>$44.117,65</td>
                    <td>$0,00</td>
                    <td>$0,00</td>
                    <td>$8.382,35</td>
                    <td>19%</td>
                    <td>$0,00</td>
                    <td>$44.117,65</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colspan="2">Total items</td>
                    <td colspan="10" style='text-align: end;'>1</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class='row p-2 infocolor' style='background-color: var(--gray-300);'>
                <label class='font-weight-bold m-0'>Documentos referenciados</label>
              </div>
              <div class='row overflow-auto separator pb-3' style='max-width: 800px;'>
                <table class='tablefactura' border="1" cellpadding="5" cellspacing="0"
                       style="border-collapse: collapse; width: 100%;font-size: .6rem;">
                  <thead>
                  <tr>
                    <th colspan='6'>Tipo de Documento Referencia</th>
                    <th colspan='4'>Número Referencia</th>
                    <th>Fecha Referencia</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td colspan='6'>Orden de compra</td>
                    <td colspan='4'>301932</td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class='row p-2 infocolor' style='background-color: var(--gray-300);'>
                <p class='font-weight-bold m-0'>Totales</p>
              </div>
              <div class="row">
                <article class='d-flex col-12 col-md-7 align-items-center p-0 mb-3' style='gap: .5rem;'>
                  <label class='font-weight-bold m-0'>Nota:</label>
                  <p class='m-0 '> Las retenciones son a nivel informativo no afecta el total a pagar.</p>
                </article>
                <article class='col-12 col-md-5 p-0' style='gap: .5rem;'>
                  <div v-for='(info, index) in totalInfo' :class='info.border && "separator2"' :key='index'
                       class='d-flex justify-content-between align-items-center p-0' style='gap: .5rem;'>
                    <p class='m-0'>{{ info.title }}:</p>
                    <p :class='info.bold && "h6 font-weight-bold"' class='m-0'>{{ info.value }}</p>
                  </div>
                </article>
              </div>
            </div>
            <div class='row mt-5'>
              <p class='font-weight-bold text-center'>Numero de Autorización: 18764067673092 Rango Autorizado Desde:
                10001 Rango Autorizado Hasta: 50000, Prefijo: FE, Fecha resolución: 2024-03-20, Vigente hasta:
                2025-03-20
                Proveedor Tecnológico: Nodexum S.A.S | NIT: 901285179-0 | Software: e-Misión</p>
            </div>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      idVenta: "",
      descuento: 0,
      headerInfo: [
        {title: "NIT", value: "9058393484-1"},
        {title: "Nombre comercial", value: "Lorem ipsum"},
        {title: "Tipo de Contribuyente", value: "Persona Juridica"},
        {title: "Direccion", value: "CIR 4 70 93 OF 302"},
        {title: "Régimen Contable", value: "Impuestos sobre las ventas - IVA"},
        {title: "Pais", value: "Colombia"},
        {title: "Municipio", value: "Medellín (Antioquia)"},
        {title: "Correo", value: "ventas@latinoanmericahosting.com.co"},
        {title: "Tipo Responsabilidad", value: "R-99-PN (No aplica - Otros)"},
        {title: "Teléfono", value: "3105363434"}
      ],
      datosInfo: [
        {title: "Fecha de Emisión", value: "2021-10-10"},
        {title: "Fecha de Vencimiento", value: "2021-10-10"},
        {title: "Tipo de Operación", value: "Estandar"},
        {title: "Prefijo", value: "FE"},
        {title: "Tipo de Negociación", value: "Contado"},
        {title: "Medio de Pago", value: "Transferencia Débito Bancaria"},
        {title: "Fecha de validación", value: "2024-07-22 17:38:20"},
        {title: "Periodo", value: "-"}
      ],
      usuarioInfo: [
        {title: "Nombre Comercial", value: ""},
        {title: "Dirección", value: "Carrera 7 calle 102"},
        {title: "Número Documento", value: "1342343534"},
        {title: "Pais", value: "Colombia"},
        {title: "Departamento", value: "Bogotá"},
        {title: "Municipio", value: "Bogotá D.C"},
        {title: "Tipo de Contribuyente", value: "Persona Jurídica"},
        {title: "Correo", value: "casargiraldopersonal@gmail.com"},
        {title: "Régimen Contable", value: "Inpuesto sobre las ventas - IVA"},
        {title: "Teléfono", value: "0"},
        {title: "Tipo de responsabilidad", value: "R-99-PN (No aplica - Otros)"}
      ],
      totalInfo: [
        {title: 'Moneda', value: 'COP', 'border': false},
        {title: 'Tasa de Cambio', value: '1', 'border': true},
        {title: 'Subtotal Precio Unitario (=)', value: '44.117,65', 'border': false},
        {title: 'Descuentos Detalle (-)', value: '0,00', 'border': false},
        {title: 'Recargos Detalle (+)', value: '44.117,65', 'border': true},
        {title: 'Subtotal Base Gravable (=)', value: '8.382,35', 'border': true},
        {title: 'Total Impuesto IVA (=)', value: '8.382,35', 'border': false},
        {title: 'Total Impuestos (+)', value: '8.382,35', 'border': false},
        {title: 'Total Mas Impuesto (=)', value: '52.500,00', 'border': true},
        {title: 'Descuento Global (-)', value: '0,00', 'border': false},
        {title: 'Recargo Global (+)', value: '0,00', 'border': true},
        {title: 'Total de la operación (=)', value: '52.500,00', 'border': false, 'bold': true}
      ],
      options: [
        {value: null, text: 'Seleccione un artículo'},
        {
          value: {nombre: 'Artículo 1', descripcion: 'Descripción 1', cantidad: 1, precio: 100, impuesto: 10},
          text: 'Artículo 1'
        },
        {
          value: {nombre: 'Artículo 2', descripcion: 'Descripción 2', cantidad: 2, precio: 200, impuesto: 20},
          text: 'Artículo 2'
        },
        {
          value: {nombre: 'Artículo 3', descripcion: 'Descripción 3', cantidad: 3, precio: 300, impuesto: 30},
          text: 'Artículo 3'
        }
      ],
      EmpresaId: "",
      Cambios: [],
    }
  },
  async mounted() {
    const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
    this.idVenta = this.$route.params.id;
    if (valor) this.EmpresaId = valor.empresa.id;
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        await this.$store.dispatch("hl_get", {
          path: "ContVentas/ObtenerHistorialCambiosVenta/" + this.idVenta
        }).then(response => {
          if (response.respuesta) {
            this.Cambios = response.data;
          } else {
            this.$vs.notification({
              position: 'top-center',
              title: 'Error',
              text: response.mensaje,
              color: 'danger'
            })
          }
        })
      } catch (error) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Error',
          text: error,
          border: 'danger'
        })
      }
    }
  },

}
</script>