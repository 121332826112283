<!--suppress ALL -->
<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class="pb-5">
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold" style='font-size: 30px'>Nuevo Cliente</h4>
          </template>
          <div class="d-flex flex-column px-4 pt-4 pb-2">
            <h4 class='mb-2' style='font-size: large;'>General</h4>
            <p class='w-100' style='font-size: 13px;'>La información de contacto de tus clientes aparecerá en las
              facturas
              y en sus perfiles. También puedes permitir que tus clientes inicien sesión para hacer un seguimiento de
              las facturas que les envías marcando la casilla de abajo.</p>
            <hr style='height: 1px; background: var(--dark);' class='w-100'/>
          </div>
          <form class='px-4' @submit.prevent="saveData">
            <label style='font-size: medium;'>Tipo *</label>
            <div class="row">
              <article class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <vs-button class="button"
                           :class="{ 'active-button': ClienteCreado.ContClientes.TipoContribuyente !== 'PersonaNatural' }"
                           style="width: 100%"
                           v-on:click.prevent="cambiartipoContribuyente('PersonaNatural')">Persona Natural
                </vs-button>
              </article>
              <article class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <vs-button class="button"
                           :class="{ 'active-button': ClienteCreado.ContClientes.TipoContribuyente !== 'PersonaJuridica' }"
                           style="width: 100%"
                           v-on:click.prevent="cambiartipoContribuyente('PersonaJuridica')">Persona Juridica
                </vs-button>
              </article>
            </div>
            <div class='row pt-4 align-items-center'>
              <article class="col-12 col-md-6 pb-3 pb-md-0">
                <label>Razon Social *</label>
                <b-form-input name="RazonSocial" aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese la razon social" v-model="ClienteCreado.ContClientes.RazonSocial"
                              required trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 pb-3 pb-md-0">
                <label>Nombre Comercial *</label>
                <b-form-input name="NombreComercial" aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Nombre" v-model="ClienteCreado.ContClientes.NombreComercial"
                              required trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 pb-3 pb-md-0 pt-3">
                <label>NIT *</label>
                <div style="display: flex;">
                  <b-form-select class="col-md-2" style="border-radius: 5px 0px 0px 5px !important;"
                                 v-model="ClienteCreado.ContClientes.TipoIdentificacion" @change="SelectTipoDocumento"
                                 placeholder="Tipo de documento" :options="listaTipoDocumentos" required value="null">
                    <template #first>
                      <b-form-select-option :value="null" disabled>Tipo de documento</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-input style="border-radius: 0px 5px 5px 0px" name="NIT" class="col-md-10"
                                aria-describedby="input-live-help input-live-feedback"
                                placeholder="Ingrese el NIT" v-model="ClienteCreado.ContClientes.NIT"
                                required trim></b-form-input>
                </div>
              </article>
              <article class="col-12 col-md-6 pb-3 pb-md-0 pt-3">
                <label>Teléfono</label>
                <b-form-input name="Telefono" aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Teléfono" v-model="ClienteCreado.ContClientes.Telefono"
                              trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 pb-3 pb-md-0 pt-3">
                <label>Celular</label>
                <b-form-input name="Celular" aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Celular" v-model="ClienteCreado.ContClientes.Celular"
                              trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 pb-3 pb-md-0 pt-3">
                <label>Página web</label>
                <b-form-input name="PaginaWeb" aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Página web" v-model="ClienteCreado.ContClientes.PaginaWeb"
                              trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 pb-3 pb-md-0 pt-3">
                <label>Correo electrónico *</label>
                <b-form-input name="Correo" v-model="ClienteCreado.ContClientes.Correo"
                              aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Correo electrónico" trim required></b-form-input>
              </article>
            </div>
            <div class='d-flex flex-column pt-5 pb-2'>
              <h4 class='mb-2' style='font-size: large;'>Dirección</h4>
              <p class='w-100' style='font-size: 13px;'>La dirección es obligatoria para las facturas, por lo que debe
                agregar los detalles de la dirección de facturación de su cliente..</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <div class='d-flex flex-column' style='gap: 1.5rem;'>
              <div class="row">
                <article class="col-12 col-md-6">
                  <label>País</label>
                  <b-form-select name="PaisID" :text-field="'descripcion'" :value-field="'id'"
                                 v-model="ClienteCreado.idPais"
                                 @change="SelectDepartamentos" :options="Listapaises" required value="null">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Por favor seleccione un país --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </article>
                <article class="col-12 col-md-6 pb-3 pb-md-0">
                  <label>Departamento</label>
                  <b-form-select name="DepartamentoID" :text-field="'descripcion'" :value-field="'id'"
                                 :disabled="ListaDepartamentos==null" v-model="ClienteCreado.idDepartamento"
                                 @change="SelectMunicipios" :options="ListaDepartamentos" required value="null">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Por favor seleccione una ciudad --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </article>
              </div>
              <div class="row">
                <article class="col-12 col-md-6 pb-3 pb-md-0">
                  <label>Municipio</label>
                  <b-form-select name="MunicipioID" :text-field="'descripcion'" :value-field="'id'"
                                 :disabled="ListaMunicipios==null" v-model="ClienteCreado.idMunicipio"
                                 :options="ListaMunicipios" required value="null">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Por favor seleccione una ciudad --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </article>
                <article class="col-12 col-md-6">
                  <label>Código postal</label>
                  <b-form-input name="CodigoPostal" aria-describedby="input-live-help input-live-feedback"
                                v-model="ClienteCreado.ContClientes.CodigoPostal"
                                placeholder="Ingrese Código postal" trim></b-form-input>
                </article>
              </div>
              <div class="row">
                <article class="col-12">
                  <label>Dirección</label>
                  <b-form-textarea name="Direccion" v-model="ClienteCreado.ContClientes.Direccion" rows="4" class='p-3'
                                   placeholder="Descripción"></b-form-textarea>
                </article>
              </div>
            </div>
            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" dark shadow @click.prevent="closeComponent ">Cancelar</vs-button>
              <vs-button type='submit' size="large">Guardar</vs-button>
            </div>
          </form>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    isExternal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      Listapaises: [],
      ListaDepartamentos: [],
      ListaMunicipios: [],
      listaTipoDocumentos: ["RC", "TI", "CC", "CE", "PP", "CD", "PEP", "DIE", "NIT"],
      TipoContribuyente: "PersonaNatural",
      ClienteCreado: {
        ContClientes: {
          TipoIdentificacion: null,
          NIT: null,
          RazonSocial: null,
          NombreComercial: null,
          PaginaWeb: null,
          TipoContribuyente: null,
          Correo: null,
          Telefono: null,
          Celular: null,
          CodigoPostal: null,
          Direccion: null
        },
        idPersona: null,
        idPais: null,
        idDepartamento: null,
        idMunicipio: null
      }
    }
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        var id = JSON.parse(localStorage.getItem("credenciales"))
        this.ClienteCreado.idPersona = id.personaId;
        this.ClienteCreado.ContClientes.TipoContribuyente = "PersonaNatural";
        this.$store.dispatch("hl_post", {
          path: "Pais/List"
        }).then(response => {
          if (response.length > 0) {
            this.Listapaises = response;
          }
        })
      } catch (error) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Error',
          text: error,
          border: 'danger'
        })
      }
    },
    async saveData() {
      try {
        await this.$store.dispatch("hl_post", {
          path: "ContComprador/CrearComprador",
          data: this.ClienteCreado
        }).then(res => {
          if (res.respuesta) {
            if (this.isExternal) this.$emit('event-complet', res.data);
            else this.$router.push('Clientes');
          } else {
            this.$vs.notification({
              position: 'top-center',
              title: 'Error',
              text: res["mensaje"],
              color: 'danger'
            })
          }
        })
      } catch (error) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Error',
          text: error,
          border: 'danger'
        })
      }
    },
    cambiartipoContribuyente(dato) {
      if (dato == "PersonaJuridica")  this.ClienteCreado.ContClientes.TipoIdentificacion = "NIT";
      else this.ClienteCreado.ContClientes.TipoIdentificacion = "CC";
      this.ClienteCreado.ContClientes.TipoContribuyente = dato;
    },
    async SelectDepartamentos(id) {
      try {
        this.ListaDepartamentos = [];
        this.$store.dispatch("hl_get", {
          path: "DepartamentoPais/ListByPais/" + id
        }).then(response => {
          if (response.length > 0) {
            this.ListaDepartamentos = response;
          }
        })
      } catch (error) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Error',
          text: error,
          border: 'danger'
        })
      }
    },
    async SelectMunicipios(id) {
      try {
        this.ListaMunicipios = [];
        this.$store.dispatch("hl_get", {
          path: "Municipio/ListByDepartamento/" + id
        }).then(response => {
          if (response.length > 0) {
            this.ListaMunicipios = response;
          }
        })
      } catch (error) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Error',
          text: error,
          border: 'danger'
        })
      }
    },
    closeComponent() {
      if (this.isExternal) this.$emit('event-complet', "");
      else this.$router.push('Clientes');
    },
    SelectTipoDocumento() {
      if (this.ClienteCreado.ContClientes.TipoIdentificacion == "NIT"){
        this.ClienteCreado.ContClientes.TipoContribuyente = "PersonaJuridica";
      }
      else{
        this.ClienteCreado.ContClientes.TipoContribuyente = "PersonaNatural";
      }

    }
  }
}
</script>

<style scoped>
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 0 48px 16px;
}

.wrapper-button {
  align-self: flex-end;
  padding: 0 48px 16px;
}

.active-button {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
}

.buttonDefault {
  border-radius: 0 !important;
  color: black !important;
  background-color: #bfbfbf;
}

.buttonDefaultActive {
  color: black !important;
  background-color: #57ff14;
}

.buttonDefaultFalse {
  color: white !important;
  background-color: #ff1818;
}
</style>