<template>
  <div
    class="mini-chat bg-card p-2 border rounded mx-auto"
    style="height: auto; max-height: 50vh; max-width: 700px"
  >
    <!-- Header -->

    <!-- Chat Messages -->
    <div
      class="chat-messages p-2 overflow-auto"
      style="
        height: auto;
        min-height: 10vh;
        max-height: 30vh;
        border: 1px solid var(--body-bg);
        border-radius: 5px;
      "
    >
      <p v-if="messages.length === 0" class="text-center">
        No hay comentarios aún.
      </p>
      <div
        v-for="(message, index) in messages"
        :key="index"
        class="d-flex my-3"
        :class="{
          'flex-row-reverse': message.user === 'Tú',
          'flex-row': message.user !== 'Tú',
        }"
      >
        <vs-avatar
          circle
          size="30"
          primary
          :danger="message.user === ''"
          class="mt-3"
        >
          <template v-show="message.user !== ''" #text>
            {{ message.user }}
          </template>
          <i
            v-show="message.user === ''"
            class="fas fa-user"
            style="font-size: 0.7rem"
          ></i>
        </vs-avatar>

        <div class="d-flex flex-column mx-2">
          <small
            :class="{
              'text-right': message.user === 'Tú',
            }"
          >
            {{ message.fecha | moment("from", "now") }}
          </small>
          <div
            class="chat-text bg-dark"
            :class="{
              right: message.user === 'Tú',
            }"
          >
            {{ message.text }}
          </div>
        </div>
      </div>
    </div>

    <!-- Input Area -->
    <div class="chat-input mt-2">
      <form class="d-flex align-items-center" @submit.prevent="sendMessage">
        <b-form-textarea
          v-model="newMessage"
          placeholder="Escribe un comentario..."
          @keyup.enter="sendMessage"
          class="mb-2 input-chat"
          rows="1"
          max-rows="3"
          required
        ></b-form-textarea>
        <vs-button
          style="
            max-height: 3rem;
            width: 3.2rem;
            height: 3rem;
            border-radius: 100% !important;
          "
          class="mt-0"
          type="submit"
          primary
          icon
          ><i class="fa-solid fa-paper-plane"></i
        ></vs-button>
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: {
    dataSendCommentFile: {
      type: Object,
      required: false,
      default: () => ({
        personaId: "",
        archivoId: "",
        // observacion: "",
        // fechaObservacion: "",
      }),
    },
    commentsFiles: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      messages: [],
      newMessage: "",
    };
  },
  mounted() {
    this.personaAct = JSON.parse(localStorage.getItem("setPersonaAct"));
    console.log(this.personaAct.id);

    this.messages = this.commentsFiles.map((comment) => ({
      user: comment.PersonaId.toLowerCase() == this.personaAct.id ? "Tú" : "",
      text: comment.Observacion,
      fecha: comment.FechaObservacion,
    }));

    console.log(this.messages);

    this.scrollToBottom();
  },
  methods: {
    async sendMessage() {
      if (this.newMessage.trim() !== "") {
        try {
          // this.$isLoading(true);

          let data = {
            ...this.dataSendCommentFile,
            observacion: this.newMessage,
            fechaObservacion: this.$moment
              .tz(new Date(), "America/Bogota")
              .format("YYYY-MM-DDTHH:mm:ss"),
          };
          const res = await this.$store.dispatch("hl_post", {
            path: "CDC/AddObservacion",
            data,
          });

          console.log(res);
          if (res.id) {
            // Swal.fire("Listo!", "Cambios guardados correctamente.", "success");

            // Agregar el nuevo mensaje al chat
            this.messages.push({
              user: "Tú",
              text: this.newMessage,
              fecha: this.$moment
                .tz(new Date(), "America/Bogota")
                .format("YYYY-MM-DDTHH:mm:ss"),
            });

            const newComment = {
              ObservacionId: res.id,
              PersonaId: this.dataSendCommentFile.personaId,
              Observacion: this.newMessage,
              FechaObservacion: data.fechaObservacion,
            };

            this.commentsFiles.push(newComment);

            this.messages = this.commentsFiles.map((comment) => ({
              user:
                comment.PersonaId.toLowerCase() == this.personaAct.id
                  ? "Tú"
                  : "",
              text: comment.Observacion,
              fecha: comment.FechaObservacion,
            }));
          }
          this.scrollToBottom();
          // this.$isLoading(false);
        } catch (error) {
          // this.$isLoading(false);
          console.error("Error al guardar cambios:", error);
          Swal.fire("Error!", "No se pudo agregar el comentario.", "error");
        }
        this.newMessage = "";
        this.scrollToBottom();
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatMessages = this.$el.querySelector(".chat-messages");
        chatMessages.scrollTop = chatMessages.scrollHeight;
      });
    },
  },
};
</script>

<style scoped>
.input-chat {
  border-radius: 2rem;
  overflow: auto !important;
  padding: 0.4rem 1rem;
}
.mini-chat {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.chat-messages {
  white-space: normal;
  overflow-wrap: break-word;
}

.chat-text {
  white-space: normal;
  overflow-wrap: break-word;
  padding: 0.7rem 1rem;
  border-radius: 0 1rem 1rem 1rem;
  &.right {
    border-radius: 1rem 0 1rem 1rem;
  }
}
</style>
