<template>
  <HXContentCard title="Proyección metas mensuales" :defaultToBack="true">
    <div class="d-flex p-4" style="gap: 1rem">
      <div v-for="item in topValues" :key="item.name" class="py-2 px-4 bg-dark rounded">
        <span>{{ item.label }}</span>
        <br />
        <h3 class="font-weight-bold text-white-50">
          {{ formatValue(item.name, currentConfig[item.name]) }}{{ item?.type }}
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 px-5">
        <label for="filterDate" class="form-label"><i class="fas fa-calendar-days"></i> Filtrar por periodo:</label>
        <input id="date" type="month" class="form-control" v-model="currentFilterDate" @input="handleFilterDateInput" />
      </div>
    </div>

    <!-- tabla -->
    <div class="row m-4">
      <div class="col-2 fixed-column">
        <div class="row">
          <div class="col-12 bg-primary py-2 text-center font-weight-bold c-border">
            CSO
          </div>
          <div class="col-12 py-2 text-center font-weight-bold border-bottom">
            TOTAL
          </div>
          <div v-for="zona in zonas" :key="zona.name" class="col-12 p-0">
            <div class="text-center font-weight-bold py-1 border-bottom">
              {{ zona.name }}
            </div>
            <div class="row">
              <div v-for="ubicacion in zona.ubicaciones" :key="ubicacion.name" class="col-12 py-1">
                {{ ubicacion.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col content-base-metas p-0">
        <div class="w-100" v-for="(column, index) in columns" :key="index">
          <div class="border bg-dark p-2 text-center font-weight-bold text-capitalize">
            {{ column }}
          </div>
          <div class="py-2 text-center font-weight-bold border-bottom">
            {{ totalGlobal(column) | currency }}
          </div>
          <div v-for="zona in zonas" :key="zona.name">
            <div class="text-center font-weight-bold py-1 border-bottom w-100">
              {{ totalZona(zona.name, column) | currency }}
            </div>
            <div v-for="ubicacion in zona.ubicaciones" :key="ubicacion.name" class="py-1 text-center">
              {{ ubicacion[column] | currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </HXContentCard>
</template>

<script>
export default {
  data() {
    return {
      currentConfig: {},
      currentFilterDate: null,
      zonas: [],
      columns: [],
      topValues: [
        {
          label: "Primer abono",
          name: "porcentajePrimerAbono",
          type: "%",
        },
        {
          label: "Segundo abono",
          name: "porcentajeSegundoAbono",
          type: "%",
        },
        {
          label: "Dias habiles",
          name: "diasHabiles",
        },
        {
          label: "Eficacia",
          name: "metaGeneralV1",
        },
      ],
    };
  },
  async mounted() {
    await this.getUltimaConfiguracion();
    await this.getBaseMetas();
  },

  methods: {
    formatValue(name, value) {
      if (name === "metaGeneralV1") {
        return this.$options.filters.currency(value || 0);
      }
      return value || 0;
    },
    totalZona(zona, column) {
      return this.zonas
        .find((z) => z.name === zona)
        .ubicaciones.reduce((acc, item) => acc + item[column], 0);
    },
    totalGlobal(column) {
      return this.zonas.reduce((totalZonas, zona) => {
        const totalUbicaciones = zona.ubicaciones.reduce(
          (total, ubicacion) => total + ubicacion[column],
          0
        );
        return totalZonas + totalUbicaciones;
      }, 0);
    },

    async handleFilterDateInput() {
      const [anio, mes] = this.currentFilterDate.split("-");
      await this.getBaseMetas(anio, mes);
      await this.getUltimaConfiguracion(anio, mes);
    },
    async getBaseMetas(anio = null, mes = null) {
      this.$isLoading(true);
      try {
        let res = await this.$store.dispatch("denti_get", {
          path:
            anio && mes
              ? "BaseMetasMensuales/UltimaBaseMetas/" + anio + "/" + mes
              : "BaseMetasMensuales/UltimaBaseMetas",
        });
        console.log("ultima basemetas", res);
        if (res && res.length) {
          this.ultimaBaseMetas = res;
          // Mapear los datos en el formato de zonas y ubicaciones
          const zonas = res.reduce((acc, item) => {
            let currentZona = acc.find((z) => z.name === item.zona);
            if (!currentZona) {
              currentZona = { name: item.zona, ubicaciones: [] };
              acc.push(currentZona);
            }

            // eslint-disable-next-line no-unused-vars
            const { id, mes, anio, zona, ubicacion, ...rest } = item;

            currentZona.ubicaciones.push({
              name: item.ubicacion,
              ...rest,
            });
            return acc;
          }, []);

          console.log("zonas", zonas);

          // eslint-disable-next-line no-unused-vars
          const { name, ...rest } = zonas[0].ubicaciones[0];

          this.columns = Object.keys(rest);

          console.log(this.columns);
          // Asignar al formulario
          this.currentFilterDate = String(res[0].anio) + "-" + String(res[0].mes).padStart(2, "0");
          this.zonas = zonas;
        } else {
          this.zonas = [];
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    async getUltimaConfiguracion(anio = null, mes = null) {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("denti_get", {
          path:
            anio && mes
              ? "BaseMetasMensuales/UltimaConfiguracion/" + anio + "/" + mes
              : "BaseMetasMensuales/UltimaConfiguracion",
        });
        console.log("ultima configuracion", res);

        if (res && res.id) {
          this.currentConfig = res;
        }
        this.$isLoading(false);
      } catch (error) {
        console.log("error", error);
        this.currentConfig = {};
        this.$isLoading(false);
      }
    },
  },
  filters: {
    currency(value) {
      if (!value) return "$ 0";
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0, // No mostrar decimales
        maximumFractionDigits: 0, // Asegurar que no haya decimales
      }).format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-border {
  border: 1px solid #085ec5;
}

.fixed-column {
  min-width: 200px;
}

.content-base-metas {
  overflow-x: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>
