<script>
import Swal from "sweetalert2";

export default {
  props: {
    isEditar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      waiting: false,
    };
  },
  computed: {
    empresaId() {
      return this.$route.params.id;
    },
  },
  async mounted() {
    const response = await this.$store.getters.fetchGet({
      path: `Empresa/GetEmpresaById/${this.empresaId}`,
    });

    const data = await response.json();

    Object.keys(data).forEach((key) => {
      const input = this.$el.querySelector(`[name="${key}"]`);
      if (input) {
        input.value = data[key];
      }
    });
  },
  methods: {
    saveData(event) {
      const fields = Object.fromEntries(new FormData(event.target).entries());

      const data = {};
      Object.keys(fields).forEach((key) => {
        if (fields[key]) {
          data[key] = fields[key];
        }
      });

      data.UsarAportesEnLinea = true
      
      this.waiting = true;
      this.$store.getters
        .fetchPut({
          path: `Empresa/UpdateEmpresa2024/${this.empresaId}`,
          data,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("No se ha guardado las credenciales");
        })
        .then((result) => {
          console.log("result", result);
          this.savedSuccess();
        })
        .catch(() => {
          this.savedError();
        })
        .finally(() => {
          this.waiting = false;
        });
    },

    savedSuccess() {
      Swal.fire({
        title: "Guardada",
        text: "Se ha guardado las credenciales de aportes en linea",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    },

    savedError() {
      Swal.fire({
        title: "Error",
        text: "No se ha guardado las credenciales de aportes en linea",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    },
  },
};
</script>

<template>
  <div>
    <h4 class="px-4 py-4"><b>Credenciales aportes en linea</b></h4>

    <form class="form" @submit.prevent="saveData">
      <div class="wrapper-fields">
        <div class="wrapper-input" title="Usuario">
          <label for="userAportesEnLinea">Usuario</label>
          <b-form-input
            id="userAportesEnLinea"
            name="userAportesEnLinea"
            required
          ></b-form-input>
        </div>

        <div class="wrapper-input" title="Nombre del contacto">
          <label for="passwordAportesEnLinea">Contraseña</label>
          <b-form-input
            id="passwordAportesEnLinea"
            name="passwordAportesEnLinea"
          ></b-form-input>
        </div>
      </div>

      <vs-button class="button" :loading="waiting">Guardar</vs-button>
    </form>
  </div>
</template>

<style scoped>
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form {
  padding: 0 48px 16px;
  display: flex;
  flex-direction: column;
}

.form .button {
  align-self: flex-end;
}
</style>
