<script>
  import Swal from "sweetalert2";

  export default {
    name: "NominaAjustePersona",
    props: {
      liquidacionId: {},
      liquidacionPersonaId: {},
    },
    data() {
      return {
        cuerpoDeConsulta: undefined,
        resultConsultaAportesEnLinea: undefined,
        liquidacionDeAjuste: undefined,
        responseLiquidacionDeAjuste: undefined,
        waitingAportes: false,
        persona: undefined,
        apostesEnLinea: undefined,
      };
    },
    async mounted() {
      const path = `LiquidadorNomina/${this.liquidacionPersonaId}`;
      const response = await this.$store.getters.fetchGet({ path });
      const { body, persona } = await response;
      this.cuerpoDeConsulta = body;
      this.persona = persona;

      const setPersona = JSON.parse(localStorage.getItem("setPersonaAct"));
      this.persona = setPersona;

      await this.consultarPersonaEnAportes();
      await this.responseRecalcularLiquidacionDelUsuario();
    },
    methods: {
      async consultarPersonaEnAportes() {
        const token = await this.$store.getters.getTokenAportes();
        const response = await fetch(`${this.$store.state.APIAportes}/NominaElectronica/ConsultaEstado`, {
          method: "POST",
          headers: {
            accept: "application/json",
            token: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.cuerpoDeConsulta),
        });
        const result = await response.json();
        this.resultConsultaAportesEnLinea = result;
      },

      async responseRecalcularLiquidacionDelUsuario() {
        const response = await this.$store.getters.fetchPost({ path: `NominaLiquidacion/RecalcularNominaLiquidacionPersona/${this.liquidacionPersonaId}/${this.liquidacionId}` });
        await response.json();

        await this.obtenerCuerPoLiquidacion();
      },

      async obtenerCuerPoLiquidacion() {
        const responseLiquidacion = await this.$store.getters.fetchGet({
          path: `LiquidadorNomina/GetLiquidacion/${this.liquidacionId}/LiquidacionPersonaId/${this.liquidacionPersonaId}`,
        });
        const responseLiquidacionjson = await responseLiquidacion.json();
        const { liquidacionAjuste } = responseLiquidacionjson;

        if (this.persona.empresa.usarAportesEnLinea) {
          liquidacionAjuste.trabajador[0].predecesor = {
            cune: null,
          };

          // Si se mejora el backend esto se podría borrar
          // tipoDeXMl se hace automático en el backend debe ser 103 ajuste
          // actualmente el cune no se guarda en ninguneado
          liquidacionAjuste.trabajador[0].tipoNota = "1"; // 1 remplazar, 2 eliminar
          liquidacionAjuste.trabajador[0].predecesor = {
            cune: this.resultConsultaAportesEnLinea.trabajador[0].cune,
            fechaGen: null,
            numero: null,
          };
        }

        this.responseLiquidacionDeAjuste = responseLiquidacionjson;
        this.liquidacionDeAjuste = liquidacionAjuste;
      },

      async enviarLiquidacionDeAjuste() {
        if (this.controladorDelEventoDeEnvio) return;

        const resultSwal = await Swal.fire({
          icon: "warning",
          text: "Desea usted hacer enviar la nomina de ajuste",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "enviar",
          cancelButtonText: "Cancelar",
        });

        if (resultSwal.isDismissed) return;

        if (this.persona.empresa.usarAportesEnLinea) {
          console.log("Estas usando aportes en linea como Proveedor de Nomina electronica");

          this.waitingAportes = true;
          try {
            const token = await this.$store.getters.getTokenAportes();

            const responseLiquidacion = await fetch(`${this.$store.state.APIAportes}/NominaElectronica/ProcesarNomina`, {
              method: "POST",
              headers: {
                accept: "application/json",
                token: token,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(this.liquidacionDeAjuste),
            });
            const resultLiquidacionAjuste = await responseLiquidacion.json();

            console.log("resultado ajuste", resultLiquidacionAjuste);

            if (!resultLiquidacionAjuste.idTrack) {
              throw new Error(resultLiquidacionAjuste.descripcion.join("\n"));
            }

            await this.$store.getters.fetchPost({
              path: `LiquidadorNomina/SaveResultLiquidacionAjusteIndividual/${this.liquidacionPersonaId}`,
              data: {
                idTrack: resultLiquidacionAjuste.idTrack,
                message: `Ajuste: ${resultLiquidacionAjuste.descripcion.join("\n")}`,
                estado: "Pendiente",
              },
            });

            Swal.fire({
              title: "Procesando nómina",
              text: resultLiquidacionAjuste.descripcion.join("\n"),
              icon: "success",
              confirmButtonText: "Ok",
            });
          } catch (error) {
            Swal.fire({
              title: "Error",
              text: error.message,
              icon: "error",
              confirmButtonText: "Ok",
            });
          }

          this.waitingAportes = false;
        } else if (this.persona.empresa.usarAportesEnLinea === false) {
          this.waitingAportes = true;
          const { liquidacionAjuste } = this.responseLiquidacionDeAjuste;
          liquidacionAjuste.trabajador.forEach(trabajador => {
            delete trabajador.id

            trabajador.tipoNota = "1"; // 1 remplazar, 2 eliminar
          });
          const response = await this.$store.dispatch('hl_post_xml', {
            path: 'NominaIndividual/NominaIndividualDeAjuste',
            data: this.responseLiquidacionDeAjuste
          })

          console.log("Respuesta completa: ", response);
          console.log("Estas usando a Helexium como Proveedor de Nomina electronica");
          this.waitingAportes = false;
        }

        Swal.fire({
          title: "Error",
          text: "No se ha podido enviar la liquidación de ajuste",
          icon: "error",
          confirmButtonText: "Ok",
        });

      },
    },

    computed: {
      controladorDelEventoDeEnvio() {
        return !this.resultConsultaAportesEnLinea?.trabajador[0].cune || !this.liquidacionDeAjuste
      },
    },
  };
</script>

<template>
  <div class="container-fluid">
    <b-card>
      <div>
        <div>
          <h3>Proceso de liquidación de nómina ajuste</h3>
          <p>Le informamos que está a punto de iniciar el proceso de ajuste de nómina, verifica la información del
            usuario antes de continuar</p>

          <div v-if="persona" class="border rounded p-3 border-white">
            <h4 class="m-0">{{ persona.nombres }} {{ persona.apellidos }}</h4>
            <!-- <h4 class="ml-0 mt-2">
              {{ persona.numeroDocumento }}
            </h4 > -->
          </div>


          <vs-button :loading="waitingAportes" @click="enviarLiquidacionDeAjuste"
            :disabled="controladorDelEventoDeEnvio || waitingAportes" style="min-width: 200px; width: 10vw" warn>
            <span v-show="!controladorDelEventoDeEnvio">
              Realizar liquidacion de ajuste
            </span>
            <span v-show="controladorDelEventoDeEnvio">
              <i class="fa fa-spinner fa-spin"></i>
            </span>
          </vs-button>
          <!-- <pre>
            {{ liquidacionDeAjuste }}
          </pre> -->
        </div>
        <!-- <div>
          <pre class="text-white">
            {{ cuerpoDeConsulta }}
          </pre>
          <pre class="text-white">
            {{ resultConsultaAportesEnLinea }}
          </pre>
        </div> -->
      </div>
    </b-card>
  </div>
</template>
