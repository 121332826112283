<!--suppress ALL -->
<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class="pb-5">
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold" style='font-size: 30px'>Editar Cliente</h4>
          </template>
          <div class="d-flex flex-column px-4 pt-4 pb-2">
            <h4 class='mb-2' style='font-size: large;'>General</h4>
            <p class='w-100' style='font-size: 13px;'>La información de contacto de tus clientes aparecerá en las
              facturas
              y en sus perfiles. También puedes permitir que tus clientes inicien sesión para hacer un seguimiento de
              las facturas que les envías marcando la casilla de abajo.</p>
            <hr style='height: 1px; background: var(--dark);' class='w-100'/>
          </div>
          <form class='px-4' @submit.prevent="UpdatCliete" style='height: auto;'>
            <label style='font-size: medium;'>Tipo *</label>
            <div class="row">
              <article class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <vs-button class="button"
                           :class="{ 'active-button': ClienteEditar.ContClientes.TipoContribuyente !== 'PersonaNatural' }"
                           style="width: 100%"
                           v-on:click.prevent="cambiartipoContribuyente('PersonaNatural')">Persona Natural
                </vs-button>
              </article>
              <article class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <vs-button class="button"
                           :class="{ 'active-button': ClienteEditar.ContClientes.TipoContribuyente !== 'PersonaJuridica' }"
                           style="width: 100%"
                           v-on:click.prevent="cambiartipoContribuyente('PersonaJuridica')">Persona Juridica
                </vs-button>
              </article>
            </div>
            <div class='row pt-4 align-items-center'>
              <article class="col-12 col-md-6 pb-3 pb-md-0">
                <label>Razon Social *</label>
                <b-form-input name="RazonSocial" aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese la razon social" v-model="ClienteEditar.ContClientes.RazonSocial"
                              required trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 pb-3 pb-md-0">
                <label>Nombre Comercial *</label>
                <b-form-input name="NombreComercial" aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Nombre" v-model="ClienteEditar.ContClientes.NombreComercial"
                              required trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 pb-3 pb-md-0 pt-3">
                <label>NIT *</label>
                <div style="display: flex;">
                  <b-form-select class="col-md-2" style="border-radius: 5px 0px 0px 5px !important;"
                                 v-model="ClienteEditar.ContClientes.TipoIdentificacion" @change="SelectTipoDocumento"
                                 placeholder="Tipo de documento" :options="listaTipoDocumentos" required value="null">
                    <template #first>
                      <b-form-select-option :value="null" disabled>Tipo de documento</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-input style="border-radius: 0px 5px 5px 0px" name="NIT" class="col-md-10" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Ingrese el NIT" v-model="ClienteEditar.ContClientes.NIT"
                                required trim></b-form-input>
                </div>
              </article>
              <article class="col-12 col-md-6 pb-3 pb-md-0 pt-3">
                <label>Teléfono</label>
                <b-form-input name="Telefono" aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Teléfono" v-model="ClienteEditar.ContClientes.Telefono"
                              trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 pb-3 pb-md-0 pt-3">
                <label>Celular</label>
                <b-form-input name="Celular" aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Celular" v-model="ClienteEditar.ContClientes.Celular"
                              trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 pt-3">
                <label>Página web</label>
                <b-form-input name="PaginaWeb" aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Página web" v-model="ClienteEditar.ContClientes.PaginaWeb"
                              trim></b-form-input>
              </article>
              <article class="col-12 col-md-6 pt-3">
                <label>Correo electrónico</label>
                <b-form-input name="Correo" v-model="ClienteEditar.ContClientes.Correo"
                              aria-describedby="input-live-help input-live-feedback" required
                              placeholder="Ingrese Correo electrónico" trim></b-form-input>
              </article>
            </div>
            <div class='d-flex flex-column pt-5 pb-2'>
              <h4 class='mb-2' style='font-size: large;'>Dirección</h4>
              <p class='w-100' style='font-size: 13px;'>La dirección es obligatoria para las facturas, por lo que debe
                agregar los detalles de la dirección de facturación de su cliente..</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <div class='row'>
              <article class="col-12 col-md-6">
                <label>País</label>
                <b-form-select name="PaisID" :text-field="'descripcion'" :value-field="'id'"
                               v-model="ClienteEditar.idPais"
                               @change="SelectDepartamentos" :options="Listapaises" required value="null">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Por favor seleccione un país --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </article>
              <article class="col-12 col-md-6">
                <label>Departamento</label>
                <b-form-select name="DepartamentoID" :text-field="'descripcion'" :value-field="'id'"
                               :disabled="ListaDepartamentos==null" v-model="ClienteEditar.idDepartamento"
                               @change="SelectMunicipios" :options="ListaDepartamentos" required value="null">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Por favor seleccione una ciudad --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </article>
            </div>
            <div class="row pt-4">
              <article class="col-12 col-md-6 pb-3 pb-md-0">
                <label>Municipio</label>
                <b-form-select name="MunicipioID" :text-field="'descripcion'" :value-field="'id'"
                               :disabled="ListaMunicipios==null" v-model="ClienteEditar.idMunicipio"
                               :options="ListaMunicipios" required value="null">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Por favor seleccione una ciudad --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </article>
              <article class="col-12 col-md-6">
                <label>Código postal</label>
                <b-form-input name="CodigoPostal" aria-describedby="input-live-help input-live-feedback"
                              v-model="ClienteEditar.ContClientes.CodigoPostal"
                              placeholder="Ingrese Código postal" trim></b-form-input>
              </article>
            </div>
            <div class="row pt-4">
              <article class="col-12">
                <label>Dirección</label>
                <b-form-textarea name="Direccion" rows="4" class='p-3'
                                 v-model="ClienteEditar.ContClientes.Direccion"
                                 placeholder="Descripción"></b-form-textarea>
              </article>
            </div>
            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" dark shadow
                         @click.prevent="$router.push('/helex/Erp/Contabilidad/Ventas/Clientes')">Cancelar
              </vs-button>
              <vs-button type='submit' size="large">Guardar</vs-button>
            </div>
          </form>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      Listapaises: [],
      ListaDepartamentos: [],
      ListaMunicipios: [],
      listaTipoDocumentos: ["RC","TI","CC","CE","PP","CD","PEP","DIE","NIT"],
      idCliente: null,
      ClienteEditar: {
        ContClientes: {
          TipoIdentificacion: null,
          NIT: null,
          RazonSocial: null,
          NombreComercial: null,
          PaginaWeb: null,
          TipoContribuyente: null,
          Correo: null,
          Telefono: null,
          Celular: null,
          CodigoPostal: null,
          Direccion: null
        },
        idPersona: null,
        idPais: null,
        idDepartamento: null,
        idMunicipio: null
      }
    }
  },
  async mounted() {
    this.idCliente = this.$route.params.id;
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        this.$store.dispatch("hl_post", {
          path: "Pais/List"
        }).then(response => {
          if (response.length > 0) {
            this.Listapaises = response;
          }
        }).catch(error => {
          console.error("Error al obtener la lista de países:", error);
        });

        this.$store.dispatch("hl_get", {
          path: "ContComprador/ObtenerCompradorId/" + this.idCliente
        }).then(data => {
          var id = JSON.parse(localStorage.getItem("credenciales"))
          this.ClienteEditar = data.data;
          this.ClienteEditar.idPersona = id.personaId;
          this.SelectDepartamentos(this.ClienteEditar.idPais);
          this.SelectMunicipios(this.ClienteEditar.idDepartamento);
        }).catch(error => {
          console.error("Error al obtener el comprador:", error);
        });

      } catch (error) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Error',
          text: error,
          border: 'danger'
        })
      }
    },
    cambiartipoContribuyente(dato) {
      if (dato == "PersonaJuridica")  this.ClienteEditar.ContClientes.TipoIdentificacion = "NIT";
      else this.ClienteEditar.ContClientes.TipoIdentificacion = "CC";
      this.ClienteEditar.ContClientes.TipoContribuyente = dato;
    },
    async SelectDepartamentos(id) {
      try {
        this.ListaDepartamentos = [];
        this.$store.dispatch("hl_get", {
          path: "DepartamentoPais/ListByPais/" + id
        }).then(response => {
          if (response.length > 0) {
            this.ListaDepartamentos = response;
          }
        })
      } catch (error) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Error',
          text: error,
          border: 'danger'
        })
      }
    },
    async SelectMunicipios(id) {
      try {
        this.ListaMunicipios = [];
        this.$store.dispatch("hl_get", {
          path: "Municipio/ListByDepartamento/" + id
        }).then(response => {
          if (response.length > 0) {
            this.ListaMunicipios = response;
          }
        })
      } catch (error) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Error',
          text: error,
          border: 'danger'
        })
      }
    },
    async UpdatCliete() {
      try {
        this.$store.dispatch("hl_post", {
          path: "ContComprador/ActualizarComprador/" + this.idCliente,
          data: this.ClienteEditar
        }).then(response => {
          if (response.respuesta) {
            this.$router.push('/helex/Erp/Contabilidad/Ventas/Clientes');
          } else {
            this.$vs.notification({
              position: 'top-center',
              title: 'Error',
              text: response.mensaje,
              border: 'danger'
            })
          }
        })
      } catch (error) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Error',
          text: error,
          border: 'danger'
        })
      }
    },
    SelectTipoDocumento() {
      if (this.ClienteEditar.ContClientes.TipoIdentificacion == "NIT"){
        this.ClienteEditar.ContClientes.TipoContribuyente = "PersonaJuridica";
      }
      else{
        this.ClienteEditar.ContClientes.TipoContribuyente = "PersonaNatural";
      }

    }
  }
}
</script>

<style scoped>
.active-button {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
}
</style>