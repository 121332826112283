<template>
  <div>
    <div>
      <vs-tooltip>
        <vs-button border @click="active = !active">
          <i class="fa-regular fa-file-excel"></i>
        </vs-button>
        <template #tooltip>
          Generar reporte
        </template>
      </vs-tooltip>
    </div>
    <vs-dialog width="400px"  v-model="active">
      <template #header>
        <span class="not-margin h3">EXPORTAR DATOS</span>
      </template>
      <div class="con-content ">
        <vs-input 
          v-model="fileName" 
          placeholder="Nombre del archivo"
          class="mb-4"
          border
        >
          <template #icon>
            <i class="fa-regular fa-file"></i>
          </template>
        </vs-input>
        <div class="checklist-container">
          <h5 class="mb-3 text-gray-600">Selecciona los campos a exportar:</h5>
          <div class="checklist" v-if = "items">
            <vs-checkbox 
              v-for="(item, index) in headers"
              :key="index"
              v-model="valoresSeleccionados"
              :val="item" 
              class="checklist-item"
            >
          <div class="checklist-content">
            <span class="checklist-label">{{ item.header }}</span>
          </div>
    </vs-checkbox>
          </div>
          <div v-else class="d-flex justify-content-center">
            <span class="checklist-label h4">¡Sin valores!</span>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="footer-container">
          <vs-button 
            @click="generateReport" 
            :disabled="isAnySelected"
            primary
          >
            <i class='bx bx-download mr-2'></i>
            Generar reporte
          </vs-button>
          <vs-button @click="active = false" danger transparent>
            Cancelar
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: "exportExcel",
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
    }
    
  },
  data() {
    return {
      active: false,
      valoresSeleccionados:[],
      fileName: "",
      };
  },
  computed: {
    isAnySelected() {
      return this.valoresSeleccionados.length === 0;
    }
  },
  methods: {
   async generateReport(){
      let keys = this.valoresSeleccionados.map(v => v.key)
      let headers = this.valoresSeleccionados.map(v => v.header)

      console.log("Valores para el informe", this.valoresSeleccionados)
      console.log("Valores para el informe key", keys)
      console.log("Valores para el informe headers", headers)

      const data = {
            "propiedades": keys, 
            "headers": headers,  
            "data": this.items,
            "nombreArchivo": this.fileName
          };

          try {
            // Hacer la solicitud POST al endpoint
            const response = await this.$store.dispatch("hl_post_excelReport", {
            path: "ExcelReportes/generate-excelHL",
            data: data,
            
          });
          console.log(response)
   
        }catch (e) {
          console.error("Error al generar el informe", e);
          this.$message.error("Error al generar el informe");
        }
    }
  },
  watch: {
    active(newVal) {
    if (!newVal) {
      // this.items = {},
      this.valoresSeleccionados = [],
      this.fileName = ""
      // this.headers = {}
    }
  },
  }
};
</script>

<style>

.con-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.con-footer .vs-button {
  margin: 0px;
}

.con-footer .vs-button .vs-button__content {
  padding: 10px 30px;
}
/* .con-footer .vs-button__content{
  background-color: rgb(191, 0, 34)
} */
.con-footer .vs-button ~ .vs-button {
  margin-left: 10px;
}

.not-margin {
  margin: 0px;
  font-weight: normal;
  padding: 10px;
  padding-bottom: 0px;
}

.con-content {
  width: 100%;
}

.con-content p {
  font-size: 0.8rem;
  padding: 0px 10px;
}

.con-content .vs-checkbox-label {
  font-size: 0.8rem;
}

.con-content .vs-input-parent {
  width: 100%;
}

.con-content .vs-input-content {
  margin: 10px 0px;
  width: calc(100%);
}

.con-content .vs-input-content .vs-input {
  width: 100%;
}

.footer-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100%);
}

.footer-dialog .new {
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  font-size: 0.7rem;
}

.footer-dialog .new a {
  color: var(--primary-color) !important;
  margin-left: 6px;
}

.footer-dialog .new a:hover {
  text-decoration: underline;
}

.footer-dialog .vs-button {
  margin: 0px;
}

</style>

<style scoped>
.checklist-container {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 8px;
}

.checklist {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.checklist-item {
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.checklist-item:hover {
  background-color: rgba(var(--vs-primary), 0.1);
}

.checklist-content {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.checklist-label {
  font-weight: 500;
}

.checklist-description {
  font-size: 0.875rem;
}

.footer-container {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px;
}

.checklist-container::-webkit-scrollbar {
  width: 6px;
}

.checklist-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.checklist-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.checklist-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>