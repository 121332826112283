<template >
  <HXContentCard  title="Detalle pregunta" :toBack="{ name: 'Preguntas' }">
    <div  >
      <div class="d-flex" >
        <h5 class="px-4 pt-4">
          <b>{{ "Competencia: "}}</b>
          <p>{{ pregunta.titulo }}</p>
        </h5>
        <!-- <h4 class="px-4 py-1">Tipo de pregunta: Multiple</h4> -->
        <div class="ml-auto mt-2 text-right mr-4">
          <vs-button v-if="usuario.rol.nivel == 4" primary style="width: 10rem;" icon class="mx-1    mt-4" @click="agregarPregunta()"><i class="fas fa-circle-plus pr-2"></i>Agregar pregunta</vs-button>
        </div>
      </div>
    </div>
    <card style=" ">
      <template v-slot:headerTitle>
        <h4 class="card-title"><b>Lista de preguntas:</b></h4>
      </template>
      <div class="row px-4 mx-1 py-3" v-if="listaItems.length > 0">
        <div @click="obtenerDetalle(pregunta.id)" v-for="pregunta of listaItems" :key="pregunta.id" class="col-12 py-3 px-3 bg-dark rounded  my-2 pointer">
          {{ pregunta.pregunta }}
        </div>
      </div>
    </card>

    <b-row  v-if="listaDetallePregunta.length > 0">
      <b-col  sm="12">
        <card style=" ">
          <!-- pregunta de control -->
          <div class="row px-5 py-3">
            <div class="col-12 mb-3">
              <label for=" " class="form-label">Pregunta de control</label>
              <input disabled required v-model="item.preguntaControl" placeholder="Escriba la pregunta..." type="text" class="form-control" id=" " />
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for=" " class="form-label">pregunta de control Activa</label>
                <div class="form-check form-switch">
                  <label class="form-check-label" :for="'aflexSwitchCheckDefault'"> </label>
                  <VueToggles height="20" width="50" :value="item.preguntaControlActiva" />
                </div>
              </div>
            </div>
          </div>
        </card>
      </b-col>
    </b-row>

    <b-row v-if="listaDetallePregunta.length > 0">
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>Items de Evaluación</b></h4>
          </template>
          <div class="row px-4 mx-1 py-3">
            <div v-for="itemDetalle of listaDetallePregunta" :key="itemDetalle.id" class="col-12 py-3 px-3 bg-dark rounded-sm my-2">
              {{ itemDetalle.descripcion }}
            </div>
          </div>
        </card>
      </b-col>
    </b-row>

    <b-row v-if="listaDetallePregunta.length > 0">
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>VALORACIONES</b></h4>
          </template>
          <div class="row px-4 pt-3">
            <div class="col-6">
              <div class="pt-3 pb-1 border-bottom pl-2">
                <span>Descripción</span>
              </div>
            </div>
            <div class="col-6">
              <div class="pt-3 pb-1 border-bottom pl-2">
                <span>Calificación</span>
              </div>
            </div>
          </div>
          <div class="row px-4 pb-3" v-for="valoracion of listaValoraciones" :key="valoracion.id">
            <div class="col-6">
              <div class="py-3 card px-3 bg-dark rounded-sm my-2">
                {{ valoracion.descripcion }}
              </div>
            </div>
            <div class="col-6">
              <div class="py-3 card px-3 bg-dark rounded-sm my-2">
                {{ valoracion.valoracion }}
              </div>
            </div>
          </div>
        </card>
      </b-col>
    </b-row>
  </HXContentCard>
</template>
<script>
// import { core } from "../../../config/pluginInit";
export default {
  data() {
    return {
      id: "",
      tabla_test: [
        {
          id: 1,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
      ],
      listaItems: [],
      listaDetallePregunta: [],
      listaValoraciones: [],
      item: {},
      usuario: {},
      pregunta: {},
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.pregunta = JSON.parse(localStorage.getItem("preguntadata"));
    // core.index();
    // window.$("#datatable_empresa").DataTable();
    this.getData();
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
  },
  methods: {
    agregarPregunta() {
      // localStorage.setItem('competenciadata', item);
      this.$router.push("/helex/Preguntas_agregar/" + this.id);
    },
    async obtenerValoraciones(id) {
      // console.log(this.persona.empresa.id);
      try {
        console.log("obteniendo preguntas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "PreguntaDetalleMovimiento/ListByPregunta/" + id,
        });
        console.log("valoraciones...", res);
        if (res.length > 0) {
          this.listaValoraciones = res;
        } else {
          this.listaValoraciones = [];
        }
      } catch (error) {
        this.listaValoraciones = [];
        console.log("err", error);
      }
    },
    async obtenerDetalle(id) {
      // console.log(this.persona.empresa.id);
      try {
        console.log("obteniendo preguntas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "PreguntaDetalle/ListByPregunta/" + id,
        });
        await this.getDataGeneral(id);
        this.obtenerValoraciones(id);
        console.log("...", res);
        if (res.length > 0) {
          this.listaDetallePregunta = res;
        } else {
          this.listaDetallePregunta = [];
        }
      } catch (error) {
        this.listaDetallePregunta = [];
        console.log("err", error);
      }
    },
    async getDataGeneral(id) {
      this.item = {};

      // console.log(this.persona.empresa.id);
      try {
        console.log("obteniendo getDataGeneral...");
        let res = await this.$store.dispatch("hl_get", {
          path: "CompetenciaPregunta/Get/" + id,
        });
        console.log("getDataGeneral ", res);
        if (res) {
          this.item = res;
        } else {
          this.item = {};
        }
      } catch (error) {
        this.item = [];
        console.log("err", error);
      }
    },
    async getData() {
      // console.log(this.persona.empresa.id);
      try {
        console.log("obteniendo preguntas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "CompetenciaPregunta/ListPreguntaByCompetenciaId/" + this.id,
        });
        console.log("listaitems ", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style scoped>
.HXContentCard{
  background-color: transparent !important;
}
</style>