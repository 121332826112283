<template>
  <HXContentCard title="Honorarios de Exito">
    <div class="p-4">

      <!-- cargar honorarios -->
      <b-modal id="cargar-honorarios" title="Cargar Honorarios" size="md" hide-footer>


        <!-- descargar plantilla de ejemplo -->
        <div class="d-flex justify-content-end align-items-center">
          <div class="mr-1 bg-dark rounded-circle d-flex justify-content-center align-items-center"
            style="width: 30px; height: 30px;" id="info">
            <i class="fa-solid fa-exclamation"></i>
          </div>
          <b-popover target="info" triggers="hover" placement="top">
            <template #title>Importante!</template>
            <p class="font-weight-bold">
              No modifiques los encabezados ni los elimines.
            </p>
            <p>
              Por favor, asegúrate de seguir estas indicaciones antes de cargar el archivo.
            </p>
          </b-popover>
          <vs-button success icon flat :href="urlPlantila">
            <i class="fas fa-file-excel mr-2"></i>
            Descargar Plantilla
          </vs-button>
        </div>
        <div class="mb-3 ">
          <label for="date" class="form-label">Mes y Año</label>
          <input id="date" type="month" class="form-control" v-model="currentUploadDate" />
        </div>
        <!-- drop file -->
        <DropFileBasic @handleFileData="handleFileData" @fileRemoved="handleFileRemoved" :loader="loader" :fileTypes="[
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]" />

        <!-- file validation -->
        <p v-if="messageFileValidation" class="py-3">
          <i class="fas fa-times text-danger"></i>
          {{ messageFileValidation }}
        </p>
        <div class="d-flex justify-content-end mt-2">
          <vs-button primary icon @click="sendDataFile" :disabled="!sendData || !currentUploadDate">
            <i class="fas fa-upload mr-1"></i>
            Subir y Guardar
          </vs-button>
        </div>
      </b-modal>

      <div v-if="isAdmin" class="d-flex justify-content-end">
        <vs-button success icon v-b-modal="'cargar-honorarios'">
          <i class="fas fa-file-import mr-1"></i>
          Cargar Honorarios</vs-button>
      </div>

      <div class="row">
        <div class="col-12 col-lg-4 px-5">
          <label for="filterDate" class="form-label"><i class="fas fa-calendar-days"></i> Filtrar por periodo:</label>
          <input id="date" type="month" class="form-control" v-model="currentFilterDate"
            @input="handleFilterDateInput" />
        </div>
      </div>

      <div v-if="excelData.length > 0" class="px-4 mt-3">
        <table id="datatable_honorariosExito" class="table table-bordered table-responsive table-striped text-center">
          <thead>
            <tr>
              <th v-for="header in headers" :key="header" :colspan="encabezadosCombinados(header)">{{ header
                }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in excelData" :key="index">
              <td v-for="(cell, idx) in row" :key="idx">
                {{ cell | formatValue(idx) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <div v-else class="px-4 mt-3 d-flex flex-column align-items-center" style="gap: 1rem;">
        <div class="bg-dark rounded-circle p-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
            stroke-linecap="round" stroke-linejoin="round" width="24" height="24" stroke-width="2">
            <path d="M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3"></path>
            <path d="M4 6v6c0 1.657 3.582 3 8 3m8 -3.5v-5.5"></path>
            <path d="M4 12v6c0 1.657 3.582 3 8 3"></path>
            <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
            <path d="M20.2 20.2l1.8 1.8"></path>
          </svg>
        </div>
        <p>No se encontraron registros.</p>
      </div>
    </div>
  </HXContentCard>
</template>

<script>
import * as XLSX from 'xlsx';
import { core } from "../../../config/pluginInit";
import DropFileBasic from "@/components/helexium/DropFileBasic.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    DropFileBasic
  },
  data() {
    return {
      currentUploadDate: null,
      currentFilterDate: null,
      headers: [
        'CLINICA', 'IDENTIFICACION', 'NOMBRE', 'FECHA DE INGRESO', 'TIEMPO DE PRESTACION DE SERVICIOS',
        'CATEGORIA', 'PRIMEROS ABONOS PARA CUMPLIMIENTO', 'META DE PRIMEROS ABONOS', 'CUMPLIMIENTO',
        'PORCENTAJE A CANCELAR', 'PRIMEROS ABONOS LIQUIDACION', 'SUBVENCION Y GASTOS FINANCIEROS',
        'DEVOLUCIONES', 'TOTAL PRIMEROS ABONOS LIQUIDACION ', 'NOTA DEBITO CONVENIOS / ALIANZAS',
        'INCENTIVO DE PRIMEROS ABONOS SIN CUMPLIMIENTO DE META', 'INCENTIVO DE PRIMEROS ABONOS CON CUMPLIMIENTO DE META',
        'CIERRES DE ORTODONCIA', 'IMPLANTES MONTADOS', 'MONTAJES DE ORTODONCIA', 'PACIENTES QUE INICIAN',
        'HONORARIOS DE ÉXITO PACKS OCTUBRE', 'GARANTIZADO', 'NOTA DEBITO CONVENIOS / ALIANZAS',
        'INCREMENTO OCACIONAL', 'OTROS', 'TOTAL'
      ],
      dataTableInstance: null,
      excelData: [],
      loader: false,
      sendData: null,
      personaAct: {
        rol: {
          nivel: 0
        },
        numeroDocumento: ''
      },
      messageFileValidation: null,
      urlPlantila: 'https://helexiumfiles.com/empresa/CACCF73C-58B3-40F7-8C0E-EF211FA21D7F/PRELIQUIDACIONHONORARIOSDEEXITO.xlsx'
    };
  },
  async mounted() {

    this.personaAct = JSON.parse(localStorage.getItem("setPersonaAct"));

    await this.getHonorariosExitoTemp();

  },
  computed: {
    isAdmin() {
      return this.personaAct.rol.nivel >= 3;
    },
    docPersonaAct() {
      return this.personaAct.numeroDocumento;
    }
  },
  methods: {
    showToast(type, message) {
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    handleFileRemoved() {
      this.sendData = null;
      this.messageFileValidation = null;
    },
    initializeDatatable() {
      core.index();
      const dataTableElement = window.$("#datatable_honorariosExito");

      // Destruir la instancia de DataTable si ya existe
      if (window.$.fn.DataTable.isDataTable(dataTableElement)) {
        dataTableElement.DataTable().destroy();
      }

      console.log(this.dataTableInstance);

      this.dataTableInstance = dataTableElement.DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
        paging: true,
        searching: true,
        info: true,
        autoWidth: false
      });
    }
    ,
    encabezadosCombinados(header) {
      switch (header) {
        case 'CIERRES DE ORTODONCIA':
          return 9;
        case 'IMPLANTES MONTADOS':
          return 2;
        case 'MONTAJES DE ORTODONCIA':
          return 4;
        case 'PACIENTES QUE INICIAN':
          return 3;
        default:
          return 1;


      }
    },
    handleFileData(event) {
      const file = event;
      const reader = new FileReader();

      console.log(file);

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        let headers = json[0].filter((item) => item !== undefined);
        let excelData = json.slice(1);
        excelData[0].push(...new Array(6).fill(undefined));

        // Aquí es donde se valida la estructura
        const isValid = this.validateExcelStructure(headers, excelData);

        if (isValid) {
          this.sendData = {
            headers,
            excelData
          };

          console.log('sendData', this.sendData);
        } else {
          this.showToast("error", "El archivo Excel no tiene la estructura correcta.");
        }
      };

      reader.readAsArrayBuffer(file);
    },
    validateExcelStructure(headers, data) {

      const expectedColumns = 41; // Número de columnas esperadas por fila


      this.messageFileValidation = "El archivo Excel no tiene la estructura correcta.";

      // Verificar que el número de encabezados sea el esperado
      if (headers.length !== this.headers.length) {
        console.error(this.messageFileValidation);
        return false;
      }

      // Verificar que los encabezados coincidan
      for (let i = 0; i < headers.length; i++) {
        if (headers[i] !== this.headers[i]) {
          console.error(this.messageFileValidation);
          return false;
        }
      }

      // Verificar que cada fila tenga el número correcto de columnas
      for (let row of data) {
        if (row.length !== expectedColumns) {
          console.error(this.messageFileValidation);
          return false;
        }
      }

      this.messageFileValidation = null;

      return true;
    },
    async handleFilterDateInput() {
      const [anio, mes] = this.currentFilterDate.split("-");
      await this.getHonorariosExitoTemp(anio, mes);
    },
    async getHonorariosExitoTemp(anio = null, mes = null) {
      try {
        this.$isLoading(true);

        // Mejorar la legibilidad de la creación de path
        const path = anio && mes ? `HonorariosExitoTemp/Obtener/${anio}/${mes}` : "HonorariosExitoTemp/Obtener";
        let res = await this.$store.dispatch("denti_get", { path });

        console.log('res', res);

        const { success, data } = res;
        if (success && data !== null) {
          // Optimización en la forma de crear currentFilterDate
          this.currentFilterDate = `${data.anio}-${String(data.mes).padStart(2, "0")}`;

          // Parsear los datos de Excel
          const parsedData = JSON.parse(data.data);

          this.excelData = parsedData.excelData;

          console.log('excelData', this.excelData);


          // filtrar para usuarios que no son admin
          if (!this.isAdmin) {
            // Filtrar solo si el rol es menor a 3 y la identificación coincide
            this.excelData = this.excelData.filter(row => row[1] == this.docPersonaAct);
          }
        } else {
          console.log('No se encontraron datos');
          this.excelData = [];
          this.dataTableInstance && this.dataTableInstance.destroy();
        }


        this.$isLoading(false);
      } catch (error) {
        console.error("Error al obtener los honorarios:", error);
        this.$isLoading(false);
        this.showToast("error", "Hubo un error al obtener los honorarios");
      } finally {
        this.$nextTick(() => {
          this.initializeDatatable();
        });
      }
    }
    ,
    async sendDataFile() {
      this.$isLoading(true);
      try {
        console.log('sendData', this.sendData);

        const [anio, mes] = this.currentUploadDate.split("-").map(Number);

        console.log(anio, mes);

        let data = {
          mes,
          anio,
          data: JSON.stringify(this.sendData),
        };

        console.log("dataSend", data);

        let res = await this.$store.dispatch("denti_post", {
          path: "HonorariosExitoTemp/GuardarData",
          data,
        });
        console.log("res", res);

        if (res.success) {
          this.$bvModal.hide("cargar-honorarios");
          this.showToast("success", res.message);
          await this.getHonorariosExitoTemp(anio, mes);
        }
        this.$isLoading(false);
      } catch (error) {
        console.log("error", error);
        this.$isLoading(false);
        Swal.fire("Error!", "Error: " + error, "error");
      }
    }
    ,

  },
  filters: {
    formatValue(value, idx) {
      console.log('value', value);

      if (value === undefined || value === null) return '';

      const formatters = {
        currency: [6, 7, 10, 11, 12, 13, 14, 15, 16, 21, 25, 27, 31, 34, 35, 37, 40],
        date: [3],
        percentage: [8, 9, 20, 30],
        numberWithCommas: [4, 19, 29, 33]
      };

      if (typeof value === 'number') {
        if (formatters.currency.includes(idx)) {
          // Formatear como moneda
          return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', maximumFractionDigits: 0 }).format(value);
        } else if (formatters.date.includes(idx)) {
          // Formatear como fecha (en caso de que sea un valor numérico de fecha de Excel)
          const excelEpoch = new Date(1899, 11, 30); // La fecha base de Excel es el 30 de diciembre de 1899
          const date = new Date(excelEpoch.getTime() + value * 86400000); // Convertir a milisegundos
          return date.toLocaleDateString('es-CO'); // Formatear la fecha en el formato deseado
        } else if (formatters.percentage.includes(idx)) {
          // Formatear como porcentaje
          return (value * 100).toFixed(1) + '%';
        } else if (formatters.numberWithCommas.includes(idx)) {
          // Redondear a un decimal y luego formatear con separadores de miles
          return new Intl.NumberFormat('es-CO').format(Math.round(value * 10) / 10);
        }
      }

      return value;
    }
  }
};
</script>

<style scoped></style>
